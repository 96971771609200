import React, { useState } from "react";
import "./addstore.css";
import { VscClose } from "react-icons/vsc";

const AddStoreNew = () => {
  const fields = [
    { label: "Store Name", type: "text", id: "store-name" },
    {
      label: "Company Name",
      type: "select",
      id: "company-name",
      options: ["Company A", "Company B", "Company C"], // Add your actual company options
    },
    { label: "Company Address", type: "text", id: "company-address" },
  ];

  // State to manage form input values for the Store component
  const [formData, setFormData] = useState({
    "store-name": "",
    "store-address": "",
    "company-address": "",
  });

  // Handle form changes
  const handleChange = (e) => {
    const { id, value, files } = e.target;
    setFormData({
      ...formData,
      [id]: files ? files[0] : value, // handle file uploads separately
    });
  };

  return (
    <div className="addstore">
      <div className="d-flex justify-content-end">
        <VscClose size={20} />
      </div>
      <h2 style={{ paddingBottom: "20px" }}>Add New Store</h2>
      <div className="border-bottom"></div>
      <div
        className="d-flex justify-content-between gap-1 content_common"
        style={{ borderBottom: "2px solid #2C357D2E" }}
      >
        <div className="label_name">
          {fields.map((field) => (
            <label key={field.id} htmlFor={field.id}>
              {field.label}
            </label>
          ))}
        </div>

        <div className="input_group mx-auto">
          {fields.map((field) => (
            <div key={field.id} className="form-group">
              {field.type === "select" ? (
                <select
                  id={field.id}
                  value={formData[field.id]}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">Select {field.label}</option>
                  {field.options.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : field.type === "file" ? (
                <input
                  type="file"
                  id={field.id}
                  accept="image/*"
                  onChange={handleChange}
                  className="form-control"
                />
              ) : (
                <input
                  className="form-control"
                  type={field.type}
                  id={field.id}
                  value={formData[field.id]}
                  onChange={handleChange}
                  placeholder={`Enter ${field.label}`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="btnblue px-4 pb-5 mt-1">
        <button>Submit</button>
      </div>
    </div>
  );
};

export default AddStoreNew;
