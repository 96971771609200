import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo_s from "../assets/img/logo_s.svg";
import lock_img from "../assets/img/lock_img.svg";
import user_1 from "../assets/img/user_1.svg";
import eye from "../assets/img/eye.png";
import eye_off from "../assets/img/eye_off.png";
import styles from "./loginPage.module.scss";
import { login } from "../redux/Action";
import SpinnerLoader from "../components/common/spinnerloader/SpinnerLoader";

const LoginPage = () => {
  const dispatch = useDispatch();
  const route = useLocation();
  const router = useNavigate();
  const [togglePassword, setTogglePassword] = useState(false);
  const [load, setLoad] = useState(false);

  const toggleVisibility = (e) => {
    setTogglePassword(!togglePassword);
  };

  const initialValues = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: Yup.object({
        email: Yup.string().email().required("Please enter your email"),
        password: Yup.string().required("Please enter your password"),
      }),
      onSubmit: (values) => {
        setLoad(true);
        dispatch(login(values, router, setLoad));
        // action.resetForm();
      },
    });

  return (
    <div className="login-page">
      <div className={styles.wrapper_login}>
        <div className={styles.wrap_login}>
          <img src={logo_s} alt="" />
        </div>
        <form className={styles.login_form} onSubmit={handleSubmit}>
          <h1 className={styles.forget_pass}>Login To HRM</h1>

          <div className={styles.wrap_input}>
            <input
              className={styles.input100}
              type="text"
              name="email"
              placeholder="Emp ID"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <span className={styles.focus_input100}>
              <img src={user_1} alt="" />
            </span>
            {errors.email && touched.email && (
              <div className={styles.input_feedback}>{errors.email}</div>
            )}
          </div>
          <div className={styles.wrap_input}>
            <input
              className={styles.input100}
              placeholder="PASSWORD"
              type={togglePassword ? "text" : "password"}
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            <span className={styles.focus_input100}>
              <img src={lock_img} alt="" />
            </span>
            {errors.password && touched.password && (
              <div className={styles.input_feedback}>{errors.password}</div>
            )}
            <span className={styles.focus_eye} onClick={toggleVisibility}>
              <img
                src={togglePassword ? eye_off : eye}
                alt="Toggle visibility"
              />
            </span>
          </div>

          <div className={styles.contact100_form_checkbox}>
            <input
              className={`${styles.input_checkbox100} form-check-input`}
              id="flexCheckChecked"
              type="checkbox"
              name="rememberMe"
              checked={values.rememberMe}
              onChange={handleChange}
            />
            {/* {values.rememberMe && (
              <img src={correactmark} alt="Checked" width={12} />
            )} */}
            <label
              className={`${styles.label_checkbox100} form-check-label`}
              htmlFor="flexCheckChecked"
            >
              Remember Me
            </label>
          </div>
          {load ? (
            <button className={`${styles.btn_dark}`} type="submit">
              <div class="spinner-border" role="status"></div>
            </button>
          ) : (
            <button className={`${styles.btn_dark}`} type="submit">
              Login
            </button>
          )}

          <Link to="/forgotpassword">
            <h4>Forgot password?</h4>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
