import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv"; // Import CSVLink from react-csv
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../redux/Action";
import TableSection from "../components/common/customtable/TableSection";

const TableExcel = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const dispatch = useDispatch();
  const { getAllUserList } = useSelector((state) => state?.main);

  useEffect(() => {
    dispatch(getUsers(currentPage, limit));
  }, []);

  // Prepare data for CSV export
  const csvData = getAllUserList?.data?.map((user, index) => ({
    No: index + 1,
    Store: user.store,
    EmployeeId: user.employeeId,
    EmployeeName: user.employeeName,
    Role: user.role,
    ShiftTime: user.shiftTime,
    SlotTime: user.slotTime,
  }));

  const headers = [
    { label: "No", key: "No" },
    { label: "Store", key: "Store" },
    { label: "Employee Id", key: "EmployeeId" },
    { label: "Employee Name", key: "EmployeeName" },
    { label: "Role", key: "Role" },
    { label: "Shift Time", key: "ShiftTime" },
    { label: "Slot Time", key: "SlotTime" },
  ];
  const columns = [
    "No",
    "Store",
    "Employee Id",
    "Employee name ",
    "Role",
    "Shift Time",
    "Slot time",
  ];
  return (
    <div className="container">
      <div className="mt-3 text-success">Export React Table Data to CSV</div>
      <div className="row mt-4">
        <CSVLink
          data={csvData}
          headers={headers}
          filename="users_table.csv"
          className="btn btn-primary"
        >
          Export CSV
        </CSVLink>
        <div className="table_wrapper">
          <div className="table_content">
            <div className="row_wrap row">
              <div className="col-lg-12">
                <div className="card">
                  <TableSection
                    tableData={getAllUserList?.data}
                    columns={columns}
                    setPageData={setCurrentPage}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableExcel;
