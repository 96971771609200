import React from "react";
import ReactDOM from "react-dom/client";
// import './index.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import "./assets/css/demo.css";
import "./assets/css/responsive.css";
import "./assets/css/core.css";
import "./assets/css/theme-default.css";
import "./assets/css/page-account-settings.css";
import "./assets/css/page-auth.css";
import "./assets/css/page-icons.css";
// import "./assets/css/page-misc.css";
import "./assets/css/perfect-scrollbar.css";
import { Provider } from "react-redux";
import store from "./redux/Store";
import Toastify from "./Common/toaster/Toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Provider store={store}>
      <HashRouter basename="/">
        <Toastify />
        <App />
      </HashRouter>
    </Provider>
  </>
);
