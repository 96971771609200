import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Layout from "./Common/Layout";

const PrivateRoute = ({ element }) => {
  const auth = localStorage.getItem("accessToken") !== null;
  const navigate = useNavigate();
  // useEffect(() => {
  //     let isAuth = localStorage.getItem('acess_token')
  //     if (isAuth & isAuth !== 'undefined') {
  //         navigate('/login')
  //     }
  // }, [])

  return auth ? (
    <Layout />
  ) : (
    <>
      <Navigate to="/login" />
    </>
  );
};

export default PrivateRoute;
