import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import req_user from "../../assets/img/req_user.svg";
import cross from "../../assets/img/cross.png";
import watch_user from "../../assets/img/watch_user.svg";
import right_mark from "../../assets/img/right_mark.png";
import upper_mark from "../../assets/img/upper_mark.png";
import "./resignation.css";
import ViewResignation from "./ViewResignation";
import { getResignationdata } from "../../redux/Action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
const Resignation = () => {
  const userRole = localStorage.getItem("role");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [onRequest, setOnRequest] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { allResignationList } = useSelector((state) => state?.main);

  // Static attendance data
  useEffect(() => {
    const totalTicket = allResignationList?.data?.totalResignations || 0;
    const pendingTicket = allResignationList?.data?.pendingResignations || 0;
    const OpenTicket = allResignationList?.data?.overdueResignations || 0;
    const SolvedTicket = allResignationList?.data?.resolvedResignations || 0;
    const escalatedTicket =
      allResignationList?.data?.escalatedResignations || 0;

    console.log(allResignationList, "allResignationList");
    const attendanceData = [
      {
        status: "Total Resign Request",
        count: totalTicket,
        imgSrc: <img src={req_user} alt="Total Resign Request" />,
      },
      {
        status: "Pending",
        count: pendingTicket,
        imgSrc: <img src={cross} alt="Pending" />,
      },
      {
        status: "Overdue",
        count: OpenTicket,
        imgSrc: <img src={watch_user} alt="Overdue" />,
      },
      {
        status: "Resolved",
        count: SolvedTicket,
        imgSrc: <img src={right_mark} alt="Resolved" />,
      },
      {
        status: "Escalated",
        count: escalatedTicket,
        imgSrc: <img src={upper_mark} alt="Escalated" />,
        type: "admin",
      },
    ];
    setAttendanceData(attendanceData);
    setTableData(allResignationList?.data || []);
  }, []);

  useEffect(() => {
    dispatch(getResignationdata(currentPage, limit));
  }, [dispatch, currentPage, limit]);

  const OpenRequest = (data) => {
    setOnRequest(true);
    navigate(`/resignation/viewresignation/${data}`);
  };

  return (
    <div className="content-wrapper">
      {!onRequest && (
        <>
          {/* Attendance Summary Cards */}
          <div className="flex-grow-1 pt-4 view-request">
            <div className="leave_wrap">
              {attendanceData
                .filter((record) => !record.type || record.type === userRole)
                .map((record, index) => (
                  <div key={index} className="cardmornings">
                    <div className="totalnumber_card">
                      <div className="left_conetent">
                        <div className="view-iocn">{record.imgSrc}</div>
                      </div>
                      <div className="right_req">
                        <h4>{record.status}</h4>
                        <div className="min_flex_req">
                          <h1>{record.count}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {/* Resignation Requests Table */}
          <div className="container-fluid view-request">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Store Name</th>
                    <th>Manager</th>
                    <th>Resignation Date</th>
                    <th>Reason</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allResignationList?.data?.resignations?.map(
                    (items, index) => (
                      <tr key={index}>
                        <td>{items.empName}</td>
                        <td>{items.userId?.store_id?.name}</td>
                        <td>{items.userId?.managerId?.name}</td>
                        <td>
                          {moment(items.createdAt).format("DD MMMM YYYY")}
                        </td>
                        <td>{items.reason}</td>
                        <td>
                          <span
                            className={`badge badge-${items.status.toLowerCase()}`}
                          >
                            {items.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className="btn-view"
                            onClick={() => OpenRequest(items._id)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <Pagination
                listData={allResignationList?.meta}
                setPageData={setCurrentPage}
                tableData={tableData}
              />
            </div>
          </div>
        </>
      )}

      {/* Conditionally Render Detailed View */}
      {onRequest && <ViewResignation />}
    </div>
  );
};

export default Resignation;
