import React, { useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  Avatar_1,
  Avatar_5,
  Avatar_6,
  Avatar_7,
  Profile,
} from "../../assets/img/dashboard";
import {
  IconBell,
  IconMenu,
  IconSearch,
} from "../../assets/img/dashboard/icons";
import menu from "../../assets/img/menu.svg";
import ProfileIcon from "../../assets/img/profileIcon.png";
import Logout from "../../assets/img/logout.png";
import profilePic from "../../assets/img/profilePic.png";
import bellicon from "../../assets/img/bellicon.svg";
import Frame from "../../assets/icons/Frame.png";
import { useRef } from "react";
import { useEffect } from "react";
import { logout } from "../../redux/Action";
import { useDispatch } from "react-redux";

const Navbar = ({ funtype, isMenuBar, showAnimation }) => {
  const [isOpen, setIsOpen] = useState(false);
  // const pathname = location.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const access_token = localStorage.getItem("accessToken");
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(true);
  };
  const handleProfileClick = () => {
    if (access_token) {
      navigate("/profile");
    } else {
      navigate("/");
    }
    setIsOpen(false);
  };
  const handleLogout = () => {
    const access_token = localStorage.getItem("refreshToken");
    const data = {
      refreshToken: access_token,
    };
    dispatch(logout(data, navigate));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      <nav
        className="navbar navbar-expand-xl align-items-center top-bar"
        id="layout-navbar"
      >
        {/* <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none bg-navbar-theme">
          <a className="nav-item nav-link px-0 me-xl-4">
            <IconMenu onClick={funtype} />
          </a>
        </div> */}

        <div
          className="navbar-nav-right d-flex align-items-center "
          id="navbar-collapse"
        >
          <div className="d-flex align-items-center justify-content-flex ">
            <img src={menu} alt="" onClick={funtype} />
          </div>
          <div className="text_head">
            <h4>Good morning, Shiv</h4>
          </div>
          <div className="navbar-nav align-items-center justify-content-end bg-navbar-theme ms-auto search-bar">
            <div className="nav-item d-flex align-items-center justify-content-end gap-2 w-100">
              <IconSearch size={30} className="i" />
              <input
                type="text"
                className=" border-0 shadow-none p-0"
                placeholder="Quick Search..."
              />
            </div>
          </div>
          <span
            className="line_right
          "
          ></span>
          <ul className="navbar-nav flex-row align-items-center  profile-bar">
            <li className="nav-item lh-1 bg-navbar-theme dropdown-notifications navbar-dropdown dropdown ">
              <a
                className="nav-link  hide-arrow"
                //  href="javascript:void(0);"
                data-toggle="dropdown"
                data-close="outside"
                aria-expanded="false"
                contenteditable="false"
                style={{ cursor: "pointer" }}
              >
                <span className="position-relative">
                  {/* <i className="bx bx-bell bx-md"></i> */}
                  <img src={bellicon} alt="" />
                  <span className="badge rounded-pill bg-danger badge-dot badge-notifications border"></span>
                </span>
              </a>
              {/* <ul
                className="dropdown-menu dropdown-menu-end notifications p-4"
                style={{ left: "-210px" }}
              >
                <div className="dropdown-header d-flex align-items-center p-0">
                  <h3>Notification</h3>
                  <h6>Mark as read</h6>
                </div>
                <li className="dropdown-notifications-list scrollable-container ps chat-card bg-none p-0">
                  <ul className="p-0 m-0">
                    <li className="d-flex align-items-center mb-6 bg-none">
                      <div className="avatar flex-shrink-0 me-3">
                        <img src={Avatar_1} alt="User" />
                      </div>
                      <div className="d-flex w-100 flex-wrap align-items-baseline justify-content-between gap-2">
                        <div className="me-2">
                          <h4 className="fw-bolder mb-0">
                            Dianne Russell
                            <span className="flex-shrink-0 badge-center rounded-pill bg-danger w-px-15 h-px-15 ms-2"></span>
                          </h4>
                          <small className="d-block">
                            You got sale, and received money
                            <span className="text-blue-green">+$521,23</span>
                          </small>
                        </div>
                        <div className="user-progress d-flex align-items-center gap-2">
                          <h6 className="text-muted">1m</h6>
                        </div>
                      </div>
                    </li>
                    <li className="d-flex align-items-center mb-6 bg-none">
                      <div className="avatar flex-shrink-0 me-3">
                        <img src={Avatar_5} alt="User" />
                      </div>
                      <div className="d-flex w-100 flex-wrap align-items-baseline justify-content-between gap-2">
                        <div className="me-2">
                          <h4 className="fw-bolder mb-0">Dianne Russell</h4>
                          <small className="d-block">
                            You got sale, and received money
                            <span className="text-blue-green">+$521,23</span>
                          </small>
                        </div>
                        <div className="user-progress d-flex align-items-center gap-2">
                          <h6 className="text-muted">1m</h6>
                        </div>
                      </div>
                    </li>
                    <li className="d-flex align-items-center mb-6 bg-none">
                      <div className="avatar flex-shrink-0 me-3">
                        <img src={Avatar_6} alt="User" />
                      </div>
                      <div className="d-flex w-100 flex-wrap align-items-baseline justify-content-between gap-2">
                        <div className="me-2">
                          <h4 className="fw-bolder mb-0">Dianne Russell</h4>
                          <small className="d-block">
                            You got sale, and received money
                            <span className="text-blue-green">+$521,23</span>
                          </small>
                        </div>
                        <div className="user-progress d-flex align-items-center gap-2">
                          <h6 className="text-muted">1m</h6>
                        </div>
                      </div>
                    </li>
                    <li className="d-flex align-items-center mb-6 bg-none">
                      <div className="avatar flex-shrink-0 me-3">
                        <img src={Avatar_5} alt="User" />
                      </div>
                      <div className="d-flex w-100 flex-wrap align-items-baseline justify-content-between gap-2">
                        <div className="me-2">
                          <h4 className="fw-bolder mb-0">Dianne Russell</h4>
                          <small className="d-block">
                            You got sale, and received money
                            <span className="text-blue-green">+$521,23</span>
                          </small>
                        </div>
                        <div className="user-progress d-flex align-items-center gap-2">
                          <h6 className="text-muted">1m</h6>
                        </div>
                      </div>
                    </li>
                    <li className="d-flex align-items-center mb-6 bg-none">
                      <div className="avatar flex-shrink-0 me-3">
                        <img src={Avatar_7} alt="User" />
                      </div>
                      <div className="d-flex w-100 flex-wrap align-items-baseline justify-content-between gap-2">
                        <div className="me-2">
                          <h4 className="fw-bolder mb-0">Dianne Russell</h4>
                          <small className="d-block">
                            You got sale, and received money
                            <span className="text-blue-green">+$521,23</span>
                          </small>
                        </div>
                        <div className="user-progress d-flex align-items-center gap-2">
                          <h6 className="text-muted">1m</h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul> */}
            </li>
            <ul className="navbar-nav flex-row align-items-center profile-bar">
              {/* Notification Dropdown */}
              {/* <li className="nav-item dropdown">
                <span className="position-relative">
                  <IconBell className="i p-2 m-2" />
                  <span className="badge rounded-pill bg-danger badge-dot badge-notifications border"></span>
                </span>
              </li> */}

              {/* User Profile Dropdown */}
              <li
                className="nav-item dropdown d-flex   align-items-center"
                ref={dropdownRef}
              >
                <a
                  className="nav-link "
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded={isOpen ? "true" : "false"}
                  onClick={toggleDropdown}
                >
                  <img
                    src={profilePic}
                    alt="user"
                    className="rounded-circle"
                    style={{ width: "40px" }}
                  />
                </a>
                <div className="admin_text">
                  <p>Admin</p>
                  <p>Admin admin@domain.in</p>
                </div>
                <ul className="dropdown-menu dropdown-menu-end rounded-4  ">
                  <li className="dropdown-item" onClick={handleProfileClick}>
                    <img src={ProfileIcon} className="" alt="" />
                    <span className="px-2">Profile</span>
                  </li>
                  <li className="dropdown-item">
                    <span className="px-2"> Notification</span>
                    <img src={Frame} className="" alt="" />
                  </li>
                  <li className="dropdown-item" onClick={handleLogout}>
                    <img src={Logout} className="" alt="" />
                    <span className="px-2">Logout</span>
                  </li>
                </ul>
              </li>
            </ul>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
