import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo_s from "../assets/img/logo_s.svg";
import user_1 from "../assets/img/user_1.svg";
import styles from "./loginPage.module.scss";
import { login } from "../redux/Action";
import { useEffect } from "react";

const Verification = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [togglePassword, setTogglePassword] = useState(false);
  const btnLoading = useSelector((state) => state.main.btnLoading);
  const [otp, setOtp] = useState();
  const [time, setTime] = useState(60);
  const [disableResend, setDisableResend] = useState(false);
  const [reOtp, setReOtp] = useState(true);
  const toggleVisibility = (e) => {
    setTogglePassword(!togglePassword);
  };

  useEffect(() => {
    setReOtp(false);
    let i = 1;
    const timeout2 = setInterval(() => {
      setTime(time - i);
      i = i + 1;
    }, 1000);

    setTimeout(() => {
      setReOtp(true);
      clearInterval(timeout2);
      setTime(60);
    }, 60000);
  }, []);
  const resendOtp = async () => {
    setOtp();

    setReOtp(false);

    let i = 1;

    const timeout2 = setInterval(() => {
      setTime(time - i);
      i = i + 1;
    }, 1000);

    setTimeout(() => {
      setReOtp(true);
      clearInterval(timeout2);
      setTime(60);
    }, 60000);
  };
  const initialValues = {
    email: "",
    password: "",
  };

  const {
    values,
    errors,
    touched,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    handleReset,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please enter your email"),
      password: Yup.string().required("Please enter your password"),
    }),
    onSubmit: (values, action) => {
      // dispatch(buttonLoading(true))
      dispatch(login(values, router));
      action.resetForm();
    },
  });

  return (
    <div className="login-page">
      <div className={styles.wrapper_login}>
        <div className={styles.wrap_login}>
          <img src={logo_s} alt="" />
        </div>
        <form className={styles.login_form} onSubmit={handleSubmit}>
          <h1 className={styles.forget_pass}>Verification</h1>
          <div className={styles.wrap_input}>
            <input
              className={styles.input100}
              type="text"
              name="email"
              placeholder="Enter the 6-digit code"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <span className={styles.focus_input100}>
              <img src={user_1} alt="" />
            </span>
            {errors.email && touched.email && (
              <div className={styles.input_feedback}>{errors.email}</div>
            )}
            <div className="p-opt">
              {reOtp ? (
                <h5 onClick={resendOtp}>
                  {" "}
                  <span onClick={resendOtp}>
                    <p>Resend </p>
                  </span>
                </h5>
              ) : (
                <h6>
                  {" "}
                  OTP Expire in {Math.floor(time / 60)}:
                  {(time % 60).toString().padStart(2, "0")}{" "}
                </h6>
              )}
            </div>{" "}
          </div>

          <button className={styles.btn_dark} type="submit">
            Verify
          </button>
        </form>
      </div>
    </div>
  );
};

export default Verification;
