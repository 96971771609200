import React, { useEffect } from "react";
import link from "../../assets/img/link.png";
import profile from "../../assets/img/profile.png";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPoshDetaildata } from "../../redux/Action";

const ViewPosh = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { allPoshDetailList } = useSelector((state) => state?.main);

  const pathname = location.pathname;

  const pathParts = pathname.split("/");

  const userId = pathParts[pathParts.length - 1];

  useEffect(() => {
    dispatch(getPoshDetaildata(userId));
  }, [userId]);

  const Attendance = {
    records: [
      {
        status: "Ticket Number",
        reason: allPoshDetailList?.data?.ticketNo,
      },
      {
        status: "Store Manager",
        reason: allPoshDetailList?.data?.user?.store_id?.name,
      },
      {
        status: "Manager",
        reason: allPoshDetailList?.data?.user?.managerId?.name,
      },
      {
        status: "Shift Time",
        reason: allPoshDetailList?.data?.user?.shift || "--",
      },
    ],
  };

  return (
    <div className="content-wrapper">
      <div className="wrap">
        <div className="container-fluid flex-grow-1 pt-4">
          <div className="logo_texts">
            <div className="logo_icons">
              <img src={profile} alt="" />
            </div>
            <div className="des_texts">
              <h5> John Lennon</h5>
            </div>
          </div>
          <div className="d-flex align-item-center justify-content-center gap-3">
            {Attendance?.records?.map((record, index) => (
              <div className="cardmornings">
                <div className="right_reqs">
                  <h4>{record?.status}</h4>
                  <div className="min_flex_reqs">
                    <h1>{record?.reason}</h1>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="para_lorems">
            <div className="head_viewss">
              <div className="application_pins">
                <h6>Resign Application</h6>
                <img src={link} alt="" />
              </div>
            </div>
            <div className="lorem_texts">
              <p>{allPoshDetailList?.data?.description}</p>
            </div>
          </div>
          {/* <div className="btn_accept">
            <div className="accept_1">
              <button>Accept</button>
            </div>
            <div className="reject_1">
              <button>Reject</button>
            </div>
          </div> */}
          <div className="add_textarea">
            <div className="heading_add">
              <h3>Add Comment</h3>
            </div>
            <textarea placeholder="Describe yourself here..."></textarea>
          </div>
          <div className="para_loremss">
            <div className="head_views">
              <div className="application_pins">
                <h6>History of comments</h6>
              </div>
            </div>
            <div className="lorem_textss border-bottom-0">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Convallis tellus id interdum velit laoreet. Enim eu turpis
                egestas pretium. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Convallis tellus id interdum velit laoreet.
                Enim eu turpis egestas pretium.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Convallis tellus id interdum velit laoreet. Enim eu turpis
                egestas pretium. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Convallis tellus id interdum velit laoreet.
                Enim eu turpis egestas pretium.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Convallis tellus id interdum velit laoreet. Enim eu turpis
                egestas pretium. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Convallis tellus id interdum velit laoreet.
                Enim eu turpis egestas pretium.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Convallis tellus id interdum velit laoreet. Enim eu turpis
                egestas pretium. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Convallis tellus id interdum velit laoreet.
                Enim eu turpis egestas pretium.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Convallis tellus id interdum velit laoreet. Enim eu turpis
                egestas pretium. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Convallis tellus id interdum velit laoreet.
                Enim eu turpis egestas pretium.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPosh;
