import axios from "axios";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_SECRET_KEY,

  // withCredentials: true,
});

Axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default Axios;
