import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import { useDispatch, useSelector } from "react-redux";
import { newPassword, newPasswordUser } from "../redux/Action";
import styles from "./loginPage.module.scss";
import eye from "../assets/img/eye.png";
import user_1 from "../assets/img/user_1.svg";

const NewPassword = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const location = useLocation(); // Use useLocation to access the query params
  const [togglePassword, setTogglePassword] = useState(false);

  const query = new URLSearchParams(location.search);
  const emailFromQuery = query.get("email");
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      password: Yup.string().required("Please enter your password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please confirm your password"),
    }),
    onSubmit: (values, action) => {
      const data = {
        email: emailFromQuery,
        password: values.password,
        confirmPassword: values.confirmPassword,
      };
      if (emailFromQuery) {
        dispatch(newPasswordUser(data, router));
      } else {
        dispatch(newPassword(data, router));
      }

      action.resetForm();
    },
  });

  return (
    <div className="login-page">
      <div className={styles.wrapper_login}>
        <div className={styles.wrap_login}>
          <img src="/path-to-logo" alt="" />
        </div>
        <form className={styles.login_form} onSubmit={handleSubmit}>
          <h1 className={styles.forget_pass}>Create New Password</h1>
          <div className={styles.wrap_input}>
            <input
              className={styles.input100}
              type="password"
              name="password"
              placeholder="Enter New Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            <span className={styles.focus_input100}>
              <img src={eye} alt="" />
            </span>
            {errors.password && touched.password && (
              <div className={styles.input_feedback}>{errors.password}</div>
            )}
          </div>
          <div className={styles.wrap_input}>
            <input
              className={styles.input100}
              type="password"
              name="confirmPassword"
              placeholder="Confirm New Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
            />
            <span className={styles.focus_input100}>
              <img src={user_1} alt="" />
            </span>
            {errors.confirmPassword && touched.confirmPassword && (
              <div className={styles.input_feedback}>
                {errors.confirmPassword}
              </div>
            )}
          </div>

          <button className={styles.btn_dark} type="submit">
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewPassword;
