import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/common/inputfield/InputField";
import Selector from "../../components/common/selects/Selector";
import { Formik } from "formik";
import InputLogo from "../../components/common/customlogo/InputLogo";

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");
  const [checkedItems, setCheckedItems] = useState({
    allowEpf: false,
    css: false,
    javascript: false,
  });
  const [error, setError] = useState("");

  const options = [
    { value: "1", label: "One" },
    { value: "1", label: "One" },
    { value: "2", label: "Two" },
    { value: "3", label: "Three" },
  ];

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setCheckedItems((prevItems) => ({
      ...prevItems,
      [id]: checked,
    }));
  };
  return (
    <div>
      <div className="container mx-auto mt-4 mb-4 ">
        <Formik
          initialValues={{
            email: "",
            password: "",
            name: "",
            designation: "",
            profileImage: null,
            department: "",
            address: "",
            company_experience: "",
            dob: "",
            user_type: "",
          }}
          validate={(values, files, name) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            // setTimeout(() => {
            //   alert(JSON.stringify(values, null, 2));
            //   setSubmitting(false);
            // }, 400);
            const userData = new FormData();
            userData.append("name", values?.name);
            userData.append("password", values?.password);
            userData.append("email", values?.email);
            userData.append("profileImage", values?.profileImage);
            userData.append("designation", values?.designation);
            userData.append("address", values?.address);
            userData.append("department", values?.department);
            userData.append("company_experience", values?.company_experience);
            userData.append("dob", values?.dob);
            userData.append("user_type", values?.user_type);

            // dispatch(addUsers(userData, navigate));
            // if (form_id) {
            //   dispatch(
            //     updateUserData(userData),
            //     setFormData({})
            //   );
            // } else {
            //   dispatch(addUsers(userData));
            // }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <div className="container mx-auto mt-4 mb-4 ">
              <form onSubmit={handleSubmit}>
                <>
                  <div className="flex_input">
                    <InputField
                      label="Admin Name*"
                      inputType="text"
                      inputPlaceholder="Enter Admin Name"
                      InputFontSize="16px"
                      LabelFontSize="16px"
                      name="adminname"
                      value={values.adminname}
                      onChange={handleChange}
                      borderColor="#2c357d"
                      labelColor="#2c357d"
                      showStar={true}
                    />
                    {errors.adminname && touched.adminname && errors.adminname}
                    <InputField
                      label="Admin E-mail*"
                      inputType="text"
                      inputPlaceholder="Enter Admin E-mail"
                      InputFontSize="16px"
                      LabelFontSize="16px"
                      name="adminemail"
                      value={values.adminemail}
                      onChange={handleChange}
                      borderColor="#2c357d"
                      labelColor="#2c357d"
                    />
                    {errors.adminemail &&
                      touched.adminemail &&
                      errors.adminemail}
                  </div>
                  <div className="flex_input">
                    <InputField
                      label="Logo Name"
                      inputType="text"
                      inputPlaceholder="Enter Logo Name"
                      InputFontSize="16px"
                      LabelFontSize="16px"
                      name="logoname"
                      value={values.logoname}
                      onChange={handleChange}
                      borderColor="#2c357d"
                      labelColor="#2c357d"
                      width={"49%"}
                    />
                    {errors.logoname && touched.logoname && errors.logoname}
                    {/* <InputField
                      label="Logo (Only jpeg, png, jpg files allowed)"
                      inputType="text"
                      inputPlaceholder="Enter Logo"
                      InputFontSize="16px"
                      LabelFontSize="16px"
                      name="logo"
                      value={values.logo}
                      onChange={handleChange}
                      borderColor="#2c357d"
                      labelColor="#2c357d"
                    /> */}
                    <div style={{ width: "49%" }}>
                      <InputLogo showIcon={false} buttonPadding="8px 30px" />{" "}
                    </div>
                    {errors.logo && touched.logo && errors.logo}
                  </div>
                  <div className="flex_input">
                    <InputField
                      label="App Version(android) *"
                      inputType="text"
                      inputPlaceholder="Enter App Version(android) "
                      InputFontSize="16px"
                      LabelFontSize="16px"
                      name="android"
                      value={values.android}
                      onChange={handleChange}
                      borderColor="#2c357d"
                      labelColor="#2c357d"
                    />
                    {errors.android && touched.android && errors.android}
                    <InputField
                      label="App Version (ios)"
                      inputType="text"
                      inputPlaceholder="Enter App Version (ios)"
                      InputFontSize="16px"
                      LabelFontSize="16px"
                      name="ios"
                      value={values.ios}
                      onChange={handleChange}
                      borderColor="#2c357d"
                      labelColor="#2c357d"
                    />
                    {errors.ios && touched.ios && errors.ios}
                  </div>
                  <div className="flex_input">
                    <Selector
                      label="Maintenance Mode"
                      options={options}
                      name="maintenancemode"
                      value={values.maintenancemode}
                      onChange={handleChange}
                      LabelFontSize="16px"
                      SelectorFontSize="16px"
                      labelColor="#2c357d"
                      starColor="#2c357d"
                      borderColor="#2c357d"
                      showStar={true}
                      error={errors.maintenancemode}
                    />
                    {errors.maintenancemode &&
                      touched.maintenancemode &&
                      errors.maintenancemode}
                    <InputField
                      label="About App"
                      inputType="text"
                      inputPlaceholder="Enter About App"
                      InputFontSize="16px"
                      LabelFontSize="16px"
                      name="aboutapp"
                      value={values.aboutapp}
                      onChange={handleChange}
                      borderColor="#2c357d"
                      labelColor="#2c357d"
                    />
                    {errors.aboutapp && touched.aboutapp && errors.aboutapp}
                  </div>
                </>

                <div className="btn_left">
                  <button type="submit" disabled={isSubmitting}>
                    Save
                  </button>
                </div>
              </form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Settings;
