import React, { useState, useEffect, useRef } from "react";
import "./user.css";
import TableSection from "../../../components/common/customtable/TableSection";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getUsers } from "../../../redux/Action";
import white_filter from "../../../assets/img/white_filter.svg";
import downdrop from "../../../assets/img/downdrop.svg";

const User = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectRef = useRef(null); // create ref for the select element

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [updateForm, setUpdateForm] = useState();
  const [url, setUrl] = useState();
  const [selectedRole, setSelectedRole] = useState("");
  const [active, setActive] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [openForm, SetOpenForm] = useState(false);

  var params = new URLSearchParams(url);

  let form_id = params.get("ref");

  const { getAllUserList, deleteUser } = useSelector((state) => state?.main);

  useEffect(() => {
    dispatch(getUsers(currentPage, limit));
  }, [deleteUser]);

  const addFormCompleted = () => {
    SetOpenForm(false);
    setUpdateForm({});
  };

  const handleClick = () => {
    SetOpenForm(true);
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleActiveChange = (e) => {
    setActive(e.target.value);
  };
  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value);
  };

  // Trigger select dropdown on image click
  const handleIconClick = () => {
    if (selectRef.current) {
      selectRef.current.click();
    }
  };

  const columns = [
    "No.",
    "Store",
    "Employee ID",
    "Employee name ",
    "Role",
    "Shift Time",
    "Slot time",
    "Status",
  ];

  return (
    <>
      <div className="table_wrapper">
        <div className="top_section">
          <div className="row_setion row tabelhed d-flex justify-content-between"></div>

          <div className="col-lg-12 col-md-12">
            <div className="right-item d-flex justify-content-start gap-3">
              <form
                action=""
                className="d-flex "
                style={{ cursor: "pointer", width: "100%" }}
              >
                <div className="outer_border  border-right-0 border-left-0">
                  <input
                    type="text"
                    name="keyword"
                    id="keyword"
                    value={searchKeyword}
                    placeholder="Employee Name"
                    style={{ cursor: "pointer" }}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="outer_border  border-right-0">
                  <input
                    type="text"
                    name="keyword"
                    id="keyword"
                    value={searchKeyword}
                    placeholder="Employee ID"
                    style={{ cursor: "pointer" }}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="outer_border">
                  <img
                    src={downdrop}
                    alt="Open dropdown"
                    onClick={handleIconClick}
                  />
                  <select
                    id="keyword"
                    style={{ cursor: "pointer" }}
                    ref={selectRef}
                  >
                    <option selected>Employee Designation</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </form>
              <div className="btn_findEmployee">
                <button>Find Employee</button>
              </div>
              <div className="filters">
                <div className="calendars">
                  <img src={white_filter} alt={white_filter} />
                  <h4>Filter</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="table_wrapper">
        <div className="table_content">
          <div className="row_wrap row">
            <div className="col-lg-12">
              <div className="card">
                <TableSection
                  tableData={getAllUserList?.data}
                  columns={columns}
                  setPageData={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
