import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./reports.css";
import ViewUser from "../../Admin/pages/Users/ViewUser";
import { getReportList, getStoreSingleDetails } from "../../redux/Action";
import moment from "moment";
import Pagination from "../Pagination/Pagination";

const ViewReports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { allReportList, allStoreSingleDetail } = useSelector(
    (state) => state.main
  );
  const userRole = localStorage.getItem("role");
  const [onRequest, setOnRequest] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pathname = location.pathname;
  const pathParts = pathname.split("/");
  const userId = pathParts[pathParts.length - 1];
  console.log(currentPage, "currentPage");
  useEffect(() => {
    if (userRole === "management") {
      dispatch(getStoreSingleDetails(userId, currentPage));
    } else {
      dispatch(getReportList());
    }
  }, [dispatch, userRole, userId]);

  const OpenRequest = (data) => {
    console.log(data);

    setOnRequest(true);
    navigate(`/report/viewreports/ViewUser/${data?._id}`);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Present":
        return "#252C58";
      case "Absent":
        return "#AA0000";
      case "Late arrival":
        return "#D5B500";
      default:
        return "#2C357D";
    }
  };

  const reportData =
    userRole === "management"
      ? allStoreSingleDetail?.data || []
      : allReportList?.data?.attendances || [];

  const paginationdata =
    userRole === "management" ? allStoreSingleDetail : allReportList;

  return (
    <>
      <div className="content-wrapper">
        <div className="store_card">
          <div className="store_text">
            {userRole === "management"
              ? allStoreSingleDetail?.data?.employee_id?.store_id?.name ||
                "Store Name"
              : allReportList?.allReportList?.data?.employee_id?.store_id
                  ?.name || "Store Name"}
          </div>
          <div
            className="container-fluid view-requestr"
            style={{ borderTop: "3.58px solid #d5d9dd" }}
          >
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead>
                  <tr>
                    <th>Employee ID</th>
                    <th>Employee Name</th>
                    <th>Role</th>
                    <th>Department</th>
                    <th>Date</th>
                    <th>Check-in</th>
                    <th>Check-out</th>
                    <th>Work hours</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {console.log(reportData, "hdskjkj")} */}
                  {reportData?.map((report) => (
                    <tr key={report.id}>
                      <td>{report.employee_id?.emp_id}</td>
                      <td>{report.employee_id?.name}</td>
                      <td style={{ opacity: "50%" }}>
                        {report.employee_id?.designation}
                      </td>
                      <td style={{ opacity: "50%" }}>
                        {report.employee_id?.department}
                      </td>
                      <td style={{ opacity: "50%" }}>
                        {moment(report.date).format("DD-MM-YYYY")}
                      </td>
                      <td style={{ color: getStatusColor(report.status) }}>
                        {report.check_in_time
                          ? moment(report.check_in_time).format("hh:mm A")
                          : "-"}
                      </td>
                      <td style={{ color: getStatusColor(report.status) }}>
                        {report.checkOut
                          ? moment(report.checkOut).format("hh:mm A")
                          : "-"}
                      </td>
                      <td style={{ color: getStatusColor(report.status) }}>
                        {report.working_hour}
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            report.status === "Present"
                              ? "badge-approved"
                              : report.status === "Absent"
                              ? "badge-rejected"
                              : "badge-pending"
                          }`}
                        >
                          {report.status}
                        </span>
                      </td>
                      <td>
                        <button
                          className="btn-view"
                          onClick={() => OpenRequest(report)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                listData={paginationdata?.meta}
                setPageData={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
      {onRequest && <ViewUser type="report" />}
    </>
  );
};

export default ViewReports;
