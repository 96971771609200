import React from 'react'

const SvgIcon = () => {
  return (
    <div><svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_578_13081)">
    <path d="M5.39746 1.37891C5.22559 1.22266 5.05371 1.22266 4.88184 1.37891L0.756836 5.50391C0.600586 5.67578 0.600586 5.84766 0.756836 6.01953C0.928711 6.17578 1.10059 6.17578 1.27246 6.01953L4.76465 2.55078V11.3867C4.78027 11.6211 4.90527 11.7461 5.13965 11.7617C5.37402 11.7461 5.49902 11.6211 5.51465 11.3867V2.55078L9.00684 6.01953C9.17871 6.17578 9.35059 6.17578 9.52246 6.01953C9.67871 5.84766 9.67871 5.67578 9.52246 5.50391L5.39746 1.37891Z" fill="#34B53A"/>
    </g>
    <defs>
    <clipPath id="clip0_578_13081">
    <rect width="9" height="13" fill="white" transform="matrix(1 0 0 -1 0.639648 13)"/>
    </clipPath>
    </defs>
    </svg></div>
  )
}

export default SvgIcon