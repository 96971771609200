import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import user_icon from "../../../assets/img/user_icon.svg";
// import left_arrow from "../../../assets/img/left_arrow.png";
import filter from "../../../assets/img/filter.svg";
import move_item from "../../../assets/img/move_item.png";
import cross_box from "../../../assets/img/cross_box.png";
import sick from "../../../assets/img/sick.png";
import side_navigation from "../../../assets/img/side_navigation.png";
import beach_access from "../../../assets/img/beach_access.png";
import Check_out from "../../../assets/img/Check_out.png";
import admin from "../../../assets/img/admin.svg";
import door from "../../../assets/img/door.png";
import "./dashboard.css";

import { useState } from "react";
import {
  Sales_1,
  Kids_1,
  Kids_2,
  Kids_3,
  Kids_4,
} from "../../../assets/img/dashboard";
import {
  IconDelete,
  IconEdit,
  IconCloudUp,
  IconDown,
  IconSearch,
} from "../../../assets/img/dashboard/icons";
import { getUsers } from "../../../redux/Action";
import SvgforDashboard from "../../../assets/SvgforDashboard";
import SvgIcon from "../../../assets/SvgIcon";
import SvgPath from "../../../assets/SvgPath";
import { color } from "framer-motion";
import { MdOpacity } from "react-icons/md";
import SpinnerLoader from "../../../components/common/spinnerloader/SpinnerLoader";
import { toast } from "react-toastify";
const Dashboard = () => {
  const Attendance = {
    records: [
      {
        status: "Total Employee",
        count: 313,
        parentage: "+10%",
        year: "Than Last Year",
        imgSrc: <img src={admin} alt={admin} />,
      },
      {
        status: "Checked In",
        count: 313,
        parentage: "+10%",
        year: "Than Last Year",
        imgSrc: <SvgforDashboard />,
      },
      {
        status: "Checked Out",
        count: 313,
        parentage: "+10%",
        year: "Than Last Year",
        imgSrc: <img src={Check_out} alt={Check_out} />,
      },
      {
        status: "Not Checked In",
        count: 313,
        parentage: "+10%",
        year: "Than Last Year",
        imgSrc: <img src={door} alt={door} />,
      },

      {
        status: "LOP leave (Loss of pay)",
        count: 313,
        parentage: "+10%",
        year: "Than Last Year",

        imgSrc: <img src={move_item} alt={move_item} />,
      },
      {
        status: "Weekly Off",
        count: 151,
        parentage: "+10%",
        year: "Than Last Year",
        imgSrc: <img src={cross_box} alt={cross_box} />,
      },
      {
        status: "Absent",
        count: 151,
        parentage: "+10%",
        year: "Than Last Year",
        imgSrc: <img src={sick} alt={sick} />,
      },
      {
        status: "Half Day",
        count: 151,
        parentage: "+10%",
        year: "Than Last Year",
        imgSrc: <img src={side_navigation} alt={side_navigation} />,
      },
      {
        status: "Holiday",
        count: 151,
        parentage: "+10%",
        year: "Than Last Year",

        imgSrc: <img src={beach_access} alt={beach_access} />,
      },
    ],
  };
  // const policy = {
  //   records: [
  //     {
  //       status: "LOP leave (Loss of pay)",
  //       count: 313,
  //       parentage: "+10%",
  //       year: "Than Last Year",

  //       imgSrc: <img src={move_item} alt={move_item} />,
  //     },
  //     {
  //       status: "Weekly Off",
  //       count: 313,
  //       parentage: "+10%",
  //       year: "Than Last Year",
  //       imgSrc: <img src={cross_box} alt={cross_box} />,
  //     },
  //     {
  //       status: "Absent",
  //       count: 313,
  //       parentage: "+10%",
  //       year: "Than Last Year",
  //       imgSrc: <img src={sick} alt={sick} />,
  //     },
  //     {
  //       status: "Half Day",
  //       count: 313,
  //       parentage: "+10%",
  //       year: "Than Last Year",
  //       imgSrc: <img src={side_navigation} alt={side_navigation} />,
  //     },
  //     {
  //       status: "Holiday",
  //       count: 313,
  //       parentage: "+10%",
  //       year: "Than Last Year",

  //       imgSrc: <img src={beach_access} alt={beach_access} />,
  //     },
  //   ],
  // };

  const dispatch = useDispatch();
  const users = useSelector((state) => state.main.getAllUsers);
  const { getAllUserList } = useSelector((state) => state?.main);
  const [currentPage, setCurrentPage] = useState(1);
  const pageLoading = useSelector((state) => state.main.loading);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const columns = [
    "No",
    "Name",
    "Date of birth",
    "Email",
    "Employee Id",
    // "Status",
  ];

  return (
    <>
      {pageLoading ? (
        <SpinnerLoader />
      ) : (
        <>
          <div className="content-wrapper">
            <div className="flex-grow-1 container-p-y">
              <div className="row g-4 pb-4">
                {Attendance.records.splice(0, 4).map((record, index) => (
                  <div
                    className="col-xxl-3 col-xl-3  col-lg-3 col-md-6 col-sm-12"
                    key={index}
                  >
                    <div className="cardmorning">
                      <div className="totalnumber_card d-flex align-items-center">
                        <div className="left_content">
                          <div className="view-icon">{record.imgSrc}</div>
                        </div>
                        <div className="right_content">
                          <h4>{record.status}</h4>
                          <div className="min_flex">
                            <div className="min_left">
                              <h1>{record.count}</h1>
                            </div>
                            <div className="min_right d-flex align-items-center">
                              <div className="parentage_up d-flex align-items-center">
                                <SvgIcon />
                                <h5>{record.parentage}</h5>
                              </div>
                              <div className="year">
                                <h5>{record.year}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="row g-3">
                {Attendance.records.map((record, index) => (
                  <div
                    className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-12"
                    key={index}
                  >
                    <div className="cardmorning2">
                      <div className="totalnumber_card d-flex align-items-center">
                        <div className="left_content">
                          <div className="view-icon">{record.imgSrc}</div>
                        </div>
                        <div className="right_content">
                          <h4>{record.status}</h4>
                          <div className="min_flex">
                            <div className="min_left">
                              <h1>{record.count}</h1>
                            </div>
                            <div className="min_right d-flex align-items-center">
                              <div className="parentage_up d-flex align-items-center">
                                <SvgIcon />
                                <h5>{record.parentage}</h5>
                              </div>
                              <div className="year">
                                <h5>{record.year}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="content-wrapper justify-content-start">
            <div className="container-fluid view-requests">
              {/* Responsive Table */}
              <div className="card-header d-flex align-items-center justify-content-center gap-3 p-0">
                <div className="kidsselect flex">
                  <h5 className="card-title m-0 me-2">Attendance Overview</h5>
                </div>
                <div className="table-search-bar">
                  <IconSearch />
                  <input
                    type="text"
                    className="form-control"
                    style={{ border: "none" }}
                    placeholder="Quick Search..."
                  />
                </div>
                <div className="calendar_pad">
                  <div className="calendar">
                    <input type="date" />
                  </div>
                </div>
                <div className="filter">
                  <div className="calendar">
                    <img src={filter} alt={filter} />
                    <h4>Filter</h4>
                  </div>
                </div>
              </div>

              <div className="table-responsive mt-5">
                <table className="table table-bordered align-middle">
                  <thead>
                    <tr>
                      <th>Employee ID</th>
                      <th>Employee Name </th>
                      <th>Role</th>
                      <th>Department</th>
                      <th>Shift Time</th>
                      <th>Status</th>
                      <th>Check-in</th>
                      <th>Check-out</th>
                      <th>Work hours</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>2341421</td>
                      <td>Ahmed Rashdan </td>
                      <td style={{ opacity: "50%" }}>
                        Help Desk Executive
                      </td>{" "}
                      <td style={{ opacity: "50%" }}>IT Department</td>
                      <td style={{ opacity: "50%" }}>Morning</td>
                      <td>
                        <span className="badge badge-approved">
                          Work from office
                        </span>
                      </td>
                      <td style={{ color: "#2C357D" }}>09:00</td>
                      <td style={{ color: "#2C357D" }}>18:00</td>
                      <td style={{ color: "#2C357D" }}>10h 2m</td>
                    </tr>
                    <tr>
                      <td>2341421</td>
                      <td>Ahmed Rashdan </td>
                      <td style={{ opacity: "50%" }}>Help Desk Executive</td>
                      <td style={{ opacity: "50%" }}>IT Department</td>
                      <td style={{ opacity: "50%" }}>Morning</td>
                      <td>
                        <span className="badge badge-rejected ">Absent</span>
                      </td>
                      <td style={{ color: "#AA0000" }}>09:00</td>
                      <td style={{ color: "#AA0000" }}>18:00</td>
                      <td style={{ color: "#AA0000" }}>10h 2m</td>
                    </tr>
                    <tr>
                      <td>2341421</td>
                      <td>Ahmed Rashdan </td>
                      <td style={{ opacity: "50%" }}>Help Desk Executive</td>
                      <td style={{ opacity: "50%" }}>IT Department</td>
                      <td style={{ opacity: "50%" }}>Morning</td>
                      <td>
                        <span className="badge badge-arrival">
                          Late arrival
                        </span>
                      </td>
                      <td style={{ color: "#D5B500" }}>09:00</td>
                      <td style={{ color: "#2C357D" }}>18:00</td>
                      <td style={{ color: "#2C357D" }}>10h 2m</td>
                    </tr>
                    <tr>
                      <td>2341421</td>
                      <td>Ahmed Rashdan </td>
                      <td style={{ opacity: "50%" }}>Help Desk Executive</td>
                      <td style={{ opacity: "50%" }}>IT Department</td>
                      <td style={{ opacity: "50%" }}>Morning</td>
                      <td>
                        <span className="badge badge-home">Work from home</span>
                      </td>
                      <td style={{ color: "#252C58" }}>09:00</td>
                      <td style={{ color: "#252C58" }}>18:00</td>
                      <td style={{ color: "#252C58" }}>10h 2m</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* <div className="content-backdrop fade"></div> */}
        </>
      )}
    </>
  );
};

export default Dashboard;
{
  /* <div className="row align-items-center gy-3 mt-3">
<div className="col-lg-6 col-md-12">
  <div className="totalnumber_card">
    <div className="view-iocn"></div>
    <h4>Total Number of Employee</h4>
    <h1>45</h1>
    <h5>Currently Active</h5>
  </div>
</div>

<div className="col-lg-6 col-md-12">
  <div className="totalnumber_card">
    <div className="view-iocn">
      <IconEye color="white" size={20} />
    </div>
    <h4>Today's Attendance</h4>
    <h1>35</h1>
    <h5>Today</h5>
  </div>
</div>
</div> */
}
