import React, { useState } from "react";
import RightCustomModal from "../../Common/RightModal";
import AddShift from "../AddNewShift/AddShift"; // Import AddShift component

const Shift = () => {
  const [openInviteMember, setOpenInviteMember] = useState(false);

  const handleClose = () => {
    setOpenInviteMember(false);
  };

  // Sample shift data
  const shiftData = [
    {
      shiftName: "Morning Shift",
      shiftCode: "MS01",
      shiftHour: "8",
      shiftStartTime: "09:00",
      shiftEndTime: "17:00",
      graceInTime: "09:10",
      graceOutTime: "16:50",
      workingHour: "16:50",
    },
    // Add more shift data as needed
  ];

  const fields = [
    { label: "Shift Name", id: "shiftName" },
    { label: "Shift Code", id: "shiftCode" },
    { label: "Shift Hour", id: "shiftHour" },
    { label: "Start Time", id: "shiftStartTime" },
    { label: "End Time", id: "shiftEndTime" },
    { label: "Grace In Time", id: "graceInTime" },
    { label: "Grace Out Time", id: "graceOutTime" },
    { label: "Working Hour", id: "workingHour" },
  ];

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 pt-4">
          <div className="text_btn">
            <div className="company_name">
              <h1>Shift</h1>
            </div>
            <div className="btn_add">
              <button onClick={() => setOpenInviteMember(true)}>
                Add Shift
              </button>
            </div>
          </div>

          <div className="comp-table">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    {fields.map((field) => (
                      <th
                        key={field.id}
                        style={
                          field.id === "shiftName"
                            ? { borderLeft: "none" }
                            : { borderRight: "none" }
                        }
                      >
                        {field.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {shiftData.map((shift, index) => (
                    <tr key={index}>
                      {fields.map((field) => (
                        <td key={field.id}>{shift[field.id]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {openInviteMember && (
        <RightCustomModal
          isOpen={openInviteMember}
          onClose={handleClose}
          shouldCloseOnOutsideClick={true}
        >
          <AddShift />
        </RightCustomModal>
      )}
    </>
  );
};

export default Shift;
