import React, { useState } from "react";
import fileicon from "../../../assets/img/fileicon.png";
import "./inputlogo.css";

const InputLogo = ({
  name,
  value,
  onChange,
  error,
  touched,
  width,
  showIcon = true,
  buttonPadding = "15px 30px",
  accept = ".jpeg, .jpg, .png, .pdf, .docx", // Added default file types for images and documents
}) => {
  const [fileName, setFileName] = useState(value || "No file chosen");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      onChange(file); // Pass file to Formik
    }
  };

  return (
    <div className="input-logo-container">
      <label className="input-logo-label" htmlFor={`file-input-${name}`}>
        Upload Document (jpeg, png, jpg, pdf, docx only)
      </label>
      <div className="form-group">
        <div className="file-upload-wrapper" style={{ width: width || "100%" }}>
          <div className="file-icon" style={{ height: "35px" }}>
            {showIcon && (
              <img
                src={fileicon}
                alt="file icon"
                style={{ maxHeight: "100%", width: "auto" }}
              />
            )}
          </div>
          <div className="file-name">{fileName}</div>
          <button
            className="btn-upload"
            style={{ padding: buttonPadding }}
            type="button"
            onClick={() =>
              document.getElementById(`file-input-${name}`).click()
            }
          >
            Upload
          </button>
          <input
            type="file"
            id={`file-input-${name}`}
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept={accept} // Allow configurable file types
          />
        </div>
        {error && touched && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
};

export default InputLogo;
