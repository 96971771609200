import React, { useState, useEffect } from "react";
import req_user from "../../../assets/img/req_user.svg";
import cross from "../../../assets/img/cross.png";
import watch_user from "../../../assets/img/watch_user.svg";
import right_mark from "../../../assets/img/right_mark.png";
import upper_mark from "../../../assets/img/upper_mark.png";
import "./request.css";
import ViewRequest from "./ViewRequest";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLeavedata } from "../../../redux/Action";
import moment from "moment";
import Pagination from "../../../Common/Pagination/Pagination";

const Requests = () => {
  const userRole = localStorage.getItem("role");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [onRequest, setOnRequest] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  console.log(currentPage);
  const { getLeaveList } = useSelector((state) => state?.main);
  useEffect(() => {
    // Destructure counts from getLeaveList to use directly
    const totalLeaves = getLeaveList?.data?.totalLeaves || 0;
    const pendingLeaves = getLeaveList?.data?.pendingLeaves || 0;
    const overdueLeaves = getLeaveList?.data?.overdueLeaves || 0;
    const resolvedLeaves = getLeaveList?.data?.resolvedLeaves || 0;
    const escalatedLeaves = getLeaveList?.data?.escalatedLeaves || 0;

    // Construct formatted data with these values
    const formattedAttendanceData = [
      {
        status: "Total Leave Request",
        count: totalLeaves,
        imgSrc: <img src={req_user} alt="req_user" />,
      },
      {
        status: "Pending",
        count: pendingLeaves,
        imgSrc: <img src={cross} alt="cross" />,
      },
      {
        status: "Overdue",
        count: overdueLeaves,
        imgSrc: <img src={watch_user} alt="watch_user" />,
      },
      {
        status: "Resolved",
        count: resolvedLeaves,
        imgSrc: <img src={right_mark} alt="right_mark" />,
      },
      {
        status: "Escalated",
        count: escalatedLeaves,
        imgSrc: <img src={upper_mark} alt="upper_mark" />,
        type: "admin",
      },
    ];

    setAttendanceData(formattedAttendanceData);
    setTableData(getLeaveList?.data?.leaves || []);
  }, [getLeaveList]);

  useEffect(() => {
    dispatch(getLeavedata(currentPage, limit));
  }, [dispatch, currentPage, limit]);

  const OpenRequest = (data) => {
    setOnRequest(true);
    navigate(`/requests/viewrequest/${data}`);
  };

  return (
    <div className="content-wrapper">
      {!onRequest && (
        <>
          <div className="flex-grow-1 pt-4 view-request">
            <div className="leave_wrap">
              {attendanceData
                .filter((record) => !record.type || record.type === userRole)
                .map((record, index) => (
                  <div key={index} className="cardmornings">
                    <div className="totalnumber_card">
                      <div className="left_conetent">
                        <div className="view-iocn">{record.imgSrc}</div>
                      </div>
                      <div className="right_req">
                        <h4>{record.status}</h4>
                        <div className="min_flex_req">
                          <h1>{record.count}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="container-fluid view-request">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead>
                  <tr>
                    <th>Leave Type</th>
                    <th>Leave Duration</th>
                    <th>Days</th>
                    <th>Reason</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData &&
                    tableData.map((items, index) => (
                      <tr key={index}>
                        <td>{items.type}</td>
                        <td>
                          {items?.startDate && items?.endDate
                            ? `${moment(items.startDate).format(
                                "DD MMMM YYYY"
                              )} - ${moment(items.endDate).format(
                                "DD MMMM YYYY"
                              )}`
                            : "-"}
                        </td>
                        <td>{items.duration}</td>
                        <td>{items.reason}</td>
                        <td>
                          <span
                            className={`badge badge-${items.status.toLowerCase()}||"-"`}
                          >
                            {items.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className="btn-view"
                            onClick={() => OpenRequest(items._id)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <Pagination
                listData={getLeaveList?.meta}
                setPageData={setCurrentPage}
                tableData={tableData}
              />
            </div>
          </div>
        </>
      )}

      {onRequest && <ViewRequest />}
    </div>
  );
};

export default Requests;
