import React, { useState } from "react";
import "./pagination.css"; // Importing CSS

const Pagination = ({ listData, setPageData }) => {
  const [currentPage, setCurrentPage] = useState(listData?.currentPage || 1);
  console.log(listData, "listData", currentPage);

  const nextPage = () => {
    if (currentPage < listData?.total_page) {
      setPageData(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setPageData(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  const pageNumbers = [];
  for (let i = 1; i <= listData?.total_page; i++) {
    pageNumbers.push(i);
  }

  const changePages = (data) => {
    setCurrentPage(data);
    setPageData(data);
  };

  return (
    <div>
      {listData?.total_page > 1 && (
        <>
          <ul className="pagination_number">
            <p>page 1 to 100</p>
            {/* Previous Button */}
            {currentPage > 1 && (
              <li className="page-item" onClick={prevPage}>
                <span className="page-link" aria-hidden="true">
                  ‹
                </span>
              </li>
            )}
            {/* Page Numbers */}
            {pageNumbers.map((pageNumber) => (
              <li
                key={pageNumber}
                className={`page-item ${
                  currentPage === pageNumber ? "active" : ""
                }`}
                aria-current={currentPage === pageNumber ? "page" : undefined}
                onClick={() => changePages(pageNumber)}
              >
                <span className="page-link">{pageNumber}</span>
              </li>
            ))}
            {/* Next Button */}
            {currentPage < listData?.total_page && (
              <li className="page-item" onClick={nextPage}>
                <span className="page-link" aria-label="Next »">
                  ›
                </span>
              </li>
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default Pagination;
