import React, { useState } from "react";
import {
  IconDelete,
  IconEdit,
  IconCloudUp,
  IconDown,
  IconSearch,
} from "../../assets/img/dashboard/icons";
import { useNavigate } from "react-router-dom";
const Attendance = () => {
  const [onRequest, setOnRequest] = useState(false);
  const navigate = useNavigate();

  const OpenRequest = () => {
    setOnRequest(true);
    navigate("/attendanceoverview");
  };
  return (
    <div>
      {" "}
      {/* <div className="content-wrapper justify-content-start">
        <div className="container-fluid view-requests">
       

          <div className="table-responsive mt-5">
            <table className="table table-bordered align-middle">
              <thead>
                <tr>
                  <th>Employee ID</th>
                  <th>Employee Name </th>
                  <th>Role</th>
                  <th>Department</th>
                  <th>Shift Time</th>
                  <th>Status</th>
                  <th>Check-in</th>
                  <th>Check-out</th>
                  <th>Work hours</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2341421</td>
                  <td>Ahmed Rashdan </td>
                  <td style={{ opacity: "50%" }}>Help Desk Executive</td>{" "}
                  <td style={{ opacity: "50%" }}>IT Department</td>
                  <td style={{ opacity: "50%" }}>Morning</td>
                  <td>
                    <span className="badge badge-approved">
                      Work from office
                    </span>
                  </td>
                  <td style={{ color: "#2C357D" }}>09:00</td>
                  <td style={{ color: "#2C357D" }}>18:00</td>
                  <td style={{ color: "#2C357D" }}>10h 2m</td>
                  <td>
                    <button className="btn  btn-view">View</button>
                  </td>
                </tr>
                <tr>
                  <td>2341421</td>
                  <td>Ahmed Rashdan </td>
                  <td style={{ opacity: "50%" }}>Help Desk Executive</td>
                  <td style={{ opacity: "50%" }}>IT Department</td>
                  <td style={{ opacity: "50%" }}>Morning</td>
                  <td>
                    <span className="badge badge-rejected ">Absent</span>
                  </td>
                  <td style={{ color: "#AA0000" }}>09:00</td>
                  <td style={{ color: "#AA0000" }}>18:00</td>
                  <td style={{ color: "#AA0000" }}>10h 2m</td>
                  <td>
                    <button className="btn  btn-view">View</button>
                  </td>
                </tr>
                <tr>
                  <td>2341421</td>
                  <td>Ahmed Rashdan </td>
                  <td style={{ opacity: "50%" }}>Help Desk Executive</td>
                  <td style={{ opacity: "50%" }}>IT Department</td>
                  <td style={{ opacity: "50%" }}>Morning</td>
                  <td>
                    <span className="badge badge-arrival">Late arrival</span>
                  </td>
                  <td style={{ color: "#D5B500" }}>09:00</td>
                  <td style={{ color: "#2C357D" }}>18:00</td>
                  <td style={{ color: "#2C357D" }}>10h 2m</td>
                  <td>
                    <button className="btn  btn-view">View</button>
                  </td>
                </tr>
                <tr>
                  <td>2341421</td>
                  <td>Ahmed Rashdan </td>
                  <td style={{ opacity: "50%" }}>Help Desk Executive</td>
                  <td style={{ opacity: "50%" }}>IT Department</td>
                  <td style={{ opacity: "50%" }}>Morning</td>
                  <td>
                    <span className="badge badge-home">Work from home</span>
                  </td>
                  <td style={{ color: "#252C58" }}>09:00</td>
                  <td style={{ color: "#252C58" }}>18:00</td>
                  <td style={{ color: "#252C58" }}>10h 2m</td>
                  <td>
                    <button className="btn  btn-view">View</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
      <div className="content-wrapper justify-content-start">
        <div className=" ">
          <div className="card-header d-flex align-items-center justify-content-start gap-4 p-0">
            <div className="kidsselect flex">
              <h5 className="card-title m-0 me-2">Attendance Overview</h5>
            </div>
            <div className="d-flex  align-items-center justify-content-start gap-3 w-100">
              <div className="table-search-bar">
                <IconSearch />
                <input
                  type="text"
                  className="form-control"
                  style={{ border: "none" }}
                  placeholder="Quick Search..."
                />
              </div>
              <div className="calendar_pad">
                <div className="calendar">
                  <input type="date" />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid view-requestb">
            {/* Responsive Table */}
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead>
                  <tr>
                    <th>Employee ID</th>
                    <th>Employee Name </th>
                    <th>Role</th>
                    <th>Department</th>
                    <th>Shift Time</th>
                    <th>Status</th>
                    <th>Check-in</th>
                    <th>Check-out</th>
                    <th>Work hours</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2341421</td>
                    <td>Ahmed Rashdan </td>
                    <td style={{ opacity: "50%" }}>Help Desk Executive</td>{" "}
                    <td style={{ opacity: "50%" }}>IT Department</td>
                    <td style={{ opacity: "50%" }}>Morning</td>
                    <td>
                      <span className="badge badge-approved">Present</span>
                    </td>
                    <td style={{ color: "#2C357D" }}>09:00</td>
                    <td style={{ color: "#2C357D" }}>18:00</td>
                    <td style={{ color: "#2C357D" }}>10h 2m</td>
                    <td>
                      <button
                        className="btn-view"
                        style={{ padding: "12px 25px" }}
                        onClick={OpenRequest}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>2341421</td>
                    <td>Ahmed Rashdan </td>
                    <td style={{ opacity: "50%" }}>Help Desk Executive</td>
                    <td style={{ opacity: "50%" }}>IT Department</td>
                    <td style={{ opacity: "50%" }}>Morning</td>
                    <td>
                      <span className="badge badge-rejected ">Absent</span>
                    </td>
                    <td style={{ color: "#AA0000" }}>09:00</td>
                    <td style={{ color: "#AA0000" }}>18:00</td>
                    <td style={{ color: "#AA0000" }}>10h 2m</td>
                    <td>
                      <button
                        className="btn-view"
                        style={{ padding: "12px 25px" }}
                        onClick={OpenRequest}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>2341421</td>
                    <td>Ahmed Rashdan </td>
                    <td style={{ opacity: "50%" }}>Help Desk Executive</td>
                    <td style={{ opacity: "50%" }}>IT Department</td>
                    <td style={{ opacity: "50%" }}>Morning</td>
                    <td>
                      <span className="badge badge-arrival">Late arrival</span>
                    </td>
                    <td style={{ color: "#D5B500" }}>09:00</td>
                    <td style={{ color: "#2C357D" }}>18:00</td>
                    <td style={{ color: "#2C357D" }}>10h 2m</td>
                    <td>
                      <button
                        className="  btn-view"
                        style={{ padding: "12px 25px" }}
                        onClick={OpenRequest}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>2341421</td>
                    <td>Ahmed Rashdan </td>
                    <td style={{ opacity: "50%" }}>Help Desk Executive</td>
                    <td style={{ opacity: "50%" }}>IT Department</td>
                    <td style={{ opacity: "50%" }}>Morning</td>
                    <td>
                      <span className="badge  badge-approved">Present</span>
                    </td>
                    <td style={{ color: "#252C58" }}>09:00</td>
                    <td style={{ color: "#252C58" }}>18:00</td>
                    <td style={{ color: "#252C58" }}>10h 2m</td>
                    <td>
                      <button
                        className="btn-view"
                        style={{ padding: "12px 25px" }}
                        onClick={OpenRequest}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendance;
