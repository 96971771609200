import React from "react";
import "./checkbox.css";

const Checkbox = ({ id, label, checked, onChange, width }) => {
  return (
    <div className="checkboxWrapper" style={{ width: width || "100%" }}>
      <label htmlFor={id} className="checkboxLabel">
        {label}
      </label>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        className="checkboxInput"
      />
    </div>
  );
};

export default Checkbox;
