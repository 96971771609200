import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo_s from "../assets/img/logo_s.svg";
import mail from "../assets/img/mail.png";
import styles from "./loginPage.module.scss";
import { forgotpassword, login } from "../redux/Action";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [togglePassword, setTogglePassword] = useState(false);

  const toggleVisibility = (e) => {
    setTogglePassword(!togglePassword);
  };
  const initialValues = {
    email: "",
    password: "",
  };

  const {
    values,
    errors,
    touched,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    handleReset,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please enter your email"),
      password: Yup.string().required("Please enter your password"),
    }),
    onSubmit: (values, action) => {
      // dispatch(buttonLoading(true))
      dispatch(forgotpassword(values, router));
      action.resetForm();
    },
  });

  return (
    <div className="login-page">
      <div className={styles.wrapper_login}>
        <div className={styles.wrap_login}>
          <img src={logo_s} alt="" />
        </div>
        <form className={styles.login_form} onSubmit={handleSubmit}>
          <h1 className={styles.forget_pass}>Forgot Password</h1>
          <div className={`${styles.wrap_input} pb-4 `}>
            <input
              className={styles.input100}
              type="text"
              name="email"
              placeholder="Enter Company Mail"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <span className={styles.focus_input100}>
              <img src={mail} alt="" />
            </span>
            {errors.email && touched.email && (
              <div className={styles.input_feedback}>{errors.email}</div>
            )}
          </div>

          <button className={styles.btn_dark} type="submit">
            Send OTP
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
