import React from "react";

const SvgforDashboard = () => {
  return (
    <div>
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38.75 29.9999C38.75 29.6124 38.5625 29.2374 38.25 28.9999L28.25 21.4999C27.875 21.2124 27.375 21.1749 26.9375 21.3874C26.5125 21.5999 26.25 22.0249 26.25 22.4999V24.9999H12.5C11.8125 24.9999 11.25 25.5624 11.25 26.2499V33.7499C11.25 34.4374 11.8125 34.9999 12.5 34.9999H26.25V37.4999C26.25 37.9749 26.5125 38.3999 26.9375 38.6249C27.1125 38.6999 27.3125 38.7499 27.5 38.7499C27.7625 38.7499 28.0375 38.6624 28.25 38.4999L38.25 30.9999C38.5625 30.7624 38.75 30.3874 38.75 29.9999ZM27.5 32.4999H18.75V27.4999H27.5C28.1875 27.4999 28.75 26.9374 28.75 26.2499V24.9999L35.4125 29.9999L28.75 34.9999V33.7499C28.75 33.0624 28.1875 32.4999 27.5 32.4999ZM13.75 32.4999V27.4999H16.25V32.4999H13.75Z"
          fill="#2C357D"
        />
        <path
          d="M48.7125 4.72508L48.1 4.85008L48.7 4.68758C48.7 4.66258 48.6875 4.62508 48.675 4.57508C48.6625 4.52508 48.65 4.47508 48.625 4.42508C48.6 4.37508 48.55 4.31258 48.5125 4.26258C48.475 4.21258 48.4375 4.15008 48.35 4.06258C48.325 4.03758 48.175 3.93758 48.15 3.91258C48.1125 3.88758 47.9875 3.82508 47.95 3.81258C47.8375 3.77508 47.7375 3.76258 47.725 3.76258C47.625 3.76258 47.5 3.73758 47.4 3.76258C47.325 3.77508 47.25 3.78758 47.1875 3.80008C47.1625 3.80008 47.125 3.81258 47.075 3.82508C47.025 3.83758 46.975 3.85008 46.9375 3.87508L36.9375 8.87508C36.5125 9.10008 36.25 9.52508 36.25 10.0001V20.0001C36.25 20.6876 36.8125 21.2501 37.5 21.2501C38.1875 21.2501 38.75 20.6876 38.75 20.0001V10.7751L46.25 7.02508V52.9876L38.75 49.2376V40.0001C38.75 39.3126 38.1875 38.7501 37.5 38.7501C36.8125 38.7501 36.25 39.3126 36.25 40.0001V50.0001C36.25 50.4751 36.5125 50.9001 36.9375 51.1126L46.9625 56.1251C46.9875 56.1376 47 56.1376 47.025 56.1501C47.1625 56.2126 47.3 56.2376 47.45 56.2501C47.4625 56.2501 47.4875 56.2501 47.5 56.2501C47.7 56.2501 47.9 56.2001 48.0875 56.1001C48.1125 56.0876 48.2 56.0251 48.225 56.0126C48.35 55.9251 48.4625 55.8126 48.5375 55.6876C48.575 55.6501 48.6 55.6001 48.625 55.5626C48.65 55.5126 48.675 55.4501 48.6875 55.3876C48.7 55.3376 48.7125 55.2876 48.725 55.2751C48.7375 55.2501 48.7375 55.2001 48.75 55.1626C48.7375 55.1001 48.75 55.0501 48.75 55.0001V5.00008C48.75 4.95008 48.7375 4.90008 48.7375 4.85008C48.725 4.80008 48.7125 4.75008 48.7125 4.72508Z"
          fill="#2C357D"
        />
        <path
          d="M43.75 40V20C43.75 19.3125 43.1875 18.75 42.5 18.75C41.8125 18.75 41.25 19.3125 41.25 20V40C41.25 40.6875 41.8125 41.25 42.5 41.25C43.1875 41.25 43.75 40.6875 43.75 40Z"
          fill="#2C357D"
        />
      </svg>
    </div>
  );
};

export default SvgforDashboard;
