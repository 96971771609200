import React, { useState } from "react";
import "./addrole.css"; // Assuming you have CSS to style the form
import { VscClose } from "react-icons/vsc";

const AddRole = () => {
  // Define the fields dynamically for the Role component
  const fields = [
    { label: "Role Name", type: "text", id: "role-name" },
    {
      label: "Role Description",
      type: "select",
      id: "role-description",
      options: ["Admin", "Manager", "Team Lead", "Employee", "Intern"],
    },
  ];

  // State to manage form input values for the Role component
  const [formData, setFormData] = useState({
    "role-name": "",
    "role-description": "",
  });

  // Handle form changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  return (
    <>
      <div className="addrole">
        <div className="d-flex justify-content-end  ">
          <VscClose size={20} />
        </div>
        <h2>Add New Company</h2>
      </div>
      <div className="border-bottom"></div>
      <div
        className="d-flex justify-content-between gap-1 content_common"
        style={{ borderBottom: "2px solid #2C357D2E" }}
      >
        {" "}
        <div className="label_name">
          {fields.map((field) => (
            <label key={field.id} htmlFor={field.id}>
              {field.label}
            </label>
          ))}
        </div>
        <div className="input_group mx-auto">
          {fields.map((field) => (
            <div key={field.id}>
              {field.type === "select" ? (
                <select
                  id={field.id}
                  value={formData[field.id]}
                  onChange={handleChange}
                  className="form-group"
                >
                  <option value="">Select {field.label}</option>
                  {field.options.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : field.type === "file" ? (
                <>
                  {/* <div className="d-flex file-input-container" > */}
                  <div className="d-flex ">
                    <input
                      type="file"
                      id={field.id}
                      accept="image/*"
                      onChange={handleChange}
                      className="form-group"
                      // className="form-group file-input  "
                    />
                  </div>
                </>
              ) : (
                <input
                  className="form-group"
                  type={field.type}
                  id={field.id}
                  value={formData[field.id]}
                  onChange={handleChange}
                  placeholder={`Enter ${field.label}`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="btnblue px-4 pb-5 mt-1">
        <button>Submit</button>
      </div>
    </>
  );
};

export default AddRole;
