import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import "../../../Common/Form/form.css";
import InputField from "../../../components/common/inputfield/InputField.jsx";
import Selector from "../../../components/common/selects/Selector.jsx";
import Checkbox from "../../../components/common/checkbox/checkbox.jsx";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import InputLogo from "../../../components/common/customlogo/InputLogo.js";
import { addUsers, updateUserData } from "../../../redux/Action/index.js";
import camera from "../../../assets/img/camera.png";
import Rectangle from "../../../assets/img/Rectangle.png";
import moment from "moment";

const EditUser = ({ addFormCompleted, formType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedValue, setSelectedValue] = useState("");
  const [checkedItems, setCheckedItems] = useState({
    allowEpf: false,
    css: false,
    javascript: false,
  });
  const [error, setError] = useState("");
  const userRole = localStorage.getItem("role");
  const [activeTab, setActiveTab] = useState("Employee Information");
  const [updateForm, setUpdateForm] = useState();

  const [imagePreview, setImagePreview] = useState(null);
  const { getAllUserList } = useSelector((state) => state?.main);
  const pathname = location.pathname;

  const pathParts = pathname.split("/");

  const userId = pathParts[pathParts.length - 1];

  const tabs = [
    {
      name: "Employee Information",
      fields: [
        { label: "Employee Number", type: "tel", name: "employeenumber" },
        { label: "Title", type: "text", name: "title" },
        { label: "Employee Name", type: "text", name: "empl_name" },
        { label: "Employee Nick Name", type: "text", name: "nick_name" },
      ],
    },

    {
      name: "Joining Details",
      fields: [
        { label: "Joining Date", name: "joining_date", type: "date" },
        { label: "Position", name: "position", type: "text" },
        { label: "Department", name: "department", type: "text" },
      ],
    },
    {
      name: "Current Position",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Employee Identity",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Education",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Address",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Emergency Contact",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Bank Account",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "ESI Account",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "PF Account",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Documents",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
  ];
  const options = [
    { value: "A-", label: "A-" },
    { value: "B-", label: "B-" },
    { value: "A+", label: "A+" },
    { value: "B+", label: "B+" },
    { value: "O+", label: "O+" },
    { value: "O-", label: "O-" },
    { value: "AB-", label: "AB-" },
    { value: "AB+", label: "AB+" },
  ];
  const titleoptions = [
    { value: "mr", label: "Mr." },
    { value: "mrs", label: "Mrs." },
    { value: "miss", label: "Miss" },
    { value: "ms", label: "Ms." },
    { value: "dr", label: "Dr." },
    { value: "prof", label: "Prof." },
  ];
  const genderoptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];
  const maritalStatus = [
    { value: "unmarried", label: "UnMarried" },
    { value: "married", label: "Married" },
  ];
  const nationalityOption = [
    { value: "india", label: "India" },
    { value: "uk", label: "United Kingdom" },
    { value: "usa", label: "United States" },
    { value: "canada", label: "Canada" },
  ];
  const residentialstatusoptions = [
    { value: "rented", label: "Rented" },
    { value: "owned", label: "Owned" },
  ];
  const placeofoptions = [
    { value: "mumbai", label: "Mumbai" },
    { value: "delhi", label: "Delhi" },
    { value: "london", label: "London" },
    { value: "new_york", label: "New York" },
  ];
  const religionoptions = [
    { value: "hindu", label: "Hindu" },
    { value: "muslim", label: "Muslim" },
    { value: "christian", label: "Christian" },
    { value: "buddhist", label: "Buddhist" },
    { value: "sikh", label: "Sikh" },
    { value: "jain", label: "Jain" },
    { value: "other", label: "Other" },
  ];
  const referredbyoptions = [
    { value: "friend", label: "Friend" },
    { value: "family", label: "Family" },
    { value: "colleague", label: "Colleague" },
  ];
  const assignedtooptions = [
    { value: "marketing", label: "Marketing" },
    { value: "sales", label: "Sales" },
  ];
  const costcenteroptions = [
    { value: "marketing", label: "Marketing" },
    { value: "sales", label: "Sales" },
  ];
  const designationoptions = [
    { value: "marketing", label: "Marketing" },
    { value: "sales", label: "Sales" },
  ];
  const locationoptions = [
    { value: "office", label: "Office" },
    { value: "home", label: "Home" },
  ];
  const optionscompany = [
    { value: "india", label: "India" },
    { value: "landon", label: "Landon" },
  ];
  const storenameoptions = [
    { value: "india", label: "India" },
    { value: "landon", label: "Landon" },
  ];
  const documentType = [
    { value: "passport", label: "Passport" },
    { value: "driving_license", label: "Driving License" },
  ];

  const reportingtooptions = [
    { value: "india", label: "India" },
    { value: "landon", label: "Landon" },
  ];
  const shiftoptions = [
    { value: "morning", label: "Morning" },
    { value: "afternoon", label: "Afternoon" },
    { value: "evening", label: "Evening" },
    { value: "night", label: "Night" },
  ];
  const attendanceSchemeoptions = [
    { value: "morning", label: "Morning" },
    { value: "afternoon", label: "Afternoon" },
    { value: "evening", label: "Evening" },
    { value: "night", label: "Night" },
  ];

  const staffoptions = [
    { value: "india", label: "India" },
    { value: "uk", label: "United Kingdom" },
    { value: "usa", label: "United States" },
    { value: "japan", label: "Japan" },
  ];
  const presentcityoptions = [
    { value: "mumbai", label: "Mumbai" },
    { value: "delhi", label: "Delhi" },
    { value: "london", label: "London" },
  ];
  const qualificationoptions = [
    { value: "high_school", label: "High School" },
    { value: "diploma", label: "Diploma" },
    { value: "associate_degree", label: "Associate Degree" },
    { value: "bachelors", label: "Bachelor's Degree" },
    { value: "masters", label: "Master's Degree" },
    { value: "phd", label: "Doctorate (PhD)" },
    { value: "postdoctoral", label: "Postdoctoral" },
    { value: "certificate", label: "Certificate" },
    { value: "vocational", label: "Vocational Training" },
    { value: "mba", label: "MBA" },
    { value: "engineering", label: "Engineering" },
    { value: "medicine", label: "Medicine" },
    { value: "law", label: "Law Degree" },
    { value: "chartered_accountant", label: "Chartered Accountant" },
    { value: "architecture", label: "Architecture" },
    { value: "it_certification", label: "IT Certification" },
    { value: "other", label: "Other" },
  ];
  const presentstateoptions = [
    { value: "maharashtra", label: "Maharashtra" },
    { value: "delhi", label: "Delhi" },
    { value: "greater_london", label: "Greater London" },
  ];
  const permanentcityoptions = [
    { value: "mumbai", label: "Mumbai" },
    { value: "delhi", label: "Delhi" },
  ];
  const permanentstateoptions = [
    { value: "maharashtra", label: "Maharashtra" },
    { value: "delhi", label: "Delhi" },
  ];
  const permanentcountryoptions = [
    { value: "india", label: "India" },
    { value: "landon", label: "Landon" },
  ];
  const vrificationstatusoptions = [
    { value: "verified", label: "Verified" },
    { value: "pending", label: "Pending" },
  ];
  const relationshipoptions = [
    { value: "parent", label: "Parent" },
    { value: "sibling", label: "Sibling" },
    { value: "husband", label: "Husband" },
    { value: "sibling", label: "Sibling" },
    { value: "friend", label: "Friend" },
    { value: "wife", label: "Wife" },
    { value: "relative", label: "Relative" },
    { value: "other", label: "Other" },
  ];
  const presentcountryoptions = [
    { value: "india", label: "India" },
    { value: "uk", label: "United Kingdom" },
  ];
  const banknameoptions = [
    { value: "hdfc", label: "HDFC" },
    { value: "icici", label: "ICICI" },
    { value: "sbi", label: "State Bank of India" },
  ];
  const accounttypeoptions = [
    { value: "savings", label: "Savings" },
    { value: "current", label: "Current" },
  ];
  const paymenttypeoptions = [
    { value: "credit_card", label: "Credit Card" },
    { value: "debit_card", label: "Debit Card" },
    { value: "net_banking", label: "Net Banking" },
  ];
  const agency_nameoptions = [
    { value: "agency1", label: "Agency 1" },
    { value: "agency2", label: "Agency 2" },
  ];
  const documenttypeoptions = [
    { value: "identity_proof", label: "Identity Proof" },
    { value: "address_proof", label: "Address Proof" },
  ];

  const documentItems = [
    { name: "aadharCard", label: "Aadhar Card" },
    { name: "panCard", label: "Pan Card" },
    { name: "passBook", label: "Pass Book / Cancelled Cheque" },
    { name: "relievingLetter", label: "Previous Org Relieving Letter" },
    { name: "paySlip", label: "Previous Org Pay Slip" },
  ];
  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setCheckedItems((prevItems) => ({
      ...prevItems,
      [id]: checked,
    }));
  };
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleNext = () => {
    const currentIndex = tabs.findIndex((tab) => tab.name === activeTab);
    if (currentIndex < tabs.length - 1) {
      setActiveTab(tabs[currentIndex + 1].name);
    }
  };

  const handlePrevious = () => {
    const currentIndex = tabs.findIndex((tab) => tab.name === activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabs[currentIndex - 1].name);
    }
  };

  useEffect(() => {
    if (userId) {
      const myData = getAllUserList?.data.find((item) => item._id == userId);

      setUpdateForm(myData);
    } else {
      setUpdateForm({});
    }
  }, [userId]);

  return (
    <>
      <div className="container mx-auto mt-4 mb-4 ">
        <Formik
          initialValues={{
            // email: "",

            employeenumber: updateForm?.employeenumber || "",
            designation: updateForm?.designation || "",
            marital: updateForm?.maritalStatus || "",
            roles: updateForm?.role || "",
            assignedto: updateForm?.assignedTo || "",
            title: updateForm?.title || "",
            gender: updateForm?.gender || "",
            nationality: updateForm?.nationality || "",
            residentialstatus: updateForm?.residentialStatus || "",
            religion: updateForm?.religion || "",
            attendanceScheme: updateForm?.attendanceScheme || "",
            reportingto: updateForm?.reportingTo || "",
            bloodgroup: updateForm?.bloodGroup || "",
            mobilenumber: updateForm?.phone || "",
            empl_name: updateForm?.name,
            nick_name: updateForm?.nickName || "",
            birth: updateForm?.dob
              ? moment(updateForm.dob).format("DD MMMM YYYY")
              : "",
            marriage_date: updateForm?.marriageDate
              ? moment(updateForm.marriageDate).format("DD MMMM YYYY")
              : "",

            spouse_name: updateForm?.spouseName || "",
            presentcity: updateForm?.presentCity || "",
            presentstate: updateForm?.presentState || "",
            presentcountry: updateForm?.presentCountry || "",
            presentpincode: updateForm?.presentPincode || "",
            aadharnumber: updateForm?.aadharNumber || "",
            bankbranch: updateForm?.bankBranch || "",
            bankaccountnumber: updateForm?.accountNumber || "",
            iFSC: updateForm?.ifscCode || "",
            agency_name: updateForm?.agencyName || "",
            verificationcompleted: updateForm?.verifcationCompletedOn || "",
            pf_join: updateForm?.employeenumber || "",
            esinumber: updateForm?.esiNumber || "",
            uan: updateForm?.uanNumber || "",
            family_pf_number: updateForm?.familyPFNumber || "",
            documenttype: updateForm?.documentType || "",
            loginusername: updateForm?.loginUserName || "",
            employee_email: updateForm?.email || "",
            Place_birth: updateForm?.placeOfBirth || "",
            empl_father: updateForm?.fatherName || "",

            joining_on: updateForm?.joiningOn
              ? moment(updateForm.joiningOn).format("DD MMMM YYYY")
              : "",
            confirmation_date: updateForm?.confirmationDate
              ? moment(updateForm.confirmationDate).format("DD MMMM YYYY")
              : "",
            status: updateForm?.status || "",
            confirmed: updateForm?.confirmed || "",
            previous_experience: updateForm?.previousExperience || "",
            experience: updateForm?.currentCompanyExperience || "",
            notice_period: updateForm?.noticePeriod || "",
            probation_period: updateForm?.probationPeriod || "",
            totalExperience: updateForm?.totalExperience || "",
            referredby: updateForm?.referredBy || "",
            costcenter: updateForm?.costCenter || "",
            shift: updateForm?.shift || "",
            staff: updateForm?.staff || "",
            storename: updateForm?.storeName || "",
            location: updateForm?.location || "",
            facerecognition: updateForm?.faceRecognition || "",
            presentaddress1: updateForm?.presentAddress1 || "",
            presentaddress2: updateForm?.presentAddress2 || "",
            presentaddress3: updateForm?.presentAddress3 || "",
            Phone1: updateForm?.presentPhone1 || "",
            phone2: updateForm?.presentPhone2 || "",
            presentext: updateForm?.presentExt || "",
            presentemail: updateForm?.presentEmail || "",
            presentmobile: updateForm?.presentMobile || "",
            presentfax: updateForm?.presentFax || "",
            permanentaddress1: updateForm?.permanentAddress1 || "",
            permanentaddress2: updateForm?.permanentAddress2 || "",
            permanentaddress3: updateForm?.permanentAddress3 || "",
            permanentcity: updateForm?.permanentCity || "",
            permanentstate: updateForm?.permanentState || "",
            permanentcountry: updateForm?.permanentCountry || "",
            permanentpincode: updateForm?.permanentPincode || "",
            permanentphone1: updateForm?.permanentPhone1 || "",
            parmanentphone2: updateForm?.permanentPhone2 || "",
            parmanentemail: updateForm?.permanentEmail || "",
            parmanentmobile: updateForm?.permanentMobile || "",
            parmanentfax: updateForm?.parmanentFax || "",
            parmanentext: updateForm?.permanentExt || "",
            vrificationstatus: updateForm?.verificationStatus || "",
            bankname: updateForm?.bankName || "",
            iban: updateForm?.iban_swift_bic_code || "",
            accounttype: updateForm?.accountType || "",
            paymenttype: updateForm?.paymentType || "",
            bank_records: updateForm?.nameAsPerBank || "",
            dd_Payable: updateForm?.ddPayableAt || "",
            pf_num: updateForm?.pfNumber || "",
            // company2: updateForm?.employeenumber || "",
            company1: updateForm?.company1 || "",
            nameasperaadhar: updateForm?.nameAsPerAadhar || "",
            aadhaarenrolment: updateForm?.aadharEnrolmentNo || "",
            permanentaccountnumber: updateForm?.permanentAccountNumber || "",
            nameasperpan: updateForm?.nameAsPerPan || "",
            qualification: updateForm?.eduQualification || "",
            institutename: updateForm?.eduInstituteName || "",
            grade: updateForm?.eduGrade || "",
            nameemergency: updateForm?.Nameemergency || "",
            relationship: updateForm?.emerRelation || "",
            emeraddress1: updateForm?.emeraddress1 || "",
            emeraddress2: updateForm?.emerAddress2 || "",
            emeraddress3: updateForm?.emerAddress3 || "",
            emercity: updateForm?.emerCity || "",
            emerpincode: updateForm?.emerPincode || "",
            emerstate: updateForm?.emerState || "",
            emerfax: updateForm?.emerFax || "",
            emercountry: updateForm?.emerCountry || "",
            emeremail: updateForm?.emerEmail || "",
            emermobile: updateForm?.emerMobile || "",
            emertext: updateForm?.emerExt || "",
            emerphone2: updateForm?.emerPhone2 || "",
            emerphone1: updateForm?.emerPhone1 || "",
            accountnumber: updateForm?.emerMobile || "",
            empolyeedocumenttype: updateForm?.pfDocumentType || "",
            empolyeebankname: updateForm?.empIdentBankName || "",
            nameinbankaccount: updateForm?.empIdenNameInBank || "",
            bankifsc: updateForm?.empIdentIfsc || null,
            image: updateForm?.profileImage || null,
            aadharCard: updateForm?.aadharCardDocument || null,
            panCard: updateForm?.panCardDocument || null,
            passBook: updateForm?.passBookOrCancelledCheque || null,
            relievingLetter: updateForm?.prevOrgRelivingLetter || null,
            paySlip: updateForm?.prevOrgPayslip || null,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.image) {
              errors.image = "image is required";
            }
            if (!values.employee_email) {
              errors.employee_email = "Email is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                values.employee_email
              )
            ) {
              errors.employee_email = "Invalid email address";
            }
            if (!values.employeenumber) {
              errors.employeenumber = "Employee number is required";
            }
            if (!values.mobilenumber) {
              errors.mobilenumber = "Mobile number is required";
            }
            if (!values.gender) {
              errors.gender = "Gender is required";
            }
            if (!values.designation) {
              errors.designation = "Designation is required";
            }
            // if (!values.department) {
            //   errors.department = "Department is required";
            // }
            if (!values.experience) {
              errors.experience = "Company experience is required";
            }
            if (!values.company1) {
              errors.company1 = "Company is required";
            }
            // if (!values.company2) {
            //   errors.company2 = "Company is required";
            // }
            if (!values.roles) {
              errors.roles = "Role is required";
            }
            if (!values.assignedto) {
              errors.assignedto = " Assigned to is required";
            }
            if (!values.empl_father) {
              errors.empl_father = "Empolyee father name is required";
            }
            if (!values.title) {
              errors.title = "Title is required";
            }
            if (!values.marital) {
              errors.marital = "Marital status is required";
            }
            if (!values.nationality) {
              errors.nationality = "Nationality is required";
            }
            if (!values.residentialstatus) {
              errors.residentialstatus = "Residential status is required";
            }
            if (!values.Place_birth) {
              errors.Place_birth = "Place of birth is required";
            }
            if (!values.religion) {
              errors.religion = "Religion is required";
            }
            if (!values.attendanceScheme) {
              errors.attendanceScheme = "Attendance scheme is required";
            }
            if (!values.reportingto) {
              errors.reportingto = "Reporting to is required";
            }
            if (!values.bloodgroup) {
              errors.bloodgroup = "Blood group is required";
            }
            if (!values.empl_name) {
              errors.empl_name = "Employee name is required";
            }
            if (!values.nick_name) {
              errors.nick_name = "Nickname is required";
            }
            if (!values.birth) {
              errors.birth = "Date of birth is required";
            }
            if (!values.marriage_date) {
              errors.marriage_date = "Marriage date is required";
            }
            if (!values.spouse_name) {
              errors.spouse_name = "Spouse name is required";
            }
            if (!values.phone2) {
              errors.phone2 = "Phone 2 is required";
            }
            if (!values.presentcity) {
              errors.presentcity = "City is required";
            }
            if (!values.presentemail) {
              errors.presentemail = " Email is required";
            }
            if (!values.presentaddress3) {
              errors.presentaddress3 = "Address 3 is required";
            }
            if (!values.presentstate) {
              errors.presentstate = "State is required";
            }
            if (!values.presentcountry) {
              errors.presentcountry = "Country is required";
            }
            if (!values.presentpincode) {
              errors.presentpincode = "Pincode is required";
            }
            if (!values.aadharnumber) {
              errors.aadharnumber = "Aadhar number is required";
            }
            if (!values.facerecognition) {
              errors.facerecognition = "Face recognition is required";
            }
            if (!values.bankbranch) {
              errors.bankbranch = "Bank branch is required";
            }
            if (!values.bankaccountnumber) {
              errors.bankaccountnumber = "Bank account number is required";
            }
            if (!values.iFSC) {
              errors.iFSC = "IFSC code is required";
            }
            if (!values.bankname) {
              errors.bankname = "Bank name is required";
            }
            if (!values.verificationcompleted) {
              errors.verificationcompleted = "Verification is required";
            }
            if (!values.vrificationstatus) {
              errors.vrificationstatus = "Verification status is required";
            }
            if (!values.agency_name) {
              errors.agency_name = "Agency name status is required";
            }
            if (!values.pf_join) {
              errors.pf_join = "PF join date is required";
            }
            if (!values.esinumber) {
              errors.esinumber = "ESI number is required";
            }
            if (!values.uan) {
              errors.uan = "UAN is required";
            }
            if (!values.family_pf_number) {
              errors.family_pf_number = "Family PF number is required";
            }
            if (!values.pf_num) {
              errors.pf_num = "PF number is required";
            }
            if (!values.documenttype) {
              errors.documenttype = "Document type is required";
            }
            if (!values.presentfax) {
              errors.presentfax = " Fax is required";
            }
            if (!values.loginusername) {
              errors.loginusername = "Login username is required";
            }
            if (!values.confirmation_date) {
              errors.confirmation_date = "Confirmation date is required";
            }
            if (!values.status) {
              errors.status = "Status is required";
            }
            if (!values.confirmed) {
              errors.confirmed = "Confirmation status is required";
            }
            if (!values.joining_on) {
              errors.joining_on = "Joining Date is required";
            }
            if (!values.previous_experience) {
              errors.previous_experience = "Previous experience is required";
            }

            if (!values.notice_period) {
              errors.notice_period = "Notice period is required";
            }
            if (!values.probation_period) {
              errors.probation_period = "Probation period is required";
            }
            if (!values.totalExperience) {
              errors.totalExperience = "Total experience is required";
            }
            if (!values.referredby) {
              errors.referredby = "Referred by is required";
            }
            if (!values.costcenter) {
              errors.costcenter = "Cost center is required";
            }
            if (!values.shift) {
              errors.shift = "Shift is required";
            }
            if (!values.staff) {
              errors.staff = "Staff status is required";
            }
            if (!values.storename) {
              errors.storename = "Store name is required";
            }
            if (!values.location) {
              errors.location = "Location is required";
            }
            if (!values.presentaddress1) {
              errors.presentaddress1 = "Address 1 is required";
            }
            if (!values.Phone1) {
              errors.Phone1 = "Phone 1 is required";
            }
            if (!values.presentext) {
              errors.presentext = "Ext is required";
            }
            if (!values.presentmobile) {
              errors.presentmobile = "Mobile is required";
            }
            if (!values.permanentaddress1) {
              errors.permanentaddress1 = "Address 1 is required";
            }
            if (!values.permanentaddress2) {
              errors.permanentaddress2 = "Address 2 is required";
            }
            if (!values.permanentaddress3) {
              errors.permanentaddress3 = "Address 3 is required";
            }
            if (!values.permanentcity) {
              errors.permanentcity = " City is required";
            }
            if (!values.permanentstate) {
              errors.permanentstate = " State is required";
            }
            if (!values.permanentcountry) {
              errors.permanentcountry = " Country is required";
            }
            if (!values.permanentpincode) {
              errors.permanentpincode = "Pincode is required";
            }
            if (!values.permanentphone1) {
              errors.permanentphone1 = " Phone 1 is required";
            }
            if (!values.parmanentphone2) {
              errors.parmanentphone2 = " Phone 2 is required";
            }
            if (!values.parmanentemail) {
              errors.parmanentemail = "Email is required";
            }
            if (!values.parmanentext) {
              errors.parmanentext = "Ext is required";
            }
            if (!values.parmanentfax) {
              errors.parmanentfax = "Fax is required";
            }
            if (!values.parmanentmobile) {
              errors.parmanentmobile = "Mobile is required";
            }
            if (!values.presentaddress2) {
              errors.presentaddress2 = "Address 2 is required";
            }
            if (!values.iban) {
              errors.iban = "IBAN is required";
            }
            if (!values.accounttype) {
              errors.accounttype = "Account type is required";
            }
            if (!values.paymenttype) {
              errors.paymenttype = "Payment type is required";
            }
            if (!values.bank_records) {
              errors.bank_records = "Bank records are required";
            }
            if (!values.dd_Payable) {
              errors.dd_Payable = "DD Payable is required";
            }
            if (!values.nameasperaadhar) {
              errors.nameasperaadhar = "Name of aadhar is required";
            }
            if (!values.aadhaarenrolment) {
              errors.aadhaarenrolment = "Aadhar Enrolment is required";
            }
            if (!values.qualification) {
              errors.qualification = "Qualification is required";
            }
            if (!values.institutename) {
              errors.institutename = "Institute Name is required";
            }
            if (!values.grade) {
              errors.grade = "Grade is required";
            }
            if (!values.nameemergency) {
              errors.nameemergency = "Name Emergency is required";
            }
            if (!values.relationship) {
              errors.relationship = "Relationship is required";
            }
            if (!values.emeraddress1) {
              errors.emeraddress1 = " Emergency Address is required";
            }
            if (!values.emeraddress2) {
              errors.emeraddress2 = " Emergency Address is required";
            }
            if (!values.emeraddress3) {
              errors.emeraddress3 = " Emergency Address is required";
            }
            if (!values.emercity) {
              errors.emercity = " Emergency City is required";
            }
            if (!values.emerstate) {
              errors.emerstate = " Emergency State is required";
            }
            if (!values.emercountry) {
              errors.emercountry = " Emergency Country is required";
            }
            if (!values.emerpincode) {
              errors.emerpincode = " Emergency Pincode is required";
            }
            if (!values.emeremail) {
              errors.emeremail = " Emergency Email is required";
            }
            if (!values.emermobile) {
              errors.emermobile = " Emergency Mobile is required";
            }
            if (!values.emerphone2) {
              errors.emerphone2 = " Emergency Phone  is required";
            }
            if (!values.emerphone1) {
              errors.emerphone1 = " Emergency phone is required";
            }
            if (!values.emerfax) {
              errors.emerfax = " Emergency Fax is required";
            }
            if (!values.emertext) {
              errors.emertext = " Emergency Ext is required";
            }
            if (!values.permanentaccountnumber) {
              errors.permanentaccountnumber =
                "Permanent Account Number is required";
            }
            if (!values.nameasperpan) {
              errors.nameasperpan = "Name as per pan  is required";
            }
            if (!values.accountnumber) {
              errors.accountnumber = "Account Number  is required";
            }
            if (!values.empolyeedocumenttype) {
              errors.empolyeedocumenttype = "Documenet Typr is required";
            }
            if (!values.empolyeebankname) {
              errors.empolyeebankname = "Bank Name is required";
            }
            if (!values.nameinbankaccount) {
              errors.nameinbankaccount = "Name in Bank Account is required";
            }
            if (!values.bankifsc) {
              errors.bankifsc = "IFSC is required";
            }
            if (!values.aadharCard) {
              errors.aadharCard = "Aadhar Card is required";
            }
            if (!values.panCard) {
              errors.panCard = "Pan Card is required";
            }
            if (!values.passBook) {
              errors.passBook = "Pass Book is required";
            }
            if (!values.relievingLetter) {
              errors.relievingLetter = "Relieving Letter is required";
            }
            if (!values.paySlip) {
              errors.paySlip = "Pay Slip is required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            const userData = new FormData();

            if (values.image) {
              userData.append("profileImage", values.image);
            }
            userData.append("name", values.empl_name);
            userData.append("designation", values.designation);
            userData.append("user_type", values.roles);
            userData.append("title", values.title);
            userData.append("gender", values.gender);
            userData.append("maritalStatus", values.marital);
            userData.append("nationality", values.nationality);
            userData.append("residentialStatus", values.residentialstatus);
            userData.append("placeOfBirth", values.Place_birth);
            userData.append("religion", values.religion);
            userData.append("attendanceScheme", values.attendanceScheme);
            userData.append("reportingTo", values.reportingto);
            userData.append("phone", values.mobilenumber);
            userData.append("nickName", values.nick_name);
            userData.append("dob", values.birth);
            userData.append("marriageDate", values.marriage_date);
            userData.append("spouseName", values.spouse_name);
            userData.append("presentCity", values.presentcity);
            userData.append("presentState", values.presentstate);
            userData.append("presentCountry", values.presentcountry);
            userData.append("presentPincode", values.presentpincode);
            userData.append("aadharNumber", values.aadharnumber);
            userData.append("nameAsPerAadhar", values.nameasperaadhar);
            userData.append("bankBranch", values.bankbranch);
            userData.append("accountNumber", values.bankaccountnumber);
            userData.append("ifscCode", values.iFSC);
            userData.append("bankName", values.bankname);
            userData.append(
              "verifcationCompletedOn",
              values.verificationcompleted
            );
            userData.append("esiNumber", values.esinumber);
            userData.append("uanNumber", values.uan);
            userData.append("familyPFNumber", values.family_pf_number);
            userData.append("documentType", values.documenttype);
            userData.append("pfJoinDate", values.pf_join);
            userData.append("assignedTo", values.assignedto);
            userData.append("loginUserName", values.loginusername);
            userData.append("email", values.employee_email);
            userData.append("bloodGroup", values.bloodgroup);
            userData.append("fatherName", values.empl_father);
            userData.append("joiningOn", values.joining_on);
            userData.append("confirmationDate", values.confirmation_date);
            userData.append("confirmed", values.confirmed);
            userData.append("probationPeriod", values.probation_period);
            userData.append("noticePeriod", values.notice_period);
            userData.append("currentCompanyExperience", values.experience);
            userData.append("previousExperience", values.previous_experience);
            userData.append("referredBy", values.referredby);
            userData.append("costCenter", values.costcenter);
            userData.append("location", values.location);
            userData.append("company1", values.company1);
            // userData.append("company2", values.company2);
            userData.append("totalExperience", values.totalExperience);
            userData.append("shift", values.shift);
            userData.append("staff", values.staff);
            userData.append("storeName", values.storename);
            userData.append("faceRecognition", values.facerecognition);
            userData.append("presentAddress1", values.presentaddress1);
            userData.append("presentAddress2", values.presentaddress2);
            userData.append("presentAddress3", values.presentaddress3);
            userData.append("presentPhone1", values.Phone1);
            userData.append("presentPhone2", values.phone2);
            userData.append("presentMobile", values.presentmobile);
            userData.append("presentExt", values.presentext);
            userData.append("presentEmail", values.presentemail);
            userData.append("presentFax", values.presentfax);
            userData.append("permanentAddress1", values.permanentaddress1);
            userData.append("permanentAddress2", values.permanentaddress2);
            userData.append("permanentAddress3", values.permanentaddress3);
            userData.append("permanentCity", values.permanentcity);
            userData.append("permanentState", values.permanentstate);
            userData.append("permanentCountry", values.permanentcountry);
            userData.append("permanentPincode", values.permanentpincode);
            userData.append("permanentEmail", values.parmanentemail);
            userData.append("permanentPhone1", values.permanentphone1);
            userData.append("permanentPhone2", values.parmanentphone2);
            userData.append("permanentExt", values.parmanentext);
            userData.append("parmanentFax", values.parmanentfax);
            userData.append("permanentMobile", values.parmanentmobile);
            userData.append("verificationStatus", values.vrificationstatus);
            userData.append("agencyName", values.agency_name);
            userData.append("iban_swift_bic_code", values.iban);
            userData.append("accountType", values.accounttype);
            userData.append("paymentType", values.paymenttype);
            userData.append("nameAsPerBank", values.bank_records);
            userData.append("pfNumber", values.pf_num);
            userData.append("ddPayableAt", values.dd_Payable);
            userData.append("employeeCoveredUnderEsi", checkedItems.underESI);
            userData.append("isExistingMemberOfEPS", checkedItems.memberofeps);
            userData.append(
              "allowEpfExcessContribution",
              checkedItems.allowEpf
            );

            userData.append("employeeCoveredUnderPf", checkedItems.underpf);
            userData.append("empIdenNameInBank", values.nameinbankaccount);
            userData.append("aadharEnrolmentNo", values.aadhaarenrolment);
            userData.append("empIdentAccountNo", values.accountnumber);
            userData.append("empIdentBankName", values.empolyeebankname);
            userData.append(
              "permanentAccountNumber",
              values.permanentaccountnumber
            );
            userData.append("empIdentIfsc", values.bankifsc);
            userData.append("pfDocumentType", values.empolyeedocumenttype);
            userData.append("nameAsPerPan", values.nameasperpan);
            userData.append("eduQualification", values.qualification);
            userData.append("eduInstituteName", values.institutename);
            userData.append("eduGrade", values.grade);
            userData.append("Nameemergency", values.nameemergency);
            userData.append("emerRelation", values.relationship);
            userData.append("emerAddress1", values.emeraddress1);
            userData.append("emerAddress2", values.emeraddress2);
            userData.append("emerAddress3", values.emeraddress3);
            userData.append("emerCity", values.emercity);
            userData.append("emerState", values.emerstate);
            userData.append("emerCountry", values.emercountry);
            userData.append("emerPincode", values.emerpincode);
            userData.append("emerPhone1", values.emerphone1);
            userData.append("emerPhone2", values.emerphone2);
            userData.append("emerExt", values.emertext);
            userData.append("emerFax", values.emerfax);
            userData.append("emerEmail", values.emeremail);
            userData.append("emerMobile", values.emermobile);
            userData.append("aadharCardDocument", values.aadharCard);
            userData.append("panCardDocument", values.panCard);
            userData.append("passBookOrCancelledCheque", values.passBook);
            userData.append("prevOrgRelivingLetter", values.relievingLetter);
            userData.append("prevOrgPayslip", values.paySlip);
            if (userId) {
              dispatch(updateUserData(userId, userData, navigate));
            }
            // setFormData({});
            setSubmitting(false);
          }}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => {
            const handleImage = (e) => {
              const { name, files } = e.target;
              if (files && files[0]) {
                if (!files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
                  // Display an error toast or message
                } else {
                  setFieldValue(name, files[0]);
                  setImagePreview(URL.createObjectURL(files[0]));
                }
              }
            };

            return (
              <>
                <div className="top_bottomEmployees">
                  <h4> Employee Detail </h4>
                </div>
                <div className="row d-flex  justify-content-start  pt-3  gap-3">
                  <div className="col-12">
                    <div
                      className="card"
                      style={{ boxShadow: "0px 1px 15px 0px #b6bacb4d" }}
                    >
                      <div className="card-body ">
                        <div className="card_flex">
                          <div className="flex_img_text">
                            <div class="upload-section">
                              <div class="upload-container">
                                <input type="file" accept="image/*" />
                                <span class="icon">
                                  <img src={Rectangle} alt="" />
                                </span>
                              </div>
                            </div>
                            <div className="text_name  pt-3">
                              <h6>Miss SARANYA R.</h6>
                              <p>MVR - Orion</p>
                              <p>CASHIER</p>
                            </div>
                          </div>
                          <div className="border_dash_right"></div>
                          <div className="detail_wrap">
                            <div className="pad_wrap">
                              <p>Phone:</p>
                              <p>+91 123456789</p>
                            </div>
                            <div className="pad_wrap">
                              <p>Email:</p>
                              <p>saranya@gmail.com</p>
                            </div>
                            <div className="pad_wrap">
                              <p>Employee Nick Name:</p>
                              <p>Sara</p>
                            </div>
                            <div className="pad_wrap">
                              <p>Gender:</p>
                              <p>Female</p>
                            </div>
                            <div className="pad_wrap">
                              <p>Report to:</p>
                              <p>RAJESH R.</p>
                            </div>
                          </div>
                          <div className="border_dash_right"></div>
                          <div className="detail_wrap">
                            <div className="pad_wrap">
                              <p>Check In:</p>
                              <p>9:30 AM</p>
                            </div>
                            <div className="pad_wrap">
                              <p>Check Out:</p>
                              <p>7:00 PM</p>
                            </div>
                            <div className="pad_wrap">
                              <p>Total Working Hours:</p>
                              <p>9:30 Hours </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 ">
                    <div
                      className="card"
                      style={{ boxShadow: "0px 1px 15px 0px #b6bacb4d" }}
                    >
                      <div className="card-body">
                        <div className="d-flex justify-content-start  align-item-start">
                          {" "}
                          <div className="tab d-flex gap-3">
                            {tabs.map((tab) => (
                              <button
                                key={tab.name}
                                className={`tab_button ${
                                  activeTab === tab.name ? "active" : ""
                                }`}
                                onClick={() => handleTabClick(tab.name)}
                              >
                                {tab.name}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  {/* <div className="top_bottomEmployee">
                <h4> Employee Detail </h4>
              </div> */}
                  {activeTab === "Employee Information" && (
                    <div className="info_btm">
                      <p>Employee Information</p>
                    </div>
                  )}
                  {activeTab === "Employee Information" && (
                    <div class="upload-section">
                      <div class="upload-container">
                        <input type="file" accept="image/*" />
                        <span class="icon">
                          <img src={camera} alt="" />
                        </span>
                      </div>
                    </div>
                  )}
                  {activeTab === "Employee Information" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col " key={field.name}>
                              <InputField
                                label="Employee Number"
                                inputType="tel"
                                inputPlaceholder="Enter Employee Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="employeenumber"
                                value={values.employeenumber}
                                onChange={handleChange}
                              />
                              {errors.employeenumber &&
                                touched.employeenumber && (
                                  <div className="error-message">
                                    {errors.employeenumber}
                                  </div>
                                )}
                            </div>
                            <div className="row_col" key={field.name}>
                              <Selector
                                label="Title"
                                options={titleoptions}
                                name="title"
                                value={values.title}
                                onChange={(e) =>
                                  setFieldValue("title", e.target.value)
                                }
                                LabelFontSize="14px"
                                SelectorFontSize="16"
                              />
                              {errors.title && touched.title && (
                                <div className="error-message">
                                  {errors.title}
                                </div>
                              )}
                            </div>
                            <div className="row_col" key={field.name}>
                              <InputField
                                label=" Employee Name"
                                inputType="text"
                                inputPlaceholder="Enter Employee Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="empl_name"
                                value={values?.empl_name}
                                onChange={handleChange}
                              />
                              {errors.empl_name && touched.empl_name && (
                                <div className="error-message">
                                  {errors.empl_name}
                                </div>
                              )}
                            </div>
                            <div className="row_col" key={field.name}>
                              <InputField
                                label="Employee Nick Name"
                                inputType="text"
                                inputPlaceholder="Enter Employee Nick Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nick_name"
                                value={values.nick_name}
                                onChange={handleChange}
                              />
                              {errors.nick_name && touched.nick_name && (
                                <div className="error-message">
                                  {errors.nick_name}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <Selector
                                label="Gender"
                                options={genderoptions}
                                name="gender"
                                value={values.gender}
                                onChange={(e) =>
                                  setFieldValue("gender", e.target.value)
                                }
                                LabelFontSize="14px"
                                SelectorFontSize="16"
                              />

                              {errors.gender && touched.gender && (
                                <div className="error-message">
                                  {errors.gender}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Employee Login Username"
                                inputType="text"
                                inputPlaceholder="Enter Employee Login Username"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="loginusername"
                                value={values.loginusername}
                                onChange={handleChange}
                              />
                              {errors.loginusername &&
                                touched.loginusername && (
                                  <div className="error-message">
                                    {errors.loginusername}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Mobile Number"
                                inputType="tel"
                                inputPlaceholder="Enter Mobile Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="mobilenumber"
                                value={values.mobilenumber}
                                onChange={handleChange}
                              />
                              {errors.mobilenumber && touched.mobilenumber && (
                                <div className="error-message">
                                  {errors.mobilenumber}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Employee Email"
                                inputType="email"
                                inputPlaceholder="Enter Employee Email"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="employee_email"
                                value={values.employee_email}
                                onChange={handleChange}
                              />
                              {errors.employee_email &&
                                touched.employee_email && (
                                  <div className="error-message">
                                    {errors.employee_email}
                                  </div>
                                )}
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Employee Information" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Date of Birth"
                                inputType="date"
                                inputPlaceholder="DD/MM/YYYY"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="birth"
                                value={
                                  values?.birth
                                    ? moment(
                                        values.birth,
                                        "DD MMMM YYYY"
                                      ).format("YYYY-MM-DD")
                                    : ""
                                }
                                onChange={(e) => {
                                  const formattedDate = moment(
                                    e.target.value,
                                    "YYYY-MM-DD"
                                  ).format("YYYY-MM-DD");
                                  setFieldValue("birth", formattedDate);
                                }}
                              />
                              {errors.birth && touched.birth && (
                                <div className="error-message">
                                  {errors.birth}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Blood Group"
                                options={options}
                                name="bloodgroup"
                                value={values.bloodgroup}
                                onChange={(e) =>
                                  setFieldValue("bloodgroup", e.target.value)
                                }
                                LabelFontSize="14px"
                                SelectorFontSize="16"
                              />
                              {errors.bloodgroup && touched.bloodgroup && (
                                <div className="error-message">
                                  {errors.bloodgroup}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Father’s Name"
                                inputType="text"
                                inputPlaceholder="Enter Father’s Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="empl_father"
                                value={values?.empl_father}
                                onChange={handleChange}
                              />
                              {errors.empl_father && touched.empl_father && (
                                <div className="error-message">
                                  {errors.empl_father}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Marital Status"
                                options={maritalStatus}
                                name="marital"
                                value={values.marital}
                                onChange={(e) =>
                                  setFieldValue("marital", e.target.value)
                                }
                                LabelFontSize="14px"
                                SelectorFontSize="16"
                              />
                              {errors.marital && touched.marital && (
                                <div className="error-message">
                                  {errors.marital}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Marriage Date"
                                inputType="date"
                                inputPlaceholder="DD/MM/YYYY"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="marriage_date"
                                value={
                                  values?.marriage_date
                                    ? moment(
                                        values.marriage_date,
                                        "DD MMMM YYYY"
                                      ).format("YYYY-MM-DD")
                                    : ""
                                }
                                onChange={(e) => {
                                  const formattedDate = moment(
                                    e.target.value,
                                    "YYYY-MM-DD"
                                  ).format("YYYY-MM-DD");
                                  setFieldValue("marriage_date", formattedDate);
                                }}
                              />
                              {errors.marriage_date &&
                                touched.marriage_date && (
                                  <div className="error-message">
                                    {errors.marriage_date}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Spouse Name"
                                inputType="text"
                                inputPlaceholder="Enter Spouse Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="spouse_name"
                                value={values.spouse_name}
                                onChange={handleChange}
                              />
                              {errors.spouse_name && touched.spouse_name && (
                                <div className="error-message">
                                  {errors.spouse_name}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Nationality"
                                options={nationalityOption}
                                name="nationality"
                                value={values.nationality}
                                onChange={(e) =>
                                  setFieldValue("nationality", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.nationality && touched.nationality && (
                                <div className="error-message">
                                  {errors.nationality}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Residential Status"
                                options={residentialstatusoptions}
                                name="residentialstatus"
                                value={values.residentialstatus}
                                onChange={(e) =>
                                  setFieldValue(
                                    "residentialstatus",
                                    e.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.residentialstatus &&
                                touched.residentialstatus && (
                                  <div className="error-message">
                                    {errors.residentialstatus}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <Selector
                                label="Place of Birth"
                                options={placeofoptions}
                                name="Place_birth"
                                value={values.Place_birth}
                                onChange={(e) =>
                                  setFieldValue("Place_birth", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                                width="100%"
                              />
                              {errors.Place_birth && touched.Place_birth && (
                                <div className="error-message">
                                  {errors.Place_birth}
                                </div>
                              )}
                            </div>
                            <div className="row_col  custom-width-50 ">
                              <Selector
                                custom-width-50
                                label="Religion"
                                options={religionoptions}
                                name="religion"
                                value={values.religion}
                                onChange={(e) =>
                                  setFieldValue("religion", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                                width="100%"
                              />
                              {errors.religion && touched.religion && (
                                <div className="error-message">
                                  {errors.religion}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Joining Details" && (
                    <div className="info_btm">
                      <p>Joining Details</p>
                    </div>
                  )}
                  {activeTab === "Joining Details" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Joining On"
                                inputType="date"
                                inputPlaceholder="Enter Joining On"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="joining_on"
                                value={
                                  values?.joining_on
                                    ? moment(
                                        values.joining_on,
                                        "DD MMMM YYYY"
                                      ).format("YYYY-MM-DD")
                                    : ""
                                }
                                onChange={(e) => {
                                  const formattedDate = moment(
                                    e.target.value,
                                    "YYYY-MM-DD"
                                  ).format("YYYY-MM-DD");
                                  setFieldValue("joining_on", formattedDate);
                                }}
                              />
                              {errors.joining_on && touched.joining_on && (
                                <div className="error-message">
                                  {errors.joining_on}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Confirmation Date"
                                inputType="date"
                                inputPlaceholder="Enter Confirmation Date"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="confirmation_date"
                                value={
                                  values?.confirmation_date
                                    ? moment(
                                        values.confirmation_date,
                                        "DD MMMM YYYY"
                                      ).format("YYYY-MM-DD")
                                    : ""
                                }
                                onChange={(e) => {
                                  const formattedDate = moment(
                                    e.target.value,
                                    "YYYY-MM-DD"
                                  ).format("YYYY-MM-DD");
                                  setFieldValue(
                                    "confirmation_date",
                                    formattedDate
                                  );
                                }}
                              />
                              {errors.confirmation_date &&
                                touched.confirmation_date && (
                                  <div className="error-message">
                                    {errors.confirmation_date}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Status"
                                inputType="text"
                                inputPlaceholder="Enter Status"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="status"
                                value={values?.status}
                                onChange={handleChange}
                              />
                              {errors.status && touched.status && (
                                <div className="error-message">
                                  {errors.status}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Confirmed"
                                inputType="text"
                                inputPlaceholder="Confirmed"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="confirmed"
                                value={values.confirmed}
                                onChange={handleChange}
                              />
                              {errors.confirmed && touched.confirmed && (
                                <div className="error-message">
                                  {errors.confirmed}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Probation Period"
                                inputType="text"
                                inputPlaceholder="Enter Probation Period"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="probation_period"
                                value={values.probation_period}
                                onChange={handleChange}
                              />
                              {errors.probation_period &&
                                touched.probation_period && (
                                  <div className="error-message">
                                    {errors.probation_period}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Notice Period"
                                inputType="text"
                                inputPlaceholder="Enter Notice Period"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="notice_period"
                                value={values.notice_period}
                                onChange={handleChange}
                              />
                              {errors.notice_period &&
                                touched.notice_period && (
                                  <div className="error-message">
                                    {errors.notice_period}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Current Company Experience"
                                inputType="tel"
                                inputPlaceholder="Enter Current Company Experience"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="experience"
                                value={values.experience}
                                onChange={handleChange}
                              />
                              {errors.experience && touched.experience && (
                                <div className="error-message">
                                  {errors.experience}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Previous Experience"
                                inputType="text"
                                inputPlaceholder="Enter Previous Experience"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="previous_experience"
                                value={values.previous_experience}
                                onChange={handleChange}
                              />
                              {errors.previous_experience &&
                                touched.previous_experience && (
                                  <div className="error-message">
                                    {errors.previous_experience}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Total Experience"
                                inputType="text"
                                inputPlaceholder="Enter Gender"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="totalExperience"
                                value={values.totalExperience}
                                onChange={handleChange}
                              />
                              {errors.totalExperience &&
                                touched.totalExperience && (
                                  <div className="error-message">
                                    {errors.totalExperience}
                                  </div>
                                )}
                            </div>
                            <div className="row_col  custom-width-50 ">
                              <Selector
                                label="Referred By"
                                options={referredbyoptions}
                                name="referredby"
                                value={values.referredby}
                                onChange={(e) =>
                                  setFieldValue("referredby", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.referredby && touched.referredby && (
                                <div className="error-message">
                                  {errors.referredby}
                                </div>
                              )}
                            </div>
                            <div className="row_col  custom-width-50 ">
                              <Selector
                                label="Assigned To"
                                options={assignedtooptions}
                                name="assignedto"
                                value={values.assignedto}
                                onChange={(e) =>
                                  setFieldValue("assignedto", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.assignedto && touched.assignedto && (
                                <div className="error-message">
                                  {errors.assignedto}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Current Position" && (
                    <div className="info_btm">
                      <p>Current Position</p>
                    </div>
                  )}
                  {activeTab === "Current Position" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <Selector
                                label="Cost Center"
                                options={costcenteroptions}
                                name="costcenter"
                                value={values.costcenter}
                                onChange={(e) =>
                                  setFieldValue("costcenter", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.costcenter && touched.costcenter && (
                                <div className="error-message">
                                  {errors.costcenter}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Designation"
                                options={designationoptions}
                                name="designation"
                                value={values.designation}
                                onChange={(e) =>
                                  setFieldValue("designation", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.designation && touched.designation && (
                                <div className="error-message">
                                  {errors.designation}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Location"
                                options={locationoptions}
                                name="location"
                                value={values.location}
                                onChange={(e) =>
                                  setFieldValue("location", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.location && touched.location && (
                                <div className="error-message">
                                  {errors.location}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Roles"
                                inputType="text"
                                inputPlaceholder="Enter Roles"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="roles"
                                value={values.roles}
                                onChange={handleChange}
                              />
                              {errors.roles && touched.roles && (
                                <div className="error-message">
                                  {errors.roles}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <Selector
                                label="Company"
                                options={optionscompany}
                                name="company1"
                                value={values.company1}
                                onChange={(e) =>
                                  setFieldValue("company1", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.company1 && touched.company1 && (
                                <div className="error-message">
                                  {errors.company1}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Store Name "
                                options={storenameoptions}
                                name="storename"
                                value={values.storename}
                                onChange={(e) =>
                                  setFieldValue("storename", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.storename && touched.storename && (
                                <div className="error-message">
                                  {errors.storename}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Attendance Scheme"
                                options={attendanceSchemeoptions}
                                name="attendanceScheme"
                                value={values.attendanceScheme}
                                onChange={(e) =>
                                  setFieldValue(
                                    "attendanceScheme",
                                    e.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.attendanceScheme &&
                                touched.attendanceScheme && (
                                  <div className="error-message">
                                    {errors.attendanceScheme}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Reporting To"
                                options={reportingtooptions}
                                name="reportingto"
                                value={values.reportingto}
                                onChange={(e) =>
                                  setFieldValue("reportingto", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.reportingto && touched.reportingto && (
                                <div className="error-message">
                                  {errors.reportingto}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <Selector
                                label="Shift"
                                options={shiftoptions}
                                name="shift"
                                value={values.shift}
                                onChange={(e) =>
                                  setFieldValue("shift", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                                width="77%"
                              />
                              {errors.shift && touched.shift && (
                                <div className="error-message">
                                  {errors.shift}
                                </div>
                              )}
                            </div>
                            <div className="row_col custom-width-50">
                              <Selector
                                label="Staff"
                                options={staffoptions}
                                name="staff"
                                value={values.staff}
                                onChange={(e) =>
                                  setFieldValue("staff", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                                width="100%"
                              />
                              {errors.staff && touched.staff && (
                                <div className="error-message">
                                  {errors.staff}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Employee Identity" && (
                    <div className="info_btm">
                      <p>Employee Identity</p>
                    </div>
                  )}
                  {activeTab === "Employee Identity" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Aadhar Number"
                                inputType="tel"
                                inputPlaceholder="Enter Aadhar Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="aadharnumber"
                                value={values.aadharnumber}
                                onChange={handleChange}
                              />
                              {errors.aadharnumber && touched.aadharnumber && (
                                <div className="error-message">
                                  {errors.aadharnumber}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Name As Per Aadhar"
                                inputType="tel"
                                inputPlaceholder="Enter Name As Per Aadhar"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nameasperaadhar"
                                value={values.nameasperaadhar}
                                onChange={handleChange}
                              />
                              {errors.nameasperaadhar &&
                                touched.nameasperaadhar && (
                                  <div className="error-message">
                                    {errors.nameasperaadhar}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Aadhaar Enrolment No"
                                inputType="tel"
                                inputPlaceholder="Enter Aadhaar Enrolment No"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="aadhaarenrolment"
                                value={values.aadhaarenrolment}
                                onChange={handleChange}
                              />
                              {errors.aadhaarenrolment &&
                                touched.aadhaarenrolment && (
                                  <div className="error-message">
                                    {errors.aadhaarenrolment}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Permanent Account Number"
                                inputType="text"
                                inputPlaceholder="Enter Permanent Account Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="permanentaccountnumber"
                                value={values.permanentaccountnumber}
                                onChange={handleChange}
                              />
                              {errors.permanentaccountnumber &&
                                touched.permanentaccountnumber && (
                                  <div className="error-message">
                                    {errors.permanentaccountnumber}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Name As Per PAN"
                                inputType="text"
                                inputPlaceholder="Enter Name As Per PAN"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nameasperpan"
                                value={values.nameAsPerPan}
                                onChange={handleChange}
                              />
                              {errors.nameasperpan && touched.nameasperpan && (
                                <div className="error-message">
                                  {errors.nameasperpan}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Document Type"
                                options={documentType}
                                name="documenttype"
                                value={values.documenttype}
                                onChange={(e) =>
                                  setFieldValue("documenttype", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.documenttype && touched.documenttype && (
                                <div className="error-message">
                                  {errors.documenttype}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Bank Name"
                                options={attendanceSchemeoptions}
                                name="bankname"
                                value={values.bankname}
                                onChange={(e) =>
                                  setFieldValue("bankname", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.bankname && touched.bankname && (
                                <div className="error-message">
                                  {errors.bankname}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Account Number"
                                inputType="text"
                                inputPlaceholder="Enter Account Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="accountnumber"
                                value={values.accountnumber}
                                onChange={handleChange}
                              />
                              {errors.accountnumber &&
                                touched.accountnumber && (
                                  <div className="error-message">
                                    {errors.accountnumber}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col custom-width-50 ">
                              <InputField
                                label="IFSC"
                                inputType="text"
                                inputPlaceholder="Enter IFSC"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="bankifsc"
                                value={values.bankifsc}
                                onChange={handleChange}
                              />
                              {errors.bankifsc && touched.bankifsc && (
                                <div className="error-message">
                                  {errors.bankifsc}
                                </div>
                              )}
                            </div>
                            <div className="row_col custom-width-50 ">
                              <InputField
                                label="Name In Bank Account"
                                inputType="text"
                                inputPlaceholder="Enter Name In Bank Account"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nameinbankaccount"
                                value={values.nameinbankaccount}
                                onChange={handleChange}
                              />
                              {errors.nameinbankaccount &&
                                touched.nameinbankaccount && (
                                  <div className="error-message">
                                    {errors.nameinbankaccount}
                                  </div>
                                )}
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Education" && (
                    <div className="info_btm">
                      <p>Education</p>
                    </div>
                  )}{" "}
                  {activeTab === "Education" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <Selector
                                label="Qualification"
                                options={qualificationoptions}
                                name="qualification"
                                value={values.qualification}
                                onChange={(e) =>
                                  setFieldValue("qualification", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.qualification &&
                                touched.qualification && (
                                  <div className="error-message">
                                    {errors.qualification}
                                  </div>
                                )}
                            </div>

                            <div className="row_col custom-width-50">
                              <InputField
                                label="Institute Name"
                                inputType="text"
                                inputPlaceholder="Enter Institute Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="institutename"
                                value={values.institutename}
                                onChange={handleChange}
                              />
                              {errors.institutename &&
                                touched.institutename && (
                                  <div className="error-message">
                                    {errors.institutename}
                                  </div>
                                )}
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Grade"
                                inputType="text"
                                inputPlaceholder="Enter Grade"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="grade"
                                value={values.grade}
                                onChange={handleChange}
                              />
                              {errors.grade && touched.grade && (
                                <div className="error-message">
                                  {errors.grade}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Address" && (
                    <div className="info_btm">
                      <p>Present Address</p>
                    </div>
                  )}{" "}
                  {activeTab === "Address" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 1"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentaddress1"
                                value={values.presentaddress1}
                                onChange={handleChange}
                              />
                              {errors.presentaddress1 &&
                                touched.presentaddress1 && (
                                  <div className="error-message">
                                    {errors.presentaddress1}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 2"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentaddress2"
                                value={values.presentaddress2}
                                onChange={handleChange}
                              />
                              {errors.presentaddress2 &&
                                touched.presentaddress2 && (
                                  <div className="error-message">
                                    {errors.presentaddress2}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 3"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentaddress3"
                                value={values.presentaddress3}
                                onChange={handleChange}
                              />
                              {errors.presentaddress3 &&
                                touched.presentaddress3 && (
                                  <div className="error-message">
                                    {errors.presentaddress3}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <Selector
                                label="City"
                                options={presentcityoptions}
                                name="presentcity"
                                value={values.presentcity}
                                onChange={(e) =>
                                  setFieldValue("presentcity", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.presentcity && touched.presentcity && (
                                <div className="error-message">
                                  {errors.presentcity}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="State"
                                options={presentstateoptions}
                                name="presentstate"
                                value={values.presentstate}
                                onChange={(e) =>
                                  setFieldValue("presentstate", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.presentstate && touched.presentstate && (
                                <div className="error-message">
                                  {errors.presentstate}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Country"
                                options={presentcountryoptions}
                                name="presentcountry"
                                value={values.presentcountry}
                                onChange={(e) =>
                                  setFieldValue(
                                    "presentcountry",
                                    e.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.presentcountry &&
                                touched.presentcountry && (
                                  <div className="error-message">
                                    {errors.presentcountry}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label=" Pincode"
                                inputType="tel"
                                inputPlaceholder="Enter Pincode"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentpincode"
                                value={values.presentpincode}
                                onChange={handleChange}
                              />
                              {errors.presentpincode &&
                                touched.presentpincode && (
                                  <div className="error-message">
                                    {errors.presentpincode}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label=" Phone 1"
                                inputType="tel"
                                inputPlaceholder="Enter Phone 1"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="Phone1"
                                value={values.Phone1}
                                onChange={handleChange}
                              />
                              {errors.Phone1 && touched.Phone1 && (
                                <div className="error-message">
                                  {errors.Phone1}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Phone 2"
                                inputType="tel"
                                inputPlaceholder="Enter Phone 2"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="phone2"
                                value={values.phone2}
                                onChange={handleChange}
                              />
                              {errors.phone2 && touched.phone2 && (
                                <div className="error-message">
                                  {errors.phone2}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Ext"
                                inputType="text"
                                inputPlaceholder="Enter Ext"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentext"
                                value={values.presentext}
                                onChange={handleChange}
                              />
                              {errors.presentext && touched.presentext && (
                                <div className="error-message">
                                  {errors.presentext}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Fax"
                                inputType="text"
                                inputPlaceholder=" Fax"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentfax"
                                value={values.presentfax}
                                onChange={handleChange}
                              />
                              {errors.presentfax && touched.presentfax && (
                                <div className="error-message">
                                  {errors.presentfax}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Mobile"
                                inputType="tel"
                                inputPlaceholder="Enter Mobile"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentmobile"
                                value={values.presentmobile}
                                onChange={handleChange}
                              />
                              {errors.presentmobile &&
                                touched.presentmobile && (
                                  <div className="error-message">
                                    {errors.presentmobile}
                                  </div>
                                )}
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Email"
                                inputType="text"
                                inputPlaceholder="Enter Email"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentemail"
                                value={values.presentemail}
                                onChange={handleChange}
                              />
                              {errors.presentemail && touched.presentemail && (
                                <div className="error-message">
                                  {errors.presentemail}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Address" && (
                    <div className="info_btm">
                      <p>Permanent Address</p>
                    </div>
                  )}
                  {activeTab === "Address" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 1"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="permanentaddress1"
                                value={values.permanentaddress1}
                                onChange={handleChange}
                              />
                              {errors.permanentaddress1 &&
                                touched.permanentaddress1 && (
                                  <div className="error-message">
                                    {errors.permanentaddress1}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 2"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="permanentaddress2"
                                value={values.permanentaddress2}
                                onChange={handleChange}
                              />
                              {errors.permanentaddress2 &&
                                touched.permanentaddress2 && (
                                  <div className="error-message">
                                    {errors.permanentaddress2}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 3"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="permanentaddress3"
                                value={values.permanentaddress3}
                                onChange={handleChange}
                              />
                              {errors.permanentaddress3 &&
                                touched.permanentaddress3 && (
                                  <div className="error-message">
                                    {errors.permanentaddress3}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <Selector
                                label="City"
                                options={permanentcityoptions}
                                name="permanentcity"
                                value={values.permanentcity}
                                onChange={(e) =>
                                  setFieldValue("permanentcity", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.permanentcity &&
                                touched.permanentcity && (
                                  <div className="error-message">
                                    {errors.permanentcity}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="State"
                                options={permanentstateoptions}
                                name="permanentstate"
                                value={values.permanentstate}
                                onChange={(e) =>
                                  setFieldValue(
                                    "permanentstate",
                                    e.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.permanentstate &&
                                touched.permanentstate && (
                                  <div className="error-message">
                                    {errors.permanentstate}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Country"
                                options={permanentcountryoptions}
                                name="permanentcountry"
                                value={values.permanentcountry}
                                onChange={(e) =>
                                  setFieldValue(
                                    "permanentcountry",
                                    e.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.permanentcountry &&
                                touched.permanentcountry && (
                                  <div className="error-message">
                                    {errors.permanentcountry}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label=" Pincode"
                                inputType="tel"
                                inputPlaceholder="Enter Pincode"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="permanentpincode"
                                value={values.permanentpincode}
                                onChange={handleChange}
                              />
                              {errors.permanentpincode &&
                                touched.permanentpincode && (
                                  <div className="error-message">
                                    {errors.permanentpincode}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label=" Phone 1"
                                inputType="tel"
                                inputPlaceholder="Enter Phone 1"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="permanentphone1"
                                value={values.permanentphone1}
                                onChange={handleChange}
                              />
                              {errors.permanentphone1 &&
                                touched.permanentphone1 && (
                                  <div className="error-message">
                                    {errors.permanentphone1}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Phone 2"
                                inputType="tel"
                                inputPlaceholder="Enter Phone 2"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="parmanentphone2"
                                value={values.parmanentphone2}
                                onChange={handleChange}
                              />
                              {errors.parmanentphone2 &&
                                touched.parmanentphone2 && (
                                  <div className="error-message">
                                    {errors.parmanentphone2}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Ext"
                                inputType="text"
                                inputPlaceholder="Enter Ext"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="parmanentext"
                                value={values.parmanentext}
                                onChange={handleChange}
                              />
                              {errors.parmanentext && touched.parmanentext && (
                                <div className="error-message">
                                  {errors.parmanentext}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Fax"
                                inputType="tel"
                                inputPlaceholder=" Fax"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="parmanentfax"
                                value={values.parmanentfax}
                                onChange={handleChange}
                              />
                              {errors.parmanentfax && touched.parmanentfax && (
                                <div className="error-message">
                                  {errors.parmanentfax}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Mobile"
                                inputType="tel"
                                inputPlaceholder="Enter Mobile"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="parmanentmobile"
                                value={values.parmanentmobile}
                                onChange={handleChange}
                              />
                              {errors.parmanentmobile &&
                                touched.parmanentmobile && (
                                  <div className="error-message">
                                    {errors.parmanentmobile}
                                  </div>
                                )}
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Email"
                                inputType="text"
                                inputPlaceholder="Enter Email"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="parmanentemail"
                                value={values.parmanentemail}
                                onChange={handleChange}
                              />
                              {errors.parmanentemail &&
                                touched.parmanentemail && (
                                  <div className="error-message">
                                    {errors.parmanentemail}
                                  </div>
                                )}
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Emergency Contact" && (
                    <div className="info_btm">
                      <p>Emergency Contact</p>
                    </div>
                  )}
                  {activeTab === "Emergency Contact" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Name"
                                inputType="text"
                                inputPlaceholder="Enter Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nameemergency"
                                value={values.nameemergency}
                                onChange={handleChange}
                              />
                              {errors.nameemergency &&
                                touched.nameemergency && (
                                  <div className="error-message">
                                    {errors.nameemergency}
                                  </div>
                                )}
                            </div>
                            <div className="row_col custom-width-50">
                              <Selector
                                label="Relationship"
                                options={relationshipoptions}
                                name="relationship"
                                value={values.relationship}
                                onChange={(e) =>
                                  setFieldValue("relationship", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.relationship && touched.relationship && (
                                <div className="error-message">
                                  {errors.relationship}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 1"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="permanentaddress1"
                                value={values.permanentaddress1}
                                onChange={handleChange}
                              />
                              {errors.permanentaddress1 &&
                                touched.permanentaddress1 && (
                                  <div className="error-message">
                                    {errors.permanentaddress1}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 2"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="permanentaddress2"
                                value={values.permanentaddress2}
                                onChange={handleChange}
                              />
                              {errors.permanentaddress2 &&
                                touched.permanentaddress2 && (
                                  <div className="error-message">
                                    {errors.permanentaddress2}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 3"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="permanentaddress3"
                                value={values.permanentaddress3}
                                onChange={handleChange}
                              />
                              {errors.permanentaddress3 &&
                                touched.permanentaddress3 && (
                                  <div className="error-message">
                                    {errors.permanentaddress3}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <Selector
                                label="City"
                                options={permanentcityoptions}
                                name="permanentcity"
                                value={values.permanentcity}
                                onChange={(e) =>
                                  setFieldValue("permanentcity", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.permanentcity &&
                                touched.permanentcity && (
                                  <div className="error-message">
                                    {errors.permanentcity}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="State"
                                options={permanentstateoptions}
                                name="permanentstate"
                                value={values.permanentstate}
                                onChange={(e) =>
                                  setFieldValue(
                                    "permanentstate",
                                    e.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.permanentstate &&
                                touched.permanentstate && (
                                  <div className="error-message">
                                    {errors.permanentstate}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Country"
                                options={permanentcountryoptions}
                                name="permanentcountry"
                                value={values.permanentcountry}
                                onChange={(e) =>
                                  setFieldValue(
                                    "permanentcountry",
                                    e.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.permanentcountry &&
                                touched.permanentcountry && (
                                  <div className="error-message">
                                    {errors.permanentcountry}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label=" Pincode"
                                inputType="tel"
                                inputPlaceholder="Enter Pincode"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="permanentpincode"
                                value={values.permanentpincode}
                                onChange={handleChange}
                              />
                              {errors.permanentpincode &&
                                touched.permanentpincode && (
                                  <div className="error-message">
                                    {errors.permanentpincode}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label=" Phone 1"
                                inputType="tel"
                                inputPlaceholder="Enter Phone 1"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="permanentphone1"
                                value={values.permanentphone1}
                                onChange={handleChange}
                              />
                              {errors.permanentphone1 &&
                                touched.permanentphone1 && (
                                  <div className="error-message">
                                    {errors.permanentphone1}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Phone 2"
                                inputType="tel"
                                inputPlaceholder="Enter Phone 2"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="parmanentphone2"
                                value={values.parmanentphone2}
                                onChange={handleChange}
                              />
                              {errors.parmanentphone2 &&
                                touched.parmanentphone2 && (
                                  <div className="error-message">
                                    {errors.parmanentphone2}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Ext"
                                inputType="text"
                                inputPlaceholder="Enter Ext"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="parmanentext"
                                value={values.parmanentext}
                                onChange={handleChange}
                              />
                              {errors.parmanentext && touched.parmanentext && (
                                <div className="error-message">
                                  {errors.parmanentext}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Fax"
                                inputType="tel"
                                inputPlaceholder=" Fax"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="parmanentfax"
                                value={values.parmanentfax}
                                onChange={handleChange}
                              />
                              {errors.parmanentfax && touched.parmanentfax && (
                                <div className="error-message">
                                  {errors.parmanentfax}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Mobile"
                                inputType="tel"
                                inputPlaceholder="Enter Mobile"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="parmanentmobile"
                                value={values.parmanentmobile}
                                onChange={handleChange}
                              />
                              {errors.parmanentmobile &&
                                touched.parmanentmobile && (
                                  <div className="error-message">
                                    {errors.parmanentmobile}
                                  </div>
                                )}
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Email"
                                inputType="text"
                                inputPlaceholder="Enter Email"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="parmanentemail"
                                value={values.parmanentemail}
                                onChange={handleChange}
                              />
                              {errors.parmanentemail &&
                                touched.parmanentemail && (
                                  <div className="error-message">
                                    {errors.parmanentemail}
                                  </div>
                                )}
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Emergency Contact" && (
                    <div className="info_btm">
                      <p>Background Check</p>
                    </div>
                  )}
                  {activeTab === "Emergency Contact" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <Selector
                                label="Verification Status"
                                options={vrificationstatusoptions}
                                name="vrificationstatus"
                                value={values.vrificationstatus}
                                onChange={(e) =>
                                  setFieldValue(
                                    "vrificationstatus",
                                    e.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.vrificationstatus &&
                                touched.vrificationstatus && (
                                  <div className="error-message">
                                    {errors.vrificationstatus}
                                  </div>
                                )}
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Verification Completed On"
                                inputType="date"
                                inputPlaceholder="Enter Verification Completed On"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="verificationcompleted"
                                value={values.verificationcompleted}
                                onChange={handleChange}
                              />
                              {errors.verificationcompleted &&
                                touched.verificationcompleted && (
                                  <div className="error-message">
                                    {errors.verificationcompleted}
                                  </div>
                                )}
                            </div>
                            <div className="row_col custom-width-50">
                              <Selector
                                label="Agency Name"
                                options={agency_nameoptions}
                                name="agency_name"
                                value={values.agency_name}
                                onChange={(e) =>
                                  setFieldValue("agency_name", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.agency_name && touched.agency_name && (
                                <div className="error-message">
                                  {errors.agency_name}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Bank Account" && (
                    <div className="info_btm">
                      <p>Bank Account</p>
                    </div>
                  )}
                  {activeTab === "Bank Account" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <Selector
                                label="Bank Name"
                                options={banknameoptions}
                                name="bankname"
                                value={values.bankname}
                                onChange={(e) =>
                                  setFieldValue("bankname", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.bankname && touched.bankname && (
                                <div className="error-message">
                                  {errors.bankname}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Bank Branch"
                                inputType="text"
                                inputPlaceholder="Enter Title"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="bankbranch"
                                value={values.bankbranch}
                                onChange={handleChange}
                              />
                              {errors.bankbranch && touched.bankbranch && (
                                <div className="error-message">
                                  {errors.bankbranch}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Bank Account Number"
                                inputType="text"
                                inputPlaceholder="Enter Bank Account Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="bankaccountnumber"
                                value={values?.bankaccountnumber}
                                onChange={handleChange}
                              />
                              {errors.bankaccountnumber &&
                                touched.bankaccountnumber && (
                                  <div className="error-message">
                                    {errors.bankaccountnumber}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="IFSC"
                                inputType="text"
                                inputPlaceholder="Enter IFSC"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="iFSC"
                                value={values.iFSC}
                                onChange={handleChange}
                              />
                              {errors.iFSC && touched.iFSC && (
                                <div className="error-message">
                                  {errors.iFSC}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="IBAN/ Swift Code / BIC"
                                inputType="text"
                                inputPlaceholder="Enter IBAN/ Swift Code / BIC"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="iban"
                                value={values.iban}
                                onChange={handleChange}
                              />
                              {errors.iban && touched.iban && (
                                <div className="error-message ">
                                  {errors.iban}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Account Type"
                                options={accounttypeoptions}
                                name="accounttype"
                                value={values.accounttype}
                                onChange={(e) =>
                                  setFieldValue("accounttype", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.accounttype && touched.accounttype && (
                                <div className="error-message">
                                  {errors.accounttype}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Payment Type"
                                options={paymenttypeoptions}
                                name="paymenttype"
                                value={values.paymenttype}
                                onChange={(e) =>
                                  setFieldValue("paymenttype", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.paymenttype && touched.paymenttype && (
                                <div className="error-message">
                                  {errors.paymenttype}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="DD Payable At"
                                inputType="text"
                                inputPlaceholder="Enter DD Payable At"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="dd_Payable"
                                value={values.dd_Payable}
                                onChange={handleChange}
                              />
                              {errors.dd_Payable && touched.dd_Payable && (
                                <div className="error-message">
                                  {errors.dd_Payable}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Name as Per Bank Records"
                                inputType="text"
                                inputPlaceholder="Enter Name as Per Bank Records"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="bank_records"
                                value={values.bank_records}
                                onChange={handleChange}
                              />
                              {errors.bank_records && touched.bank_records && (
                                <div className="error-message">
                                  {errors.bank_records}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "ESI Account" && (
                    <div className="info_btm">
                      <p>ESI Account</p>
                    </div>
                  )}
                  {activeTab === "ESI Account" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input ">
                            <div className="row_col custom-width-50">
                              <Checkbox
                                id="underESI"
                                label="Employee Is Covered Under PF"
                                checked={checkedItems?.underESI}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="ESI Number"
                                inputType="text"
                                inputPlaceholder="Enter ESI Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="esinumber"
                                value={values.esinumber}
                                onChange={handleChange}
                              />
                              {errors.esinumber && touched.esinumber && (
                                <div className="error-message">
                                  {errors.esinumber}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "PF Account" && (
                    <div className="info_btm">
                      <p>PF Account</p>
                    </div>
                  )}
                  {activeTab === "PF Account" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <Checkbox
                                id="underpf"
                                label="Employee Is Covered Under PF"
                                checked={checkedItems?.underpf}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="UAN"
                                inputType="text"
                                inputPlaceholder="Enter UAN"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="uan"
                                value={values.uan}
                                onChange={handleChange}
                              />
                              {errors.uan && touched.uan && (
                                <div className="error-message">
                                  {errors.uan}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="PF Number"
                                inputType="text"
                                inputPlaceholder="Enter PF Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="pf_num"
                                value={values?.pf_num}
                                onChange={handleChange}
                              />
                              {errors.pf_num && touched.pf_num && (
                                <div className="error-message">
                                  {errors.pf_num}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="PF Join Date"
                                inputType="date"
                                inputPlaceholder="Enter PF Join Date"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="pf_join"
                                value={values.pf_join}
                                onChange={handleChange}
                              />
                              {errors.pf_join && touched.pf_join && (
                                <div className="error-message">
                                  {errors.pf_join}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Family PF Number"
                                inputType="text"
                                inputPlaceholder="Enter PF Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="family_pf_number"
                                value={values.family_pf_number}
                                onChange={handleChange}
                              />
                              {errors.family_pf_number &&
                                touched.family_pf_number && (
                                  <div className="error-message">
                                    {errors.family_pf_number}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <Checkbox
                                id="memberofeps"
                                label="Is Existing Member Of EPS"
                                checked={checkedItems?.memberofeps}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            <div className="row_col">
                              <Checkbox
                                id="allowEpf"
                                label="Allow EPF Excess Contribution"
                                checked={checkedItems?.allowEpf}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Document Type"
                                options={documenttypeoptions}
                                name="documenttype"
                                value={values.documenttype}
                                onChange={(e) =>
                                  setFieldValue("documenttype", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.documenttype && touched.documenttype && (
                                <div className="error-message">
                                  {errors.documenttype}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Documents" && (
                    <div className="info_btm">
                      <p>Documents</p>{" "}
                    </div>
                  )}
                  {activeTab === "Documents" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="upload_input">
                            {documentItems.map((doc) => (
                              <div key={doc.name} className="document-item">
                                <label className="doc-title">{doc.label}</label>
                                <InputLogo
                                  name={doc.name}
                                  value={values[doc.name]?.name || ""}
                                  onChange={(file) =>
                                    setFieldValue(doc.name, file)
                                  }
                                  error={errors[doc.name]}
                                  touched={touched[doc.name]}
                                  accept=".jpeg, .jpg, .png, .pdf, .doc, .docx"
                                />
                              </div>
                            ))}
                          </div>
                        </>
                      ))}
                  <div className="flex_btn_next">
                    {activeTab !== tabs[0].name && (
                      <div className="btn_pre">
                        <button
                          type="button"
                          onClick={handlePrevious}
                          disabled={isSubmitting}
                        >
                          Previous
                        </button>
                      </div>
                    )}
                    <>
                      {activeTab === "Documents" && (
                        <div className="btn_save">
                          <button type="button" disabled={isSubmitting}>
                            Save
                          </button>
                        </div>
                      )}
                    </>
                    {activeTab !== tabs[tabs.length - 1].name && (
                      <div className="btn_left">
                        <button
                          type="button"
                          onClick={handleNext}
                          disabled={isSubmitting}
                        >
                          Next
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default EditUser;
