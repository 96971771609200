import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./reports.css";
import { getStoreList } from "../../redux/Action";
import { useState } from "react";
import ViewReports from "./ViewReports";
import Pagination from "../Pagination/Pagination";

const Reports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = localStorage.getItem("role");
  const location = useLocation();

  // Select data from API reducer
  const { allStoreList } = useSelector((state) => state.main);
  const [onRequest, setOnRequest] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pathname = location.pathname;
  console.log(pathname, "pathname");
  const pathParts = pathname.split("/");
  const userId = pathParts[pathParts.length - 1];

  // Fetch data when component mounts
  useEffect(() => {
    dispatch(getStoreList(currentPage));
  }, [dispatch, currentPage]);

  // Function to get the color based on store name
  const getTextColor = (storeName) => {
    if (storeName === "Head Office") return "#2c357d";
    if (storeName === "MVR") return "#AA0000";
    return "#252C58"; // Default color
  };

  const OpenRequest = (data) => {
    setOnRequest(true);
    console.log(data, "data");

    navigate(`/report/viewreports/${data}`);
  };
  return (
    <div className="content-wrapper">
      {!onRequest && (
        <div className="store_card">
          <div className="container-fluid view-requestr">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>Store Name</th>
                    <th>Store Address</th>
                    <th>Store Manager</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allStoreList?.data?.map((report) => (
                    <tr key={report.id}>
                      <td style={{ color: "#2c357d" }}>
                        {report.company_id?.company_name}
                      </td>
                      <td style={{ color: getTextColor(report.storeName) }}>
                        {report.name}
                      </td>
                      <td style={{ color: "#2c357d" }}>{report.address}</td>
                      <td style={{ color: "#2c357d" }}>
                        {report.store_manager?.name || "-"}
                      </td>
                      <td>
                        <button
                          className="btn-view ml-2"
                          onClick={() => OpenRequest(report._id)}
                        >
                          View
                        </button>
                        <button
                          className="btn-view ml-2"
                          onClick={() => OpenRequest(report._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                listData={allStoreList?.meta}
                setPageData={setCurrentPage}
              />
            </div>
          </div>
        </div>
      )}
      {onRequest && <ViewReports />}
    </div>
  );
};

export default Reports;
