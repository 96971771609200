import React, { useState } from "react";
import RightCustomModal from "../../Common/RightModal";
import AddDesgination from "../AddNewDesgination/AddDesgination";

const Designation = () => {
  const [openInviteMember, setOpenInviteMember] = useState(false);

  const handleClose = () => {
    setOpenInviteMember(false);
  };

  // Sample designation data
  const designationData = [
    {
      designationName: "Software Engineer",
      reportingDesignation: "Manager",
    },
    // Add more designations as needed
  ];

  const fields = [
    { label: "Designation Name", id: "designationName" },
    { label: "Reporting Designation", id: "reportingDesignation" },
  ];

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 pt-4">
          <div className="text_btn">
            <div className="company_name">
              <h1>Designation</h1>
            </div>
            <div className="btn_add">
              <button onClick={() => setOpenInviteMember(true)}>
                Add Designation
              </button>
            </div>
          </div>

          <div className="comp-table">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    {fields.map((field) => (
                      <th
                        key={field.id}
                        style={
                          field.id === "designationName"
                            ? { borderLeft: "none" }
                            : {}
                        }
                      >
                        {field.label}
                      </th>
                    ))}
                    <th style={{ width: "18%", borderRight: "none" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {designationData.map((designation, index) => (
                    <tr key={index}>
                      {fields.map((field) => (
                        <td key={field.id}>{designation[field.id]}</td>
                      ))}
                      <td>
                        <span>
                          <button>Save</button>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {openInviteMember && (
        <RightCustomModal
          isOpen={openInviteMember}
          onClose={handleClose}
          shouldCloseOnOutsideClick={true}
        >
          <AddDesgination />
        </RightCustomModal>
      )}
    </>
  );
};

export default Designation;
