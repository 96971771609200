import React, { useState, useEffect } from "react";
import req_user from "../../../assets/img/req_user.svg";
import cross from "../../../assets/img/cross.png";
import watch_user from "../../../assets/img/watch_user.svg";
import ViewJob from "./ViewJob";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getjobList } from "../../../redux/Action";
import Pagination from "../../../Common/Pagination/Pagination";

const Jobs = () => {
  const [onRequest, setOnRequest] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allJobList } = useSelector((state) => state?.main);

  useEffect(() => {
    dispatch(getjobList(currentPage, limit));
  }, [dispatch, currentPage, limit]);

  useEffect(() => {
    const totalJobs = allJobList?.data?.totalJobs || 0;
    const vacancies = allJobList?.data?.vacancies || 0;
    const filledPositions = allJobList?.data?.filledJobs || 0;

    const attendanceData = [
      {
        status: "Total Jobs",
        count: totalJobs,
        imgSrc: <img src={req_user} alt="Total Jobs" />,
      },
      {
        status: "Vacancies",
        count: vacancies,
        imgSrc: <img src={cross} alt="Vacancies" />,
      },
      {
        status: "Position Filled",
        count: filledPositions,
        imgSrc: <img src={watch_user} alt="Position Filled" />,
      },
    ];
    setAttendanceData(attendanceData);
  }, [allJobList]);

  const OpenRequest = (jobId) => {
    setOnRequest(true);
    navigate(`/job/viewjob/${jobId}`);
  };

  return (
    <div className="content-wrapper">
      {!onRequest && (
        <>
          <div className="container-fluid flex-grow-1 pt-4">
            <div className="d-flex align-item-center justify-content-center gap-3">
              {attendanceData.map((record, index) => (
                <div key={index} className="cardmorningd">
                  <div className="totalnumber_card">
                    <div className="left_conetent">
                      <div className="view-iocn">{record.imgSrc}</div>
                    </div>
                    <div className="right_req">
                      <h4>{record.status}</h4>
                      <div className="min_flex_req">
                        <h1>{record.count}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="container-fluid view-requestb">
              <div className="table-responsive">
                <table className="table table-bordered align-middle">
                  <thead>
                    <tr>
                      <th>Job Type</th>
                      <th>Job Location</th>
                      <th>Experienced</th>
                      <th>Job Role</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allJobList?.data?.jobs?.map((job, index) => (
                      <tr key={index}>
                        <td>{job.title}</td>
                        <td>{job.store?.address}</td>
                        <td>{job.experience}</td>
                        <td>{job.role}</td>
                        <td>
                          <span
                            className={`badge badge-${job.status?.toLowerCase()}`}
                          >
                            {job.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className="btn-view"
                            onClick={() => OpenRequest(job._id)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  listData={allJobList?.meta}
                  setPageData={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {onRequest && <ViewJob />}
    </div>
  );
};

export default Jobs;
