import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import s_logo from "../../../assets/img/s_logo.svg";
import { getjobdetailList } from "../../../redux/Action";
import "./jobs.css";
import SelectorReffered from "../../../components/common/selectorreffered/SelectorReffered";
import Selector from "../../../components/common/selects/Selector";

const ViewJob = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { allJobDetailList } = useSelector((state) => state?.main);
  const [selectedValue, setSelectedValue] = useState(null);

  // Define the options array
  const options = [
    { value: "1", label: "Richal", username: "@Achal", category: "referred" },
    { value: "2", label: "Anil", username: "@Anuj", category: "referred" },
    { value: "3", label: "Richard", category: "other" },
    { value: "4", label: "Alok", category: "other" },
  ];

  // Handle the change event from SelectorReffered
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const pathname = location.pathname;
  const pathParts = pathname.split("/");
  const userId = pathParts[pathParts.length - 1];

  useEffect(() => {
    dispatch(getjobdetailList(userId));
  }, [dispatch, userId]);

  return (
    <div>
      <div className="content-wrapper">
        <div className="wrap">
          <div className="container-fluid flex-grow-1 pt-4">
            <div className="logo_text">
              <div className="logo_icon">
                <img src={s_logo} alt="" />
              </div>
              <div className="des_text">
                <h5>{allJobDetailList?.data?.jobDetails?.title}</h5>
              </div>
            </div>
            <div className="para_lorem">
              <div className="head_view">
                <h6>Job Overview</h6>
              </div>
              <div className="lorem_text">
                <p>{allJobDetailList?.data?.jobDetails?.bio}</p>
              </div>
            </div>
            <div className="skill_btn">
              <div className="skill_text">
                <h4>Skills</h4>
              </div>
              <div className="ocup_btn">
                {Array.isArray(allJobDetailList?.data?.jobDetails?.skillSet) ? (
                  allJobDetailList.data?.jobDetails?.skillSet?.map(
                    (skill, index) => <span key={index}>{skill}</span>
                  )
                ) : (
                  <span>{allJobDetailList?.data?.skillSet}</span>
                )}
              </div>
            </div>
            <div className="canditate_selector w-25">
              {/* Uncomment and use Selector if needed */}
              {/* <Selector
                label="Top Candidate "
                name="my-selector"
                LabelFontSize="16px"
                SelectorFontSize="14px"
                labelColor="#2C357D"
                showStar={false}
              /> */}
              <SelectorReffered
                label="Top Candidate"
                name="topCandidate"
                options={options}
                value={selectedValue}
                onChange={handleChange}
                LabelFontSize="16px"
                SelectorFontSize="14px"
                labelColor="#2C357D"
                showStar={false}
              />
            </div>
            <div className="submit_button">
              <button>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewJob;
