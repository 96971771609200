import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "./delete.css";
import deletetrash from "../../assets/img/deletetrash.svg";
const Delete = ({ open, onClose, onConfirm }) => {
  return (
    <div>
      {" "}
      <Modal
        open={open}
        onClose={onClose}
        center
        classNames={{
          modal: "delete_confirm_popup",
        }}
      >
        <div className="header_bar">
          <div className="svg_trash">
            <img src={deletetrash} alt="" />
          </div>
        </div>
        <div className="body_sec">
          <h4>Are You Sure?</h4>
          <p>This item will be permanently deleted?</p>
        </div>
        <div className="btn_sec">
          <div className="del_btn">
            <button onClick={onConfirm}>Delete</button>
          </div>
          <div className="cancel_btn">
            <button onClick={onClose}>Cancel</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Delete;
