import React from "react";

const Payrolls = () => {
  return (
    <div>
      <div className="container-fluid flex-grow-1 pt-4">comming soon</div>
    </div>
  );
};

export default Payrolls;
