import React from "react";
import styles from "./SpinnerLoader.module.scss";
import { useSelector } from "react-redux";
const SpinnerLoader = () => {
  // const isLoading = useSelector((state) => state.loading);

  return (
    <>
      {/* {isLoading && ( */}
      <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default SpinnerLoader;
