export const SET_LOADING = "SET_LOADING";
export const SET_LOGIN = "SET_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_BTN_LOADING = "SET_BTN_LOADING";
export const SET_USERLIST = "SET_USERLIST";
export const SET_ADD_USERDATA = "SET_ADD_USERDATA";
export const SET_DETELE_USER = "SET_DETELE_USER";
export const SET_FORGET_PASSWORD = "SET_FORGET_PASSWORD";
export const SET_VERIFICATION = "SET_VERIFICATION";
export const SET_NEWPASSWORD = "SET_NEWPASSWORD";
export const SET_LOCALSTRORAGE_LOGIN = "SET_LOCALSTRORAGE_LOGIN";
export const SET_REFRESHER_LOGIN = "SET_REFRESHER_LOGIN";
export const SET_NEWPASSWORDUSER = "SET_NEWPASSWORDUSER";
export const SET_LEAVEDATA = "SET_LEAVEDATA";
export const SET_GRIEVANCEDATA = "SET_GRIEVANCEDATA";
export const SET_VIEW_USERDATA = "SET_VIEW_USERDATA";
export const SET_UPDATE_USERDATA = "SET_UPDATE_USERDATA";
export const SET_ALLUSERLIST = "SET_ALLUSERLIST";
export const SET_LEAVEDATAREJAPP = "SET_LEAVEDATAREJAPP";
export const SET_LEAVEDELATAILSDATA = "SET_LEAVEDELATAILSDATA";
export const SET_RESIGNATIONDATA = "SET_RESIGNATIONDATA";
export const SET_GRIEVANCEDETAILSDATA = "SET_GRIEVANCEDETAILSDATA";
export const SET_GRIEVANCEREJAPP = "SET_GRIEVANCEREJAPP";
export const SET_RESIGNATIONDETAILSDATA = "SET_RESIGNATIONDETAILSDATA";
export const SET_RESIGNATIONREJAPP = "SET_RESIGNATIONREJAPP";
export const SET_POSHDATA = "SET_RESIGNATIONREJAPP";
export const SET_POSHDETAILSDATA = "SET_POSHDETAILSDATA";
export const SET_JOBLIST = "SET_JOBLIST";
export const SET_JOBDETAILS = "SET_JOBDETAILS";
export const SET_ADD_JOBDATA = "SET_ADD_JOBDATA";
export const SET_REPORTLIST = "SET_REPORTLIST";
export const SET_STORELIST = "SET_STORELIST";
export const SET_STORESINGLE = "SET_STORESINGLE";
export const SET_STORESINGLEUSER = "SET_STORESINGLEUSER";
export const SET_REPORTSINGLEUSER = "SET_REPORTSINGLEUSER";
