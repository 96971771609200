import {
  SET_ADD_USERDATA,
  SET_BTN_LOADING,
  SET_DETELE_USER,
  SET_GET_ALL_USERS,
  SET_LOADING,
  SET_LOGIN,
  SET_LOGOUT,
  SET_USERLIST,
  SET_LOCALSTRORAGE_LOGIN,
  SET_REFRESHER_LOGIN,
  SET_LEAVEDATA,
  SET_GRIEVANCEDATA,
  SET_UPDATE_USERDATA,
  SET_VIEW_USERDATA,
  SET_ALLUSERLIST,
  SET_LEAVEDATAREJAPP,
  SET_LEAVEDELATAILSDATA,
  SET_RESIGNATIONDATA,
  SET_GRIEVANCEDETAILSDATA,
  SET_RESIGNATIONDETAILSDATA,
  SET_GRIEVANCEREJAPP,
  SET_POSHDETAILSDATA,
  SET_POSHDATA,
  SET_JOBLIST,
  SET_JOBDETAILS,
  SET_ADD_JOBDATA,
  SET_REPORTLIST,
  SET_STORELIST,
  SET_STORESINGLE,
  SET_STORESINGLEUSER,
  SET_REPORTSINGLEUSER,
} from "../types";

const initialState = {
  isAuthenticated: !localStorage.getItem("token"),
  loading: false,
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN:
      return {
        ...state,
        isAuthenticated: true,
      };
    case SET_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
      };

    case SET_USERLIST:
      return {
        ...state,
        getAllUserList: action.payload,
      };
    case SET_ADD_USERDATA:
      return {
        ...state,
        addUserList: action.payload,
      };
    case SET_UPDATE_USERDATA:
      return {
        ...state,
        updateUserList: action.payload,
      };
    case SET_VIEW_USERDATA:
      return {
        ...state,
        viewUserList: action.payload,
      };
    case SET_DETELE_USER:
      return {
        ...state,
        deleteUser: action.payload,
      };
    case SET_LOCALSTRORAGE_LOGIN:
      return {
        ...state,
        addlocalstorageaccesstoken: action.payload,
      };

    case SET_REFRESHER_LOGIN:
      return {
        ...state,
        addlocalstoragerefreshtoken: action.payload,
      };
    case SET_LEAVEDATA:
      return {
        ...state,
        getLeaveList: action.payload,
      };
    case SET_GRIEVANCEDATA:
      return {
        ...state,
        getGrievanceList: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_ALLUSERLIST:
      return {
        ...state,
        allUserList: action.payload,
      };
    case SET_LEAVEDATAREJAPP:
      return {
        ...state,
        allAppRejLeaveList: action.payload,
      };
    case SET_LEAVEDELATAILSDATA:
      return {
        ...state,
        allDetailLeave: action.payload,
      };
    case SET_RESIGNATIONDATA:
      return {
        ...state,
        allResignationList: action.payload,
      };

    case SET_RESIGNATIONDETAILSDATA:
      return {
        ...state,
        allResignationDetailsList: action.payload,
      };
    case SET_GRIEVANCEDETAILSDATA:
      return {
        ...state,
        allGrievanceDetailList: action.payload,
      };
    case SET_GRIEVANCEREJAPP:
      return {
        ...state,
        allGrievanceRejApp: action.payload,
      };
    case SET_POSHDATA:
      return {
        ...state,
        allposhList: action.payload,
      };
    case SET_POSHDETAILSDATA:
      return {
        ...state,
        allPoshDetailList: action.payload,
      };
    case SET_JOBLIST:
      return {
        ...state,
        allJobList: action.payload,
      };
    case SET_JOBDETAILS:
      return {
        ...state,
        allJobDetailList: action.payload,
      };
    case SET_ADD_JOBDATA:
      return {
        ...state,
        alladdJob: action.payload,
      };
    case SET_REPORTLIST:
      return {
        ...state,
        allReportList: action.payload,
      };
    case SET_STORELIST:
      return {
        ...state,
        allStoreList: action.payload,
      };
    case SET_STORESINGLE:
      return {
        ...state,
        allStoreSingleDetail: action.payload,
      };
    case SET_STORESINGLEUSER:
      return {
        ...state,
        allStoreSingleUser: action.payload,
      };
    case SET_REPORTSINGLEUSER:
      return {
        ...state,
        allReportSingleUser: action.payload,
      };

    default:
      return state;
  }
};

export default mainReducer;
