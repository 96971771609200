import React, { useState, useEffect } from "react";
import ViewPosh from "./ViewPosh";
import { useNavigate } from "react-router-dom";
import req_user from "../../assets/img/req_user.svg";
import right_mark from "../../assets/img/right_mark.png";
import upper_mark from "../../assets/img/upper_mark.png";
import watch_user from "../../assets/img/watch_user.svg";
import cross from "../../assets/img/cross.png";
import { useDispatch, useSelector } from "react-redux";
import { getPoshdata, getPoshData } from "../../redux/Action"; // Action to fetch POSH data
import Pagination from "../Pagination/Pagination";
import moment from "moment";

const Posh = () => {
  const userRole = localStorage.getItem("role");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [onRequest, setOnRequest] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [tableData, setTableData] = useState([]);

  const { allposhList } = useSelector((state) => state.main);

  useEffect(() => {
    dispatch(getPoshdata(currentPage, limit));
  }, [dispatch, currentPage, limit]);
  console.log(allposhList, "allposhList");

  const attendanceData = [
    {
      status: "Total POSH Request",
      count: allposhList?.data?.totalPoshes || 0,
      imgSrc: <img src={req_user} alt="req_user" />,
    },
    {
      status: "Pending",
      count: allposhList?.data?.pendingPoshes || 0,
      imgSrc: <img src={cross} alt="right_mark" />,
    },
    {
      status: "Overdue",
      count: allposhList?.data?.overduePoshes,
      imgSrc: <img src={watch_user} alt="Overdue" />,
    },
    {
      status: "Resolved",
      count: allposhList?.data?.resolvedPoshes,
      imgSrc: <img src={right_mark} alt="Resolved" />,
    },
  ];

  const OpenRequest = (data) => {
    setOnRequest(true);
    console.log(data, "data");
    navigate(`/posh/viewposh/${data}`);
  };

  return (
    <div className="content-wrapper">
      {!onRequest && (
        <>
          <div className="flex-grow-1 pt-4 view-request">
            <div className="leave_wrap">
              {attendanceData
                .filter((record) => !record.type || record.type === userRole)
                .map((record, index) => (
                  <div key={index} className="cardmornings">
                    <div className="totalnumber_card">
                      <div className="left_conetent">
                        <div className="view-iocn">{record.imgSrc}</div>
                      </div>
                      <div className="right_req">
                        <h4>{record.status}</h4>
                        <div className="min_flex_req">
                          <h1>{record.count}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="container-fluid view-request">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Store Name</th>
                    <th>Manager</th>
                    <th>Witness</th>
                    <th>Witness Name</th>
                    <th>Created Date</th>
                    <th>Time</th>
                    <th>Reason</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allposhList?.data?.poshes?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.employeeName}</td>
                      <td>{item.storeName}</td>
                      <td>{item.managerName}</td>
                      <td>{item.witness ? "Yes" : "No"}</td>
                      <td>{item.witnessName}</td>
                      <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>
                      <td>{moment(item.createdAt).format("hh:mm A")}</td>
                      <td>{item.description}</td>
                      <td>
                        <span
                          className={`badge badge-${item.status.toLowerCase()}`}
                        >
                          {item.status}
                        </span>
                      </td>
                      <td>
                        <button
                          className="btn-view"
                          onClick={() => OpenRequest(item._id)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                listData={allposhList?.meta}
                setPageData={setCurrentPage}
                tableData={tableData}
              />
            </div>
          </div>
        </>
      )}

      {onRequest && <ViewPosh />}
    </div>
  );
};

export default Posh;
