import React, { useState } from "react";
import "./tableList.css";
import RightCustomModal from "../../Common/RightModal";
import AddCompany from "../AddNewCompany/AddCompany";
import pink_logo from "../../assets/img/pink_logo.png";
import black_logo from "../../assets/img/black_logo.png";

const Company = () => {
  const [openInviteMember, setOpenInviteMember] = useState(false);
  const handleClose = () => {
    setOpenInviteMember(false);
  };

  // Sample company data
  const companyData = [
    {
      companyName: "JJ Agencies",
      companyAddress: "#76 & 79, Jansons Building, 3rd Floor",
      country: "India",
      state: "Karnataka",
      timeZone: "(UTC+05:30) Asia/Kolkata",
      currency: "Indian Rupees (INR)",
      pfNo: "123xxxxxxxx",
      tanNo: "123xxxxxxxx",
      panNo: "123xxxxxxxx",
      esiNo: "123xxxxxxxx",
      linNo: "123xxxxxxxx",
      gstNo: "123xxxxxxxx",
      companyLogo: (
        <img src={black_logo} alt="Company Logo" className="company-logo" />
      ),
      signature: <img src={pink_logo} alt="Signature" className="signature" />,
    },
    // Add more rows as needed
  ];

  const fields = [
    { label: "Company Name", id: "companyName" },
    { label: "Company Address", id: "companyAddress" },
    { label: "Country", id: "country" },
    { label: "State", id: "state" },
    { label: "Time Zone", id: "timeZone" },
    { label: "Currency", id: "currency" },
    { label: "PF No.", id: "pfNo" },
    { label: "TAN No.", id: "tanNo" },
    { label: "PAN No.", id: "panNo" },
    { label: "ESI No.", id: "esiNo" },
    { label: "LIN No.", id: "linNo" },
    { label: "GST No.", id: "gstNo" },
    { label: "Company Logo", id: "companyLogo" },
    { label: "Signature", id: "signature" },
  ];

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 pt-4">
          <div className="text_btn">
            <div className="company_name">
              <h1>Company</h1>
            </div>
            <div className="btn_add">
              <button onClick={() => setOpenInviteMember(true)}>
                Add Company
              </button>
            </div>
          </div>

          <div className="comp-table">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    {fields.map((field) => (
                      <th
                        key={field.id}
                        style={
                          field.id === "companyName"
                            ? { borderLeft: "none" }
                            : {}
                        }
                      >
                        {field.label}
                      </th>
                    ))}
                    <th style={{ borderRight: "none" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {companyData.map((company, index) => (
                    <tr key={index}>
                      {fields.map((field) => (
                        <td key={field.id}>
                          {field.id === "companyLogo" ||
                          field.id === "signature"
                            ? // Display the image directly if the field is "companyLogo" or "signature"
                              company[field.id]
                            : // Display the text for other fields
                              company[field.id]}
                        </td>
                      ))}
                      <td>
                        <span>
                          <button>Save</button>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {openInviteMember && (
        <RightCustomModal
          isOpen={openInviteMember}
          onClose={handleClose}
          shouldCloseOnOutsideClick={true}
        >
          <AddCompany />
        </RightCustomModal>
      )}
    </>
  );
};

export default Company;
