import React from "react";
import timer from "../../assets/img/timer.svg";
// import attendanceView from "./attendanceView";

const AttendanceOverview = () => {
  // Define attendance data for each month
  const attendanceData = [
    {
      month: "January",
      totalDays: 31,
      present: 25,
      late: 3,
      absent: 3,
      leave: 2,
      off: 1,
      holiday: 1,
      halfDay: 2.5,
    },
    {
      month: "February",
      totalDays: 28,
      present: 24,
      late: 2,
      absent: 2,
      leave: 1,
      off: 1,
      holiday: 1,
      halfDay: 3,
    },
    {
      month: "March",
      totalDays: 31,
      present: 27,
      late: 1,
      absent: 2,
      leave: 1,
      off: 0,
      holiday: 0,
      halfDay: 1.5,
    },
    {
      month: "April",
      totalDays: 30,
      present: 26,
      late: 1,
      absent: 2,
      leave: 1,
      off: 1,
      holiday: 1,
      halfDay: 2,
    },
    {
      month: "May",
      totalDays: 31,
      present: 28,
      late: 0,
      absent: 1,
      leave: 1,
      off: 0,
      holiday: 1,
      halfDay: 1.5,
    },
    {
      month: "June",
      totalDays: 30,
      present: 27,
      late: 2,
      absent: 1,
      leave: 1,
      off: 1,
      holiday: 1,
      halfDay: 1,
    },
    {
      month: "July",
      totalDays: 31,
      present: 29,
      late: 0,
      absent: 0,
      leave: 1,
      off: 1,
      holiday: 1,
      halfDay: 0.5,
    },
    {
      month: "August",
      totalDays: 31,
      present: 26,
      late: 3,
      absent: 2,
      leave: 0,
      off: 2,
      holiday: 1,
      halfDay: 1.5,
    },
    {
      month: "September",
      totalDays: 30,
      present: 25,
      late: 2,
      absent: 3,
      leave: 1,
      off: 2,
      holiday: 0,
      halfDay: 2,
    },
    {
      month: "October",
      totalDays: 31,
      present: 28,
      late: 1,
      absent: 1,
      leave: 1,
      off: 0,
      holiday: 1,
      halfDay: 2.5,
    },
    {
      month: "November",
      totalDays: 30,
      present: 27,
      late: 1,
      absent: 2,
      leave: 1,
      off: 1,
      holiday: 1,
      halfDay: 2,
    },
    {
      month: "December",
      totalDays: 31,
      present: 26,
      late: 2,
      absent: 3,
      leave: 1,
      off: 1,
      holiday: 1,
      halfDay: 2.5,
    },
  ];

  return (
    <div className="overview container">
      <div className="row d-flex  justify-content-start">
        <div className="col-6">
          <div
            className="card"
            style={{ boxShadow: "0px 1px 15px 0px #b6bacb4d" }}
          >
            <div className="card-body ">
              <div className="d-flex justify-content-start  align-item-center">
                <p
                  style={{
                    fontSize: "20px",
                    lineHeight: "16px",
                    fontWeight: "500",
                    color: "#2C357D",
                    paddingBottom: "10",
                  }}
                >
                  Statistics
                </p>
              </div>
              <div className=" mx-auto" style={{ width: "95%" }}>
                <div
                  className="d-flex justify-content-between align-item-center pt-2 pb-2"
                  style={{ color: "#2C357D" }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontWeight: "300",
                    }}
                  >
                    Today
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontWeight: "400",
                    }}
                  >
                    3:45 / 9hrs
                  </span>
                </div>
                <div className="progress" style={{ height: "8px" }}>
                  <div
                    className="progress-bar w-50"
                    style={{ backgroundColor: "#2C357D" }}
                    role="progressbar"
                  ></div>
                </div>
                <div
                  className="d-flex justify-content-between align-item-center pt-2 pb-2"
                  style={{ color: "#2C357D" }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontWeight: "300",
                    }}
                  >
                    Week
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontWeight: "400",
                    }}
                  >
                    39 / 45hrs
                  </span>
                </div>
                <div className="progress" style={{ height: "8px" }}>
                  <div
                    className="progress-bar w-50"
                    style={{ backgroundColor: "#2C357D" }}
                    role="progressbar"
                  ></div>
                </div>
                <div
                  className="d-flex justify-content-between align-item-center pt-2 pb-2"
                  style={{ color: "#2C357D" }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontWeight: "300",
                    }}
                  >
                    Overtime Per Week
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontWeight: "400",
                    }}
                  >
                    4 days
                  </span>
                </div>
                <div className="progress" style={{ height: "8px" }}>
                  <div
                    className="progress-bar w-50"
                    style={{ backgroundColor: "#2C357D" }}
                    role="progressbar"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div
            className="card"
            style={{ boxShadow: "0px 1px 15px 0px #b6bacb4d" }}
          >
            <div className="card-body">
              <div className="d-flex justify-content-between align-item-center gap-2">
                <p
                  style={{
                    fontSize: "20px",
                    lineHeight: "16px",
                    fontWeight: "500",
                    color: "#2C357D",
                  }}
                >
                  Timesheet
                </p>
              </div>
              <div className=" mx-auto" style={{ width: "95%" }}>
                <div className="d-flex justify-content-between align-item-center">
                  <div className="d-flex flex-column gap-3 ">
                    <div className="d-flex">
                      <span
                        style={{
                          fontSize: "16px",
                          lineHeight: "16px",
                          fontWeight: "500",
                          color: "#2C357D",
                        }}
                      >
                        Punch In At:
                      </span>
                      <span
                        style={{
                          paddingLeft: "20px",
                          fontSize: "16px",
                          lineHeight: "16px",
                          fontWeight: "300",
                          color: "#2C357D",
                        }}
                      >
                        Wed, 11th Mar 2024 10.00 AM
                      </span>
                    </div>
                    <div className="d-flex">
                      <span
                        style={{
                          fontSize: "16px",
                          lineHeight: "16px",
                          fontWeight: "500",
                          color: "#2C357D",
                        }}
                      >
                        Punch In Out:
                      </span>
                      <span
                        style={{
                          paddingLeft: "12px",
                          fontSize: "16px",
                          lineHeight: "16px",
                          fontWeight: "300",
                          color: "#2C357D",
                        }}
                      >
                        Wed, 11th Mar 2024 -- -- PM
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <span>
                      <img src={timer} alt="Timer icon" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-container" style={{ padding: "15px 0" }}>
        <table className="table" style={{ textAlign: "left" }}>
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>Month</th>
              <th style={{ textAlign: "left" }}>Total Days (TD)</th>
              <th style={{ textAlign: "left" }}>Present</th>
              <th style={{ textAlign: "left" }}>Late (LT)</th>
              <th style={{ textAlign: "left" }}>Absent (A)</th>
              <th style={{ textAlign: "left" }}>Leave (L)</th>
              <th style={{ textAlign: "left" }}>Off</th>
              <th style={{ textAlign: "left" }}>Holiday (HO)</th>
              <th style={{ textAlign: "left" }}>Half Day (H/D)</th>
            </tr>
          </thead>
          <tbody>
            {attendanceData.map((data, index) => (
              <tr key={index}>
                <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                  {data.month}
                </td>
                <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                  {data.totalDays}
                </td>
                <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                  {data.present}
                </td>
                <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                  {data.late}
                </td>
                <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                  {data.absent}
                </td>
                <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                  {data.leave}
                </td>
                <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                  {data.off}
                </td>
                <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                  {data.holiday}
                </td>
                <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                  {data.halfDay}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AttendanceOverview;
