import React, { useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Sidebar from "../components/Sidebar/Sidebar";
const Layout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const toggleSidebar = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <div className="layout-wrapper layout-content-navbar ">
      <motion.div
        animate={{
          width: isMenuOpen ? "20%" : "4%",

          transition: {
            duration: 0.5,
            type: "spring",
            damping: 10,
          },
        }}
        className="sidebar"
        style={
          isMenuOpen !== null
            ? {
                borderRight: "1px solid rgba(44, 53, 125, 0.5)",
                boxShadow: "1px 0px 4px rgba(44, 53, 125, 0.2)",
                padding: "10px 0px",
              }
            : {}
        }
      >
        <Sidebar isOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      </motion.div>
      <div className="layout-container">
        <div className="layout-page">
          <Navbar funtype={toggleSidebar} />
          <main>
            <Outlet />
          </main>
        </div>
      </div>
    </div>

    // <div className="layout-wrapper layout-content-navbar">
    //   <div className="layout-container">
    //     <Sidebar />
    //     <div className="layout-page">
    //       <Navbar />
    //       <main>
    //         <Outlet />
    //       </main>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Layout;
