import React, { useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import "./rightmodal.css";

const RightCustomModal = ({
  children,
  isOpen,
  onClose,
  width,
  shouldCloseOnOutsideClick,
}) => {
  const modalRef = useRef(null); // Ref for the modal DOM element
  const modalInstanceRef = useRef(null); // Ref for the modal instance

  useEffect(() => {
    // Ensure the modal DOM element is mounted before initializing
    if (modalRef.current) {
      if (!modalInstanceRef.current) {
        modalInstanceRef.current = new Modal(modalRef.current, {
          backdrop: shouldCloseOnOutsideClick ? true : "static",
        });

        modalRef.current.addEventListener("hidden.bs.modal", () => {
          modalInstanceRef.current?.dispose();
          modalInstanceRef.current = null;
        });
      }

      // Show or hide modal based on `isOpen`
      if (isOpen && modalInstanceRef.current) {
        modalInstanceRef.current.show();
      } else if (!isOpen && modalInstanceRef.current) {
        modalInstanceRef.current.hide();
      }
    }

    return () => {
      // Clean up the modal instance when component unmounts
      if (modalInstanceRef.current) {
        modalInstanceRef.current.dispose();
        modalInstanceRef.current = null;
      }
    };
  }, [isOpen, shouldCloseOnOutsideClick]);

  return (
    <div
      className="modal w-100"
      tabIndex="-1"
      ref={modalRef}
      onClick={(e) => {
        if (
          shouldCloseOnOutsideClick &&
          e.target &&
          modalRef.current &&
          e.target === modalRef.current
        ) {
          onClose();
        }
      }}
    >
      <div className="modal-dialog">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default RightCustomModal;
