import React, { useState } from "react";
import "./addnew.css";
import { Navigate, useNavigate } from "react-router-dom";
import { VscClose } from "react-icons/vsc";

const AddCompany = () => {
  const navigate = useNavigate();
  // Define the fields dynamically
  const fields = [
    { label: "Company Name", type: "text", id: "company-name" },
    { label: "Company Address", type: "text", id: "company-address" },
    {
      label: "Country",
      type: "select",
      id: "country",
      options: ["USA", "Canada", "UK"],
    },
    {
      label: "State",
      type: "select",
      id: "state",
      options: ["California", "Texas", "New York"],
    },
    {
      label: "Time Zone",
      type: "select",
      id: "time-zone",
      options: ["PST", "EST", "CST"],
    },
    {
      label: "Currency",
      type: "select",
      id: "currency",
      options: ["USD", "CAD", "GBP"],
    },
    { label: "PF No.", type: "text", id: "pf-no" },
    { label: "Tan No.", type: "text", id: "tan-no" },
    { label: "Pan No.", type: "text", id: "pan-no" },
    { label: "ESI No.", type: "text", id: "esi-no" },
    { label: "LIN No.", type: "text", id: "lin-no" },
    { label: "GST No.", type: "text", id: "gst-no" },
    {
      label: "Registration Certification No.",
      type: "text",
      id: "registration-certification-no",
    },
    {
      label: "Company Logo",
      type: "file",
      id: "company-logo",
    },
    {
      label: "Signature",
      type: "file",
      id: "signature",
    },
  ];

  const [formData, setFormData] = useState({
    "company-name": "",
    "company-address": "",
    country: "",
    state: "",
    "time-zone": "",
    currency: "",
    "pf-no": "",
    "tan-no": "",
    "pan-no": "",
    "esi-no": "",
    "lin-no": "",
    "gst-no": "",
    "registration-certification-no": "",
    "company-logo": null,
    signature: null,
  });

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    setFormData({
      ...formData,
      [id]: files ? files[0] : value,
    });
  };

  return (
    <>
      <div className="addcompany">
        <div className="d-flex justify-content-end">
          <VscClose size={20} />
        </div>
        <h2>Add New Company</h2>
      </div>
      <div className="border-bottom"></div>
      <div
        className="d-flex justify-content-between gap-1 content_common"
        style={{ borderBottom: "2px solid #2C357D2E" }}
      >
        <div className="label_name">
          {fields.map((field) => (
            <label key={field.id} htmlFor={field.id}>
              {field.label}{" "}
              {field.label.includes("*") && (
                <span style={{ color: "red" }}>*</span>
              )}
            </label>
          ))}
        </div>
        <div className="input_group mx-auto">
          {fields.map((field) => (
            <div key={field.id}>
              {field.type === "select" ? (
                <select
                  id={field.id}
                  value={formData[field.id]}
                  onChange={handleChange}
                  className="form-group"
                >
                  <option value=""></option>
                  {field.options.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : field.type === "file" ? (
                // <div className="d-flex">
                
                // </div>
                  <input   className="form-group" type="file" id="formFile" />
              ) : (
                <input
                  className="form-group"
                  type={field.type}
                  id={field.id}
                  value={formData[field.id]}
                  onChange={handleChange}
                  placeholder={`Enter ${field.label}`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="btnblue px-4 pb-5 mt-1">
        <button>Submit</button>
      </div>
    </>
  );
};

export default AddCompany;
