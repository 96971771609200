import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import req_user from "../../assets/img/req_user.svg";
import cross from "../../assets/img/cross.png";
import watch_user from "../../assets/img/watch_user.svg";
import right_mark from "../../assets/img/right_mark.png";
import upper_mark from "../../assets/img/upper_mark.png";
import "./grievance.css";
import ViewGrievance from "./ViewGrievance";
import { useDispatch, useSelector } from "react-redux";
import { getGrievancedata } from "../../redux/Action"; // Assuming action to fetch grievance data
import Pagination from "../Pagination/Pagination";
import moment from "moment";
const Grievance = () => {
  const userRole = localStorage.getItem("role");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [onRequest, setOnRequest] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { getGrievanceList } = useSelector((state) => state?.main);

  useEffect(() => {
    const totalTicket = getGrievanceList?.data?.totalGrievances || 0;
    const pendingTicket = getGrievanceList?.data?.pendingGrievances || 0;
    const OpenTicket = getGrievanceList?.data?.openGrievances || 0;
    const SolvedTicket = getGrievanceList?.data?.solvedGrievances || 0;
    const escalatedTicket = getGrievanceList?.data?.escalatedGrievances || 0;

    const fetchedAttendanceData = [
      {
        status: "Total Ticket Request",
        count: totalTicket,
        imgSrc: <img src={req_user} alt="req_user" />,
      },
      {
        status: "Solved Tickets",
        count: SolvedTicket,
        imgSrc: <img src={cross} alt="cross" />,
      },
      {
        status: "Open Tickets",
        count: OpenTicket,
        imgSrc: <img src={watch_user} alt="watch_user" />,
      },
      {
        status: "Pending",
        count: pendingTicket,
        imgSrc: <img src={right_mark} alt="right_mark" />,
      },
      {
        status: "Escalated",
        count: escalatedTicket,
        imgSrc: <img src={upper_mark} alt="upper_mark" />,
        type: "admin",
      },
    ];

    setAttendanceData(fetchedAttendanceData);
    setTableData(getGrievanceList?.data || []);
  }, []);

  useEffect(() => {
    dispatch(getGrievancedata(currentPage, limit));
  }, [dispatch, currentPage, limit]);

  const OpenRequest = (data) => {
    setOnRequest(true);
    navigate(`/grievance/viewgrievance/${data}`);
  };

  return (
    <div className="content-wrapper">
      {/* Conditionally render content when onRequest is false */}
      {!onRequest && (
        <>
          <div className="flex-grow-1 pt-4 view-request">
            <div className="leave_wrap">
              {attendanceData
                .filter((record) => !record.type || record.type === userRole)
                .map((record, index) => (
                  <div key={index} className="cardmornings">
                    <div className="totalnumber_card">
                      <div className="left_conetent">
                        <div className="view-iocn">{record.imgSrc}</div>
                      </div>
                      <div className="right_req">
                        <h4>{record.status}</h4>
                        <div className="min_flex_req">
                          <h1>{record.count}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="container-fluid view-request">
            {/* Responsive Table */}
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead>
                  <tr>
                    <th>Ticket Id</th>
                    <th>Employee Name</th>
                    <th>Store Name</th>
                    <th>Assigned to</th>
                    <th>Created Date</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {console.log(getGrievanceList, "getGrievanceList")}
                  {getGrievanceList &&
                    getGrievanceList?.data?.grievances?.map((items, index) => (
                      <tr key={index}>
                        <td>{items.ticketNo || "-"}</td>
                        <td>{items.userId?.name || "-"}</td>
                        <td>{items.userId?.store_id?.name || "-"}</td>
                        <td>{items.userId?.managerId?.name || "-"}</td>
                        <td>
                          <td>
                            {moment(items.createdAt).format("DD MMMM YYYY")}
                          </td>
                        </td>
                        <td>{items.description}</td>

                        <td>
                          <span
                            className={`badge badge-${
                              items.status.toLowerCase() || "-"
                            }`}
                          >
                            {items.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className="btn-view"
                            onClick={() => OpenRequest(items._id)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <Pagination
                listData={getGrievanceList?.meta}
                setPageData={setCurrentPage}
                tableData={tableData}
              />
            </div>
          </div>
        </>
      )}

      {onRequest && <ViewGrievance />}
    </div>
  );
};

export default Grievance;
