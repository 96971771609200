import React, { useState } from "react";
import "./selector.css";
import dropnewicon from "../../../assets/img/dropnewicon.svg";

const Selector = ({
  label,
  options,
  name,
  value,
  onChange,
  LabelFontSize,
  SelectorFontSize,
  labelColor = "#2C357D80",
  starColor = "red",
  showStar,
  error,
  width,
  borderColor,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (optionValue) => {
    onChange({ target: { name, value: optionValue } });
    setIsOpen(false);
  };

  const labelStyle = {
    fontSize: LabelFontSize,
    color: labelColor,
  };

  const selectStyle = {
    fontSize: SelectorFontSize,
    borderBottom: `1px solid ${borderColor}`,
  };

  return (
    <div className="selector_wrapper" style={{ width: width || "100%" }}>
      <label className="selector_label" style={labelStyle}>
        {label} {showStar && <span style={{ color: starColor }}> *</span>}
      </label>
      <div
        className="custom_selector"
        style={selectStyle}
        onClick={handleToggleDropdown}
      >
        {value
          ? options.find((option) => option?.value === value)?.label
          : "Select an option"}

        <img
          src={dropnewicon}
          alt=""
          className={`dropdown_icon ${isOpen ? "open" : ""}`}
        />
      </div>
      {isOpen && (
        <div className="custom_dropdown">
          {options?.map((option) => (
            <div
              key={option.value}
              className="custom_option"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default Selector;
