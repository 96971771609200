import React from "react";
import { Link } from "react-router-dom";
import {
  Avatar_1,
  Avatar_2,
  Logo,
  Profile_Background,
} from "../../../assets/img/dashboard";
import {
  DashboardIcon,
  IconAnalytics,
  IconCamera,
  IconKiddos,
  IconParents,
  IconReports,
  IconSettings,
  IconStaff,
} from "../../../assets/img/dashboard/icons";

const Profile = () => {
  return (
    <>
      <div className="">
        <div className="">
          {/* <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
            <div className="app-brand demo">
              <Link to="/dashboard" className="app-brand-link">
                <span className="app-brand-logo demo">
                  <img src={Logo} className="img-fluid" alt="logo" />
                </span>
                <span className="app-brand-text demo menu-text fw-bolder ms-2">Vision</span>
              </Link>
              <a
                // href="javascript:void(0);"
                className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
                <i className="bx bx-chevron-left bx-sm align-middle"></i>
              </a>
            </div>
            <div className="menu-inner-shadow"></div>
            <ul className="menu-inner py-1">
              <li className="menu-item">
                <Link to="/dashboard" className="menu-link">
                  <DashboardIcon className='menu-icon tf-icons' />
                  <div data-i18n="Analytics">Dashboard</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/analytics" className="menu-link">
                  <IconAnalytics className="menu-icon tf-icons" />
                  <div data-i18n="Analytics">Analytics</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/reports" className="menu-link">
                  <IconReports className="menu-icon tf-icons" />
                  <div data-i18n="Analytics">Reports</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/kiddos" className="menu-link">
                  <IconKiddos className='menu-icon tf-icons' />
                  <div data-i18n="Analytics">Kiddos</div>
                </Link>
              </li>
              <li className="menu-item ">
                <Link to="/parents" className="menu-link">
                  <IconParents className='menu-icon tf-icons' />
                  <div data-i18n="Analytics">Parents</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/staff" className="menu-link">
                  <IconStaff className='menu-icon tf-icons' />
                  <div data-i18n="Analytics">Staff</div>
                </Link>
              </li>
              <li className="menu-header small text-uppercase">
                <span className="menu-header-text">Settings</span>
              </li>
              <li className="menu-item active">
                <Link to="/profile" className="menu-link">
              
                  <IconParents className='menu-icon tf-icons' />
                  <div data-i18n="Basic">Profile</div>
                </Link>
              </li>

              <li className="menu-item">
                <Link to="/settings" className="menu-link">
                  <IconSettings className='menu-icon tf-icons' />
                  <div data-i18n="Basic">Settings</div>
                </Link>
              </li>
            </ul>
          </aside> */}
          <div className="">
            <div className="content-wrapper justify-content-start">
              <div className="row gy-3">
                <div className="col-lg-9 col-md-12 w-100">
                  <div className="sales-dashboard ">
                    <h2>Your Profile</h2>
                  </div>
                  <div
                    className="profile-img"
                    style={{ backgroundImage: `url(${Profile_Background})` }}
                  >
                    <a href="">
                      <IconCamera className="i fa-solid fa-camera" />
                    </a>
                  </div>
                  <div className="profile-card">
                    <div className="profile-top">
                      <div className="pro-img">
                        <img src={Avatar_2} alt="" />
                        <a href="">
                          {/* <i className="fa-solid fa-camera"></i> */}
                          <IconCamera
                            className="i fa-solid fa-camera"
                            color="black"
                          />
                        </a>
                      </div>
                      <div className="d-flex gap-2">
                        <button className="btn cancel">Cancel</button>
                        <button className="save" type="submit">
                          Save
                        </button>
                      </div>
                    </div>
                    <form className="parents-modal p-0">
                      <div className="row">
                        <div className="col-lg-7 col-md-12">
                          <div className="row gy-4">
                            <div className="col-lg-12 col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Full Name</label>
                                <input
                                  type="text"
                                  id="first-name"
                                  className="form-control"
                                  name="first_name"
                                  placeholder="Full Name"
                                  required=""
                                  fdprocessedid="r7mml"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Nick Name</label>
                                <input
                                  type="text"
                                  id="last-name"
                                  className="form-control"
                                  name="last_name"
                                  placeholder="Nick Name"
                                  required=""
                                  fdprocessedid="994nt3"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input
                                  type="text"
                                  id="last-name"
                                  className="form-control"
                                  name="last_name"
                                  placeholder="Email"
                                  required=""
                                  fdprocessedid="d39m2o"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Phone</label>
                                <input
                                  type="text"
                                  id="last-name"
                                  className="form-control"
                                  name="last_name"
                                  placeholder="Phone"
                                  required=""
                                  fdprocessedid="d39m2o"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-5 col-md-12">
                          <div className="row gy-4">
                            <div className="col-lg-12 col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Bio</label>
                                <textarea
                                  className="form-control"
                                  style={{ height: "186px" }}
                                  placeholder="Bio"
                                  id="special-notes"
                                  rows="6"
                                ></textarea>
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Instagram</label>
                                <input
                                  type="text"
                                  id="last-name"
                                  className="form-control"
                                  name="last_name"
                                  placeholder="Instagram"
                                  required=""
                                  fdprocessedid="d39m2o"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Facebook</label>
                                <input
                                  type="text"
                                  id="last-name"
                                  className="form-control"
                                  name="last_name"
                                  placeholder="Facebook"
                                  required=""
                                  fdprocessedid="d39m2o"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 tab-bars ">
                  <div className="row ">
                    <div className="col-lg-12 col-md-12 mb-4 h-100  ">
                      {/* <div className="card h-100 chat-card ">
                        <div className="tab-container">
                          <div className="tab-buttons">
                            <button className="tab-button active staff  " data-tab="1" style={{ }}>Office Staff</button>
                            <button className="tab-button" data-tab="2" >Therapists</button>
                          </div>
                          <div className="tab-content">
                            <div className="tab-item active" data-tab="1">
                              <ul className="p-0 m-0">
                                <li className="d-flex align-items-center mb-6">
                                  <div className="avatar flex-shrink-0 me-3 rounded-circle">
                                    <img src={Avatar_1} className="rounded-circle" alt="User" />
                                  </div>
                                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                    <div className="me-2">
                                      <h4 className="fw-bolder mb-0">Wade Warren</h4>
                                      <small className="d-block">Australian</small>
                                    </div>
                                    <div className="user-progress d-flex align-items-center gap-2">
                                      <button className="chat-button bg-black">Message</button>
                                    </div>
                                  </div>
                                </li>
                                <li className="d-flex align-items-center mb-6">
                                  <div className="avatar flex-shrink-0 me-3 rounded-circle">
                                    <img src={Avatar_1} className="rounded-circle" alt="User" />
                                  </div>
                                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                    <div className="me-2">
                                      <h4 className="fw-bolder mb-0">Devon Lane</h4>
                                      <small className="d-block">Gabonese</small>
                                    </div>
                                    <div className="user-progress d-flex align-items-center gap-2">
                                      <button className="chat-button bg-graytwo">Follow</button>
                                    </div>
                                  </div>
                                </li>
                                <li className="d-flex align-items-center mb-6">
                                  <div className="avatar flex-shrink-0 me-3 rounded-circle">
                                    <img src={Avatar_1} className="rounded-circle" alt="User" />
                                  </div>
                                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                    <div className="me-2">
                                      <h4 className="fw-bolder mb-0">Devon Lane</h4>
                                      <small className="d-block">Gabonese</small>
                                    </div>
                                    <div className="user-progress d-flex align-items-center gap-2">
                                      <button className="chat-button bg-graytwo">Follow</button>
                                    </div>
                                  </div>
                                </li>
                                <li className="d-flex align-items-center mb-6">
                                  <div className="avatar flex-shrink-0 me-3 rounded-circle">
                                    <img src={Avatar_1} className="rounded-circle" alt="User" />
                                  </div>
                                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                    <div className="me-2">
                                      <h4 className="fw-bolder mb-0">Wade Warren</h4>
                                      <small className="d-block">Australian</small>
                                    </div>
                                    <div className="user-progress d-flex align-items-center gap-2">
                                      <button className="chat-button bg-black">Message</button>
                                    </div>
                                  </div>
                                </li>
                                <li className="d-flex align-items-center mb-6">
                                  <div className="avatar flex-shrink-0 me-3 rounded-circle">
                                    <img src={Avatar_1} className="rounded-circle" alt="User" />
                                  </div>
                                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                    <div className="me-2">
                                      <h4 className="fw-bolder mb-0">Wade Warren</h4>
                                      <small className="d-block">Australian</small>
                                    </div>
                                    <div className="user-progress d-flex align-items-center gap-2">
                                      <button className="chat-button bg-black">Message</button>
                                    </div>
                                  </div>
                                </li>
                                <li className="d-flex align-items-center mb-6">
                                  <div className="avatar flex-shrink-0 me-3 rounded-circle">
                                    <img src={Avatar_1} className="rounded-circle" alt="User" />
                                  </div>
                                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                    <div className="me-2">
                                      <h4 className="fw-bolder mb-0">Wade Warren</h4>
                                      <small className="d-block">Australian</small>
                                    </div>
                                    <div className="user-progress d-flex align-items-center gap-2">
                                      <button className="chat-button bg-black">Message</button>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="tab-item" data-tab="2">
                              <ul className="p-0 m-0">
                                <li className="d-flex align-items-center mb-6">
                                  <div className="avatar flex-shrink-0 me-3 rounded-circle">
                                    <img src={Avatar_1} className="rounded-circle" alt="User" />
                                  </div>
                                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                    <div className="me-2">
                                      <h4 className="fw-bolder mb-0">Wade Warren</h4>
                                      <small className="d-block">Australian</small>
                                    </div>
                                    <div className="user-progress d-flex align-items-center gap-2">
                                      <button className="chat-button bg-black">Message</button>
                                    </div>
                                  </div>
                                </li>
                                <li className="d-flex align-items-center mb-6">
                                  <div className="avatar flex-shrink-0 me-3 rounded-circle">
                                    <img src={Avatar_1} className="rounded-circle" alt="User" />
                                  </div>
                                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                    <div className="me-2">
                                      <h4 className="fw-bolder mb-0">Devon Lane</h4>
                                      <small className="d-block">Gabonese</small>
                                    </div>
                                    <div className="user-progress d-flex align-items-center gap-2">
                                      <button className="chat-button bg-graytwo">Follow</button>
                                    </div>
                                  </div>
                                </li>
                                <li className="d-flex align-items-center mb-6">
                                  <div className="avatar flex-shrink-0 me-3 rounded-circle">
                                    <img src={Avatar_1} className="rounded-circle" alt="User" />
                                  </div>
                                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                    <div className="me-2">
                                      <h4 className="fw-bolder mb-0">Devon Lane</h4>
                                      <small className="d-block">Gabonese</small>
                                    </div>
                                    <div className="user-progress d-flex align-items-center gap-2">
                                      <button className="chat-button bg-graytwo">Follow</button>
                                    </div>
                                  </div>
                                </li>
                                <li className="d-flex align-items-center mb-6">
                                  <div className="avatar flex-shrink-0 me-3 rounded-circle">
                                    <img src={Avatar_1} className="rounded-circle" alt="User" />
                                  </div>
                                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                    <div className="me-2">
                                      <h4 className="fw-bolder mb-0">Wade Warren</h4>
                                      <small className="d-block">Australian</small>
                                    </div>
                                    <div className="user-progress d-flex align-items-center gap-2">
                                      <button className="chat-button bg-black">Message</button>
                                    </div>
                                  </div>
                                </li>
                                <li className="d-flex align-items-center mb-6">
                                  <div className="avatar flex-shrink-0 me-3 rounded-circle">
                                    <img src={Avatar_1} className="rounded-circle" alt="User" />
                                  </div>
                                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                    <div className="me-2">
                                      <h4 className="fw-bolder mb-0">Wade Warren</h4>
                                      <small className="d-block">Australian</small>
                                    </div>
                                    <div className="user-progress d-flex align-items-center gap-2">
                                      <button className="chat-button bg-black">Message</button>
                                    </div>
                                  </div>
                                </li>
                                <li className="d-flex align-items-center mb-6">
                                  <div className="avatar flex-shrink-0 me-3 rounded-circle">
                                    <img src={Avatar_1} className="rounded-circle" alt="User" />
                                  </div>
                                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                    <div className="me-2">
                                      <h4 className="fw-bolder mb-0">Wade Warren</h4>
                                      <small className="d-block">Australian</small>
                                    </div>
                                    <div className="user-progress d-flex align-items-center gap-2">
                                      <button className="chat-button bg-black">Message</button>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gy-3"></div>
            </div>
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
