import Axios from "../axios";
import { toast } from "react-toastify";
import {
  SET_GET_ALL_USERS,
  SET_LOADING,
  SET_LOGIN,
  SET_LOGOUT,
  SET_USERLIST,
  SET_ADD_USERDATA,
  SET_DETELE_USER,
  SET_FORGET_PASSWORD,
  SET_VERIFICATION,
  SET_NEWPASSWORD,
  SET_LOCALSTRORAGE_LOGIN,
  SET_REFRESHER_LOGIN,
  SET_NEWPASSWORDUSER,
  SET_LEAVEDATA,
  SET_GRIEVANCEDATA,
  SET_VIEW_USERDATA,
  SET_UPDATE_USERDATA,
  SET_ALLUSERLIST,
  SET_LEAVEDATAREJAPP,
  SET_LEAVEDELATAILSDATA,
  SET_RESIGNATIONDATA,
  SET_GRIEVANCEDETAILSDATA,
  SET_GRIEVANCEREJAPP,
  SET_RESIGNATIONDETAILSDATA,
  SET_RESIGNATIONREJAPP,
  SET_POSHDETAILSDATA,
  SET_POSHDATA,
  SET_JOBLIST,
  SET_ADD_JOBDATA,
  SET_JOBDETAILS,
  SET_REPORTLIST,
  SET_STORELIST,
  SET_STORESINGLE,
  SET_STORESINGLEUSER,
  SET_REPORTSINGLEUSER,
} from "../types";

const token = localStorage.getItem("accessToken");
const role = localStorage.getItem("role");

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

export const setPageLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: data,
  });
};

export const setTokenInLocalStorage =
  (accessToken, refreshToken, role) => async (dispatch) => {
    dispatch({
      type: SET_LOCALSTRORAGE_LOGIN,
      payload: accessToken,
    });
    dispatch({
      type: SET_REFRESHER_LOGIN,
      payload: refreshToken,
    });

    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);

    localStorage.setItem("role", role);

    return (accessToken = accessToken);
  };

export const login = (data, router, setLoad) => (dispatch) => {
  dispatch(setPageLoading(true));

  Axios.post("/admin/login", data, config)
    .then((response) => {
      if (response.status) {
        dispatch(
          setTokenInLocalStorage(
            response.data.data.accessToken,
            response.data.data.refreshToken,
            response.data.data.user.role
          )
        );
        router("/");

        toast.success("Login successful");
        setLoad(false);
      } else {
        toast.error(response.data.message || "Login failed!");
        router("/login");
        setLoad(false);
      }
    })
    .catch((error) => {
      const errorMessage = error.response?.data?.message || "Login failed!";
      toast.error(errorMessage);
      router("/login");
      setLoad(false);
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(setPageLoading(false));
        setLoad(false);
      }, 1000);
    });
};

export const logout = (data, navigate) => (dispatch) => {
  Axios.post(`/logout`, data, config)
    .then((response) => {
      if (response.status) {
        toast.success(response.data.message || "Logout successful.");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        dispatch({
          type: SET_LOGOUT,
          payload: response.data,
        });
        navigate("/login");
      } else if (response.data.login === false) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        toast.error("Session expired. Please log in again.");
        dispatch({ type: SET_LOGOUT });
        navigate("/login");
      } else {
        toast.error(response.data.message || "Something went wrong!");
      }
    })
    .catch((error) => {
      const errorMessage =
        error.response?.data?.message || "Something went wrong.";

      if (error.response?.status === 401) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        toast.error("Unauthorized access. Please log in again.");
        dispatch({ type: SET_LOGOUT });
        navigate("/login");
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.login === false
      ) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        toast.error("Session expired. Please log in again.");
        dispatch({ type: SET_LOGOUT });
        navigate("/login");
      } else {
        toast.error(errorMessage);
      }
    });
};

export const forgotpassword = (data, navigate) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.post("f-password", data, config)
    .then((response) => {
      if (response.status) {
        toast.success(response?.data?.result?.message);
        dispatch({
          type: SET_FORGET_PASSWORD,
          payload: data,
        });
        navigate("/verification");
        dispatch(setPageLoading(false));
      } else {
        toast.error("password failed!");
        navigate("/login");
        dispatch(setPageLoading(false));
      }
    })
    .catch((error) => {
      toast.error("password failed!");
      navigate("/login");
      dispatch(setPageLoading(false));
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const verificationOtp = (data, navigate) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.post("verify-otp", data, config)
    .then((response) => {
      if (response.status) {
        toast.success(response?.data?.result?.message);
        dispatch({
          type: SET_VERIFICATION,
          payload: data,
        });
        navigate("/newpassword");
        dispatch(setPageLoading(false));
      } else {
        toast.error("verification failed!");
        navigate("/forgotpassword");
        dispatch(setPageLoading(false));
      }
    })
    .catch((error) => {
      toast.error("verification failed!");
      navigate("/forgotpassword");
      dispatch(setPageLoading(false));
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const newPassword = (data, navigate) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.post("/change-password", data, config)
    .then((response) => {
      if (response.status) {
        toast.success(response?.data?.result?.message);
        dispatch({
          type: SET_NEWPASSWORD,
          payload: data,
        });
        navigate("/login");
        dispatch(setPageLoading(false));
      } else {
        toast.error("verification failed!");
        navigate("/verification");
        dispatch(setPageLoading(false));
      }
    })
    .catch((error) => {
      toast.error("verification failed!");
      navigate("/verification");
      dispatch(setPageLoading(false));
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const newPasswordUser = (data, navigate) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.post(`/reset-password`, data, config)
    .then((response) => {
      if (response.status) {
        toast.success(response?.data?.result?.message);
        dispatch({
          type: SET_NEWPASSWORDUSER,
          payload: data,
        });
        // navigate("/login");
        dispatch(setPageLoading(false));
      } else {
        toast.error("verification failed!");
        // navigate("/verification");
        dispatch(setPageLoading(false));
      }
    })
    .catch((error) => {
      toast.error("verification failed!");
      // navigate("/verification");
      dispatch(setPageLoading(false));
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getUsers = () => (dispatch) => {
  dispatch(setPageLoading(true));

  Axios.get(`/admin/users`, config)
    .then((response) => {
      dispatch({
        type: SET_USERLIST,
        payload: response?.data,
      });
      toast.success(response?.data?.message);
    })
    .catch((error) => {
      console.error(error);
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getUsersAll = (data, currentPage) => (dispatch) => {
  dispatch(setPageLoading(true));

  Axios.get(`/admin/get-all-list?page=${currentPage}`, data, config)
    .then((response) => {
      dispatch({
        type: SET_ALLUSERLIST,
        payload: response?.data,
      });
      toast.success(response?.data?.message);
    })
    .catch((error) => {
      console.error(error);
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const addUsers = (data, navigate) => (dispatch) => {
  setPageLoading(true);
  Axios.post(`/admin/add-user`, data, config)
    .then((response) => {
      dispatch({
        type: SET_ADD_USERDATA,
        payload: response?.data,
      });

      navigate("/user");
      toast.success(response?.data?.result?.message);
      setPageLoading(false);
    })
    .catch((error) => {
      console.error(error);
      toast.error(error?.response?.data?.message);
    })

    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const updateUserData = (id, data, navigate) => (dispatch) => {
  setPageLoading(true);

  Axios.put(`admin/update-user/${id}`, data, config)
    .then((response) => {
      toast.success(response?.data?.message);
      dispatch({
        type: SET_UPDATE_USERDATA,
        payload: response?.data,
      });
      navigate("/user");
      setPageLoading(false);
    })
    .catch((error) => {
      console.error(error);
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const userDeleteData = (data) => (dispatch) => {
  setPageLoading(true);
  Axios.delete(`/admin/delete-user/${data}`, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_DETELE_USER,
          payload: res?.data,
        });
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getLeavedata = (currentPage) => (dispatch) => {
  setPageLoading(true);
  Axios.get(`/admin/get-leaves?page=${currentPage}`, config)
    .then((response) => {
      toast.success(response?.data?.result?.message);

      dispatch({
        type: SET_LEAVEDATA,
        payload: response?.data,
      });
      setPageLoading(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getLeaveDetailsdata = (id, data) => (dispatch) => {
  setPageLoading(true);
  Axios.get(`leave-details/${id}`, config)
    .then((response) => {
      toast.success(response?.data?.result?.message);

      dispatch({
        type: SET_LEAVEDELATAILSDATA,
        payload: response?.data,
      });
      setPageLoading(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const putRejacedApprovedata = (status, id) => (dispatch) => {
  setPageLoading(true);
  Axios.put(`/admin/modify-leave-status/${id}?status=${status}`, config)
    .then((response) => {
      toast.success(response?.data?.result?.message);

      dispatch({
        type: SET_LEAVEDATAREJAPP,
        payload: response?.data,
      });
      setPageLoading(false);
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getGrievancedata = (currentPage) => (dispatch) => {
  setPageLoading(true);
  Axios.get(`admin/get-admin-grievance?page=${currentPage}`, config)
    .then((response) => {
      dispatch({
        type: SET_GRIEVANCEDATA,
        payload: response?.data,
      });
      toast.success(
        response?.data?.message || "Grievance data retrieved successfully."
      ); // Added toast notification
    })
    .catch((error) => {
      toast.error(
        error?.response?.data?.message || "Failed to retrieve grievance data."
      ); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getGrievanceDetaildata = (id) => (dispatch) => {
  setPageLoading(true);
  Axios.get(`/get-single-grievance/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_GRIEVANCEDETAILSDATA,
        payload: response?.data,
      });
      toast.success(
        response?.data?.message || "Grievance details retrieved successfully."
      ); // Added toast notification
    })
    .catch((error) => {
      toast.error(
        error?.response?.data?.message ||
          "Failed to retrieve grievance details."
      ); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const patchGrievanceRejacedApprovedata = (status, id) => (dispatch) => {
  setPageLoading(true);
  Axios.patch(`/admin/modify-grievance/${id}?status=${status}`, config)
    .then((response) => {
      dispatch({
        type: SET_GRIEVANCEREJAPP,
        payload: response?.data,
      });
      toast.success(
        response?.message || "Grievance status updated successfully."
      ); // Added toast notification
    })
    .catch((error) => {
      toast.error(
        error?.response?.message || "Failed to update grievance status."
      ); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getResignationdata = (currentPage) => (dispatch) => {
  setPageLoading(true);
  Axios.get(`admin/get-user-resignation?page=${currentPage}`, config)
    .then((response) => {
      dispatch({
        type: SET_RESIGNATIONDATA,
        payload: response?.data,
      });
      toast.success("Resignation data retrieved successfully."); // Added toast notification
    })
    .catch((error) => {
      toast.error(
        error?.response?.data?.message || "Failed to retrieve resignation data."
      ); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getResignationDetaildata = (id) => (dispatch) => {
  setPageLoading(true);
  Axios.get(`get-single-resignation/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_RESIGNATIONDETAILSDATA,
        payload: response?.data,
      });
      toast.success("Resignation details retrieved successfully."); // Added toast notification
    })
    .catch((error) => {
      toast.error(
        error?.response?.data?.message ||
          "Failed to retrieve resignation details."
      ); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const putResignationRejacedApprovedata = (status, id) => (dispatch) => {
  setPageLoading(true);
  Axios.put(`/admin/modify-admin-grievance/${id}?status=${status}`, config)
    .then((response) => {
      dispatch({
        type: SET_RESIGNATIONREJAPP,
        payload: response?.data,
      });
      toast.success("Resignation status updated successfully."); // Added toast notification
    })
    .catch((error) => {
      toast.error(
        error?.response?.data?.message || "Failed to update resignation status."
      ); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getPoshdata = (currentPage) => (dispatch) => {
  setPageLoading(true);
  Axios.get(`admin/get-posh-admin?page=${currentPage}`, config)
    .then((response) => {
      dispatch({
        type: SET_POSHDATA,
        payload: response?.data,
      });
      toast.success("Resignation data retrieved successfully."); // Added toast notification
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getPoshDetaildata = (id) => (dispatch) => {
  setPageLoading(true);
  Axios.get(`get-single-posh/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_POSHDETAILSDATA,
        payload: response?.data,
      });
      toast.success(response?.data?.message);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getjobList = (currentPage) => (dispatch) => {
  setPageLoading(true);
  Axios.get(`admin/get-all-job?page=${currentPage}`, config)
    .then((response) => {
      dispatch({
        type: SET_JOBLIST,
        payload: response?.data,
      });
      toast.success(response?.data?.message);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getjobdetailList = (id) => (dispatch) => {
  setPageLoading(true);
  Axios.get(`admin/get-single-job-admin/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_JOBDETAILS,
        payload: response?.data,
      });
      toast.success(); // Added toast notification
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const addJobs = (data, navigate) => (dispatch) => {
  setPageLoading(true);
  Axios.post(`/admin/add-job`, data, config)
    .then((response) => {
      dispatch({
        type: SET_ADD_JOBDATA,
        payload: response?.data,
      });

      navigate("/job");
      toast.success(response?.data?.result?.message);
      setPageLoading(false);
    })
    .catch((error) => {
      console.error(error);
      toast.error(error?.response?.data?.message);
    })

    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getReportList = (currentPage) => (dispatch) => {
  setPageLoading(true);
  Axios.get(`admin/get-emps-report?page=${currentPage}`, config)
    .then((response) => {
      dispatch({
        type: SET_REPORTLIST,
        payload: response?.data,
      });
      toast.success(); // Added toast notification
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getStoreList = (currentPage) => (dispatch) => {
  setPageLoading(true);
  Axios.get(`admin/management-store-report?page=${currentPage}`, config)
    .then((response) => {
      dispatch({
        type: SET_STORELIST,
        payload: response?.data,
      });
      toast.success();
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getStoreSingleDetails = (id, currentPage) => (dispatch) => {
  setPageLoading(true);
  console.log(currentPage, "currentPage");
  Axios.get(`admin/store-emps-report/${id}?page=${currentPage}`, config)
    .then((response) => {
      console.log(response);
      dispatch({
        type: SET_STORESINGLE,
        payload: response?.data,
      });
      toast.success();
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getStoreSingleUser = (id) => (dispatch) => {
  setPageLoading(true);
  Axios.get(`admin/single-user/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_STORESINGLEUSER,
        payload: response?.data,
      });
      toast.success(); // Added toast notification
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getReportSingleUserdata = (id) => (dispatch) => {
  setPageLoading(true);
  Axios.get(`admin/single-user/${id}`, config)
    .then((response) => {
      console.log(response);
      dispatch({
        type: SET_REPORTSINGLEUSER,
        payload: response?.data,
      });
      toast.success(); // Added toast notification
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
