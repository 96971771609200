import React, { useState } from "react";
import RightCustomModal from "../../Common/RightModal";
import AddStoreNew from "../AddStore/AddStoreNew";

const Store = () => {
  const [openInviteMember, setOpenInviteMember] = useState(false);

  const handleClose = () => {
    setOpenInviteMember(false);
  };

  // Sample store data
  const storeData = [
    {
      storeName: "JJ Agencies",
      companyName: "JJ Agencies",
      storeAddress:
        "#75 & 76, Jansons Building, 3rd Floor, Commercial Street, Bangalore - 560 001.",
    },
    // Add more stores as needed
  ];

  const fields = [
    { label: "Store Name", id: "storeName" },
    { label: "Company Name", id: "companyName" },
    { label: "Store Address", id: "storeAddress" },
  ];

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 pt-4">
          <div className="text_btn">
            <div className="company_name">
              <h1>Store</h1>
            </div>
            <div className="btn_add">
              <button onClick={() => setOpenInviteMember(true)}>
                Add Store
              </button>
            </div>
          </div>

          <div className="comp-table">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    {fields.map((field) => (
                      <th
                        key={field.id}
                        style={
                          field.id === "storeName" ? { borderLeft: "none" } : {}
                        }
                      >
                        {field.label}
                      </th>
                    ))}
                    <th style={{ borderRight: "none" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {storeData.map((store, index) => (
                    <tr key={index}>
                      {fields.map((field) => (
                        <td key={field.id}>{store[field.id]}</td>
                      ))}
                      <td>
                        <span>
                          <button>Save</button>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {openInviteMember && (
        <RightCustomModal
          isOpen={openInviteMember}
          onClose={handleClose}
          shouldCloseOnOutsideClick={true}
        >
          <AddStoreNew />
        </RightCustomModal>
      )}
    </>
  );
};

export default Store;
